import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import logo from "../../assets/icon/logo.svg";
import {useForm} from 'react-hook-form'
import {forgotPassword, RecoverState, setRecoveryData,} from "../../app/authSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {style} from "../../assets/formErrorStyle";
import {RootState} from "../../app/store";
import {toast} from "react-toastify";
import mail from "../../assets/icon/mail.png";
import FormErrorField from "../form-error-field/FormErrorField";

function Recovery() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const recoveryData = useAppSelector<any>((state: RootState) => state.auth.recoveryData);
    const [email, setEmail] = React.useState<any>(null);
    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<RecoverState>({mode: "all"})

    const onSubmit = handleSubmit((data: RecoverState) => {
        dispatch(forgotPassword(data))
        setEmail(data)
    })
    useEffect(() => {
        dispatch(setRecoveryData(null))
    }, []);

    useEffect(() => {
        if (recoveryData && recoveryData.status === 'EMAIL_SENT') {
            toast.success(recoveryData.message)
        }
    }, [recoveryData]);
    return (
        <div className="login__container recovery__container w-100">
            <div className="login__header">
                <img className="login__logo" src={logo} alt="solomon"/>
            </div>
            <div className="login__body">
                {
                    recoveryData && recoveryData.status === 'EMAIL_SENT'
                    &&
                    (
                        <div className="login__message">
                            <img className="login__message-img" src={mail} alt="email"/>
                            <p className="login__message-text mt-0-5"> {t('Email was sent to')}
                                <strong> {email ? email.email : null}</strong>.</p>
                            <p className="login__message-text mt-0-5"> {t('Follow the link in the email to regain access')}</p>
                        </div>
                    )
                }
                {
                    !recoveryData
                    &&
                    (
                        <>
                            <h1 className="title__primary">
                                {t("Restore access")}
                            </h1>
                            <form className="form recovery__form" onSubmit={onSubmit}>
                                <div className="form__input-container">
                                    <div className="form__input-item">
                                        <FormErrorField error={errors.email}/>
                                        <input
                                            {...register('email', {
                                                required: t('Required field'),
                                            })}
                                            className="form__input"
                                            style={style(errors?.email)}
                                            placeholder={t('Enter e-mail')}
                                            type="text"/>
                                    </div>
                                </div>

                                <div className="login__btn-container mt-1">
                                    <button
                                        type="submit"
                                        className="button w-100"
                                        disabled={!isValid}
                                    >
                                        {t('Continue')}
                                    </button>
                                </div>
                            </form>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default Recovery;

