import {AbilityBuilder, createMongoAbility,} from '@casl/ability';

export function defineAbilitiesFor(user: string) {
    const {can, cannot, build} = new AbilityBuilder(createMongoAbility);

    can('read', 'units');
    can('read', 'payments')
    can('read', 'balance');
    can('read', 'profile');
    can('read', 'dashboard');

    if (user === 'root' || user === 'distributor') {
        cannot('read', 'dashboard', ['dashboard']);
        can('read', 'users');
        can('read', 'price-plan');
        can('read', 'acquirers');
        can('read', 'counterparties');
    }

    if (user === 'distributor') {
        cannot('read', 'users', ['distributor']);
        cannot('read', 'price-plan', ['country']);
        cannot('read', 'acquirers', ['country']);
        cannot('read', 'counterparties', ['country']);
        can('read', 'franchisee');
    }

    if (user === 'customer') {
        cannot('read', 'dashboard', ['customers']);
        can('read', 'monitoring');
        can('read', 'contract');
        can('read', 'deeds');
        can('read', 'requisites');
    }

    return build();
}
