import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './app/store';
import {ApolloProvider} from '@apollo/client';
import {client} from './app/apolloClient'
import App from "./App";
import MainLoader from "./components/mainLoader/MainLoader";
import {I18nextProvider} from "react-i18next";
import i18n from "./app/i18n";
import {BrowserRouter} from "react-router-dom";
import {refreshToken} from "./app/authSlice";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
const refresh_token = localStorage.getItem('refresh_token');
if (refresh_token) {
    store.dispatch(refreshToken({
        refresh_token: refresh_token
    }))
}

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Suspense fallback={<MainLoader/>}>
                <Provider store={store}>
                    <I18nextProvider i18n={i18n}>
                        <BrowserRouter>
                            <App/>
                        </BrowserRouter>
                    </I18nextProvider>
                </Provider>
            </Suspense>
        </ApolloProvider>
    </React.StrictMode>
);

reportWebVitals();
