import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

const REQUISITES = gql`
query {
accountDetailFields {
     type
     fields
  } 
}
`;

const CONTRACTOR = gql`
query {
contractorDetails {
     contractor
  } 
}
`;

const CREATE_REQUISITES = gql`
mutation createAccountDetails($input:  AccountDetails!) {
createAccountDetails(input: $input) {
     result
  }
}
`;

const UPDATE_VAT_STATUS = gql`
mutation updateUserVATStatus($input:  UserVATStatus!) {
updateUserVATStatus(input: $input) {
     result
  }
}
`;

export const requisites = createAsyncThunk(
    'requisites',
    async () => {
        const response = await client.mutate({mutation: REQUISITES})
        return response.data
    }
)

export const contractor = createAsyncThunk(
    'contractor',
    async () => {
        const response = await client.mutate({mutation: CONTRACTOR})
        return response.data
    }
)

export const createAccountDetails = createAsyncThunk(
    'createAccountDetails',
    async (form: any) => {
        const response = await client.mutate({mutation: CREATE_REQUISITES, variables: {input: form}})
        return response.data
    }
)

export const updateUserVATStatus = createAsyncThunk(
    'updateUserVATStatus',
    async (form: any) => {
        const response = await client.mutate({mutation: UPDATE_VAT_STATUS, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    requisitesLoader: false,
    contractorData: null,
    requisitesData: null,
    resultData: null,
}

const requisitesSlice = createSlice({
    name: 'requisites',
    initialState,
    reducers: {
        setUpdateResult: (state: any, action: PayloadAction<boolean>) => {
            state.resultData = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(requisites.pending, (state: any) => {
                    state.requisitesLoader = true;
                }
            )
            .addCase(requisites.fulfilled, (state: any, action: any) => {
                    state.requisitesLoader = false;
                    state.requisitesData = action.payload.accountDetailFields
                }
            )
            .addCase(requisites.rejected, (state: any) => {
                    state.requisitesLoader = false;
                }
            )
            .addCase(createAccountDetails.pending, (state: any) => {
                    state.requisitesLoader = true;
                }
            )
            .addCase(createAccountDetails.fulfilled, (state: any, action: any) => {
                    state.requisitesLoader = false;
                    state.resultData = action.payload.createAccountDetails.result
                }
            )
            .addCase(createAccountDetails.rejected, (state: any) => {
                    state.requisitesLoader = false;
                }
            )
            .addCase(contractor.pending, (state: any) => {
                    state.requisitesLoader = true;
                }
            )
            .addCase(contractor.fulfilled, (state: any, action: any) => {
                    state.requisitesLoader = false;
                    state.contractorData = action.payload.contractorDetails.contractor.split('\n')
                }
            )
            .addCase(contractor.rejected, (state: any) => {
                    state.requisitesLoader = false;
                }
            )
            .addCase(updateUserVATStatus.pending, (state: any) => {
                    state.requisitesLoader = true;
                }
            )
            .addCase(updateUserVATStatus.fulfilled, (state: any, action: any) => {
                    state.requisitesLoader = false;
                    state.resultData = action.payload.updateUserVATStatus.result;
                }
            )
            .addCase(updateUserVATStatus.rejected, (state: any) => {
                    state.requisitesLoader = false;
                }
            )
    }
})
export const {
    setUpdateResult,
} = requisitesSlice.actions;
export default requisitesSlice.reducer;


