import React, {useContext} from 'react';
import {AbilityContext} from '../../app/can';
import {Can} from "@casl/react";
import DashboardCustomers from "./DashboardCustomers";
import DashboardCounts from "./DashboardCounts";

export const dashboardColors = ["#3D9DDE", "#ACD92C", "#2DCEB2", "#FB8640"];

const DashboardComponent = () => {
    const ability = useContext(AbilityContext);

    return (
        <>
            <Can I='read' a={'dashboard'} field="customers" ability={ability}>
                <DashboardCustomers/>
            </Can>
            <Can I='read' a={'dashboard'} field="dashboard" ability={ability}>
                <DashboardCounts/>
            </Can>
        </>
    )
};

export default DashboardComponent;

