import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {contract} from "./DocumentsSlice";
import {RootState} from "../../app/store";

const Contract = () => {
    const dispatch = useAppDispatch();

    const contractData = useAppSelector<any>((state: RootState) => state.documents.contractData);
    useEffect(() => {
        dispatch(contract())
    }, []);

    return (
        <div className="documents__container wh-100">
            <div className="contract__container wh-100">
                <iframe className="wh-100" src={contractData}/>
            </div>
        </div>
    )
};

export default Contract;

