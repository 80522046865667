import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import logo from "../../assets/icon/logo.svg";
import {useForm} from 'react-hook-form'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {style} from "../../assets/formErrorStyle";
import {useNavigate, useSearchParams} from "react-router-dom";
import {RootState} from "../../app/store";
import {updateForgottenPassword} from "../../app/authSlice";
import {toast} from "react-toastify";
import FormErrorField from "../form-error-field/FormErrorField";
import classNames from "classnames";

function RestorePassword() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const updateForgottenPasswordData = useAppSelector<any>((state: RootState) => state.auth.updateForgottenPasswordData);
    const [addressLineData, setAddressLineData] = React.useState({email: '', token: ''});
    const [passwordsDoNotMatch, setPasswordsDoNotMatch] = React.useState(false);
    const [passwordType, setPasswordType] = React.useState(false);
    const [passwordConfirmationType, setPasswordConfirmationType] = React.useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        setAddressLineData({...addressLineData, email: searchParams.get("email")!, token: searchParams.get("token")!})
    }, [searchParams]);

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<{
        password: string
        confirmPassword: string
    }>({mode: "all"})

    const onSubmit = handleSubmit((data) => {
        if (data.password !== data.confirmPassword) {
            setPasswordsDoNotMatch(true)
        } else {
            setPasswordsDoNotMatch(false)
            dispatch(updateForgottenPassword({
                email: addressLineData.email,
                token: addressLineData.token,
                password: data.password,
                password_confirmation: data.confirmPassword,
            }))
        }
    })

    useEffect(() => {
        if (updateForgottenPasswordData && updateForgottenPasswordData.status === 'PASSWORD_UPDATED') {
            navigate('/login/sing-in');
            toast.success(t('Password successfully updated'))
        }
    }, [updateForgottenPasswordData]);

    const passwordTypeClick = (e: string) => {
        if (e === 'password') {
            setPasswordType(!passwordType)
        }
        if (e === 'password_confirmation') {
            setPasswordConfirmationType(!passwordConfirmationType)
        }
    }
    return (
        <div className="login__container recovery__container w-100">
            <div className="login__header">
                <img className="login__logo" src={logo} alt="solomon"/>
            </div>
            <div className="login__body">
                <h1 className="title__primary">
                    {t('Restoring access to')} <span className="title_underline">{addressLineData.email}</span>
                </h1>
                <form className="form recovery__form" onSubmit={onSubmit}>
                    <div className="form__input-container">
                        <div className="form__input-item">
                            <FormErrorField error={errors.password}/>
                            <FormErrorField
                                errorText={passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                            <div className="input_eye">
                                <input
                                    {...register('password', {
                                        required: t('Required field'),
                                        minLength: {
                                            value: 6,
                                            message: t('Password must be at least 6 characters')
                                        }
                                    })}
                                    className="form__input w-100"
                                    autoComplete="off"
                                    style={style(errors?.password || passwordsDoNotMatch)}
                                    placeholder={t('Enter password')}
                                    type={passwordType ? 'text' : 'password'}/>
                                <div
                                    className={classNames('eye', passwordType ? 'eye_open' : 'eye_close')}
                                    onClick={() => passwordTypeClick('password')}>
                                </div>
                            </div>
                        </div>
                        <div className="form__input-item">
                            <FormErrorField error={errors.confirmPassword}/>
                            <FormErrorField
                                errorText={passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                            <div className="input_eye">
                                <input
                                    {...register('confirmPassword', {
                                        required: t('Required field'),
                                        minLength: {
                                            value: 6,
                                            message: t('Password must be at least 6 characters')
                                        },
                                    })}
                                    className="form__input w-100"
                                    autoComplete="off"
                                    style={style(errors?.confirmPassword || passwordsDoNotMatch)}
                                    placeholder={t('Repeat password')}
                                    type={passwordConfirmationType ? 'text' : 'password'}/>
                                <div
                                    className={classNames('eye', passwordConfirmationType ? 'eye_open' : 'eye_close')}
                                    onClick={() => passwordTypeClick('password_confirmation')}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="login__btn-container mt-1">
                        <button
                            type="submit"
                            className="button w-100"
                            disabled={!isValid}
                        >
                            {t('Continue')}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RestorePassword;

