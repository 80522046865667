export const selectStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: state.isFocused ? '#ECF6D4' : 'rgba(0, 0, 0, 0.12)',
        boxShadow: state.isFocused ? '0 0 0 1px #ECF6D4' : null,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        cursor: 'pointer',
        background: state.isSelected ? '#3D9DDE' : state.isFocused ? '#DFF5FE' : '#FFFFFF',
        color: state.isSelected ? '#FFFFFF' : '#1D2649',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        opacity: '0.5',
    }),
}
