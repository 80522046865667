import React from 'react';
import {TabsState} from "../../app/appSlice";
import classNames from "classnames";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Tabs = (tabsArray: TabsState) => {
    const [state, setState] = React.useState({activeTab: ''});
    const navigate = useNavigate();
    const {t} = useTranslation();
    const location = useLocation();
    const changeTab = (tab: any) => {
        setState({...state, activeTab: tab.tabName})
        navigate(tab.route);
    }

    return (
        <div className="tabs">
            <div className="tabs__container">
                {
                    tabsArray.tabs.map((item: any) => (
                        <div key={item.tabName}
                             className={classNames('tabs__item pointer', location.pathname.includes(item.route) ? ' tabs__item_active' : '')}
                             onClick={() => {
                                 changeTab(item)
                             }}>
                            {t(item.tabName)}
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default Tabs;







