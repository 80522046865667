import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

const CUSTOMERS = gql`
mutation {
    customers {
        id
        country_code
        data {
           count_customers
           count_managers
        }
   }
}
`;
const DASHBOARD = gql`
mutation {
    dashboard {
        count_all_units
        count_active_units
        user_balance
        count_days
        currency_code
    }
}
`;

export const customers = createAsyncThunk(
    'customers',
    async () => {
        const response = await client.mutate({mutation: CUSTOMERS})
        return response.data
    }
)

export const dashboardCustomers = createAsyncThunk(
    'dashboard',
    async () => {
        const response = await client.mutate({mutation: DASHBOARD})
        return response.data
    }
)

const initialState = {
    customersData: [],
    dashboardData: {},
    dashboardPreloader: false
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(customers.pending, (state: any) => {
                    state.dashboardPreloader = true
                }
            )
            .addCase(customers.fulfilled, (state: any, action) => {
                    state.dashboardPreloader = false
                    state.customersData = action.payload.customers
                }
            )
            .addCase(customers.rejected, (state: any) => {
                    state.dashboardPreloader = false
                }
            )
            .addCase(dashboardCustomers.pending, (state: any) => {
                    state.dashboardPreloader = true
                }
            )
            .addCase(dashboardCustomers.fulfilled, (state: any, action) => {
                    state.dashboardPreloader = false
                    state.dashboardData = action.payload.dashboard
                }
            )
            .addCase(dashboardCustomers.rejected, (state: any) => {
                    state.dashboardPreloader = false
                }
            )
    }
})

export default dashboardSlice.reducer;


