import React from 'react';
import "./MainLoader.scss";

const MainLoader = () => {
    return (
        <div className="preloader">
            <div className="triple-spinner">
            </div>
        </div>

    )
};

export default MainLoader;

