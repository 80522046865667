import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";
import {toast} from "react-toastify";

export interface FormAcquirerState {
    country_code: string
    city: string
}

export interface FormAcquirerCreateType {
    name: string
    type: string
    login: string
    password: string
    country_code?: string,
    city?: string
}

export interface AcquirerType {
    type: string
    label: {
        login: string
        password: string
    }
}

export interface AcquirersInfoTypes {
    id: string
    name: string
    is_active: string
    country_code: string
    city: string
}


const ACQUIRERS = gql`
mutation {
    acquirers {
      id
      name
      is_active
      country_code
      city
    }
}
`;

const UPDATE_ACQUIRERS = gql`
mutation updateAcquirer($input:  UpdateAcquirer!) {
    updateAcquirer(input: $input) {
           result
    }
}
`;

const CREATE_ACQUIRERS = gql`
mutation addAcquirer($input:  AddAcquirer!) {
    addAcquirer(input: $input) {
           result
    }
}
`;

const ACQUIRER_TYPE = gql`
query {
acquirerTypes
   {
     type
     label
      {
        login
        password
     }
  }
}
`;

const ACQUIRER_INFO = gql`
mutation acquirerInfo($input:  AcquirerInfo!) {
acquirerInfo(input: $input) {
     id
      name
      is_active
      country_code
      city
  }
}
`;

export const acquirer = createAsyncThunk(
    'acquirer',
    async () => {
        const response = await client.mutate({mutation: ACQUIRERS})
        return response.data
    }
)

export const updateAcquirer = createAsyncThunk(
    'updateAcquirer',
    async (form: any) => {
        const response = await client.mutate({mutation: UPDATE_ACQUIRERS, variables: {input: form}})
        return response.data
    }
)

export const createAcquirer = createAsyncThunk(
    'createAcquirer',
    async (form: any) => {
        const response = await client.mutate({mutation: CREATE_ACQUIRERS, variables: {input: form}})
        return response.data
    }
)

export const acquirerType = createAsyncThunk(
    'acquirerType',
    async () => {
        const response = await client.mutate({mutation: ACQUIRER_TYPE})
        return response.data
    }
)

export const acquirerInfo = createAsyncThunk(
    'acquirerInfo',
    async (form: any) => {
        const response = await client.mutate({mutation: ACQUIRER_INFO, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    acquirersData: null,
    acquirersLoader: false,
    updateResult: false,
    acquirerTypes: [],
    acquirerTypesSelection: [],
    acquirerInfo: null,
    successResultFromServer: false,
    errorResultFromServer: false
}

const acquirersSlice = createSlice({
    name: 'acquirer',
    initialState,
    reducers: {
        setUpdateResult: (state: any, action: PayloadAction<boolean>) => {
            state.updateResult = action.payload;
        },
        setSuccessAddedAcquirer: (state: any, action: PayloadAction<boolean>) => {
            state.successResultFromServer = action.payload;
        },
        setErrorAddedAcquirer: (state: any, action: PayloadAction<boolean>) => {
            state.errorResultFromServer = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(acquirer.pending, (state: any) => {
                    state.acquirersLoader = true;
                }
            )
            .addCase(acquirer.fulfilled, (state: any, action: PayloadAction<{ acquirers: AcquirersInfoTypes[]  }>) => {
                    state.acquirersLoader = false;
                    state.acquirersData = action.payload.acquirers;
                }
            )
            .addCase(acquirer.rejected, (state: any) => {
                    state.acquirersLoader = false;
                }
            )
            .addCase(updateAcquirer.pending, (state: any) => {
                    state.acquirersLoader = true;
                }
            )
            .addCase(updateAcquirer.fulfilled, (state: any, action: PayloadAction<{ updateAcquirer: { result: boolean } }>) => {
                    state.acquirersLoader = false;
                    state.updateResult = action.payload.updateAcquirer.result;
                }
            )
            .addCase(updateAcquirer.rejected, (state: any) => {
                    state.acquirersLoader = false;
                }
            )
            .addCase(createAcquirer.pending, (state: any) => {
                    state.acquirersLoader = true;
                }
            )
            .addCase(createAcquirer.fulfilled, (state: any, action: PayloadAction<{ addAcquirer: { result: boolean } }>) => {
                    state.acquirersLoader = false;
                    if (action.payload.addAcquirer.result) {
                        state.successResultFromServer = true
                    } else {
                        state.errorResultFromServer = true
                    }
                }
            )
            .addCase(createAcquirer.rejected, (state: any, action: any) => {
                    state.acquirersLoader = false;
                    toast.error(action.error.message)
                }
            )
            .addCase(acquirerType.fulfilled, (state: any, action: PayloadAction<{ acquirerTypes: AcquirerType[] }>) => {
                    state.acquirerTypes = action.payload.acquirerTypes
                    state.acquirerTypesSelection = action.payload.acquirerTypes.map((item: any) => ({
                        value: item.type,
                        label: item.type
                    }))
                }
            )
            .addCase(acquirerInfo.pending, (state: any) => {
                    state.acquirersLoader = true;
                }
            )
            .addCase(acquirerInfo.fulfilled, (state: any, action: PayloadAction<{ acquirerInfo: AcquirersInfoTypes }>) => {
                    state.acquirersLoader = false;
                    state.acquirerInfo = action.payload.acquirerInfo;
                }
            )
            .addCase(acquirerInfo.rejected, (state: any) => {
                    state.acquirersLoader = false;
                }
            )
    }
})

export const {
    setUpdateResult,
    setSuccessAddedAcquirer,
    setErrorAddedAcquirer
} = acquirersSlice.actions;


export default acquirersSlice.reducer;
