import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useForm} from "react-hook-form";
import {getHwType, qrCode, QrCodeState, setHwType, setQrCodeResult, setSuccessfullyGenerated} from "./QrCodeSlice";
import {RootState} from "../../app/store";
import {pricePlans} from "../price-plan/pricePlanSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import {toast} from "react-toastify";
import trash from "../../assets/icon/trash.svg";
import {style} from "../../assets/formErrorStyle";
import {selectStyles} from "../SelectStyles";
import Select from "react-select";
import FormErrorField from "../form-error-field/FormErrorField";

const QrCode = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let pricePlanArrayData = useAppSelector<any>((state: RootState) => state.pricePlans.pricePlansSelection);
    const qrCodeResult = useAppSelector((state: RootState) => state.qrCode.qrCodeResult);
    const qrCodeLoader = useAppSelector((state: RootState) => state.qrCode.qrCodeLoader);
    const successfullyGenerated = useAppSelector((state: RootState) => state.qrCode.successfullyGenerated);
    const hwTypeData = useAppSelector<any>((state: RootState) => state.qrCode.hwType);
    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors, isValid}
    } = useForm<QrCodeState>({mode: 'all'})

    const [state, setState] = React.useState<QrCodeState>({rate_id: null, imei: null});

    useEffect(() => {
        dispatch(pricePlans())
    }, []);

    const resetAllData = () => {
        reset();
        setState({...state, rate_id: null, imei: null})
        setValue('rate_id', null)
        dispatch(setQrCodeResult(''))
    }

    useEffect(() => {
        resetAllData()
    }, []);

    useEffect(() => {
        if (successfullyGenerated) {
            toast.success(t('QR CODE generated'));
            dispatch(setSuccessfullyGenerated(false))
        }
    }, [successfullyGenerated]);

    const onSubmit = handleSubmit((data: QrCodeState) => {
        setState({...state, rate_id: data.rate_id, imei: data.imei})
        dispatch(getHwType({imei: data.imei}))
    })

    useEffect(() => {
        if (hwTypeData) {
            if (hwTypeData.hwType) {
                dispatch(qrCode({
                    imei: state.imei,
                    rate_id: Number(state.rate_id),
                }))
            } else {
                toast.error(t('Hardware type not found'))
            }
        }
        return () => {
            dispatch(setHwType(null))
        }
    }, [hwTypeData]);

    const onSelectRateIdChange = (val: any) => {
        setValue('rate_id', val.value)
        setState({...state, rate_id: val.value})
    }

    return (
        <div className="table__container qr-code__container">
            {qrCodeLoader && <SecondaryLoader/>}
            <div className="table__header">
                <div className="table__title">{t('Generate QR CODE')}</div>
                {
                    state.rate_id || state.imei
                        ? (
                            <div className="button__container">
                                <div className="button__clear" onClick={resetAllData}>
                                    <img src={trash} alt="clear"/>
                                    <p className="button__clear-title">
                                        {t('Clear')}
                                    </p>
                                </div>
                            </div>
                        ) : null
                }
            </div>
            <div className="table__body">
                <div className='add__block add__block_visible'>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.imei}/>
                                <input
                                    {...register('imei', {
                                        required: t('Required field'),
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: t('Field must be a real number')
                                        }
                                    })}
                                    className="form__input"
                                    style={style(errors?.imei)}
                                    placeholder={t('Enter imei')}
                                    onChange={(e) => {
                                        setState({...state, imei: e.target.value})
                                    }}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <Select
                                    isSearchable={false}
                                    options={pricePlanArrayData}
                                    className="select"
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    placeholder={t('Choose a price plan')}
                                    value={state.rate_id ?
                                        pricePlanArrayData.map((e: any) => e.value === state.rate_id).label
                                        : ''}
                                    onChange={onSelectRateIdChange}
                                />
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Generate')}
                            </button>
                        </div>
                    </form>
                    {
                        qrCodeResult
                        &&
                        (
                            <div className="qr-code__result">
                                <div className="qr-code__title mb-1">
                                    {t('Your QR CODE')}:
                                </div>
                                <div className="qr-code__description">
                                    <p className="qr-code__text">{t('Price plan')}: {state.rate_id}, </p>
                                    <p className="qr-code__text">IMEI: {state.imei} </p>
                                </div>
                                <div className="qr-code__img mt-1" dangerouslySetInnerHTML={{__html: qrCodeResult}}/>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default QrCode;

