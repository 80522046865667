import i18n from "../../app/i18n";

export const monthsOptions = () => {
    return [
        {
            label: i18n.t('January'),
            value: 1
        },
        {
            label: i18n.t('February'),
            value: 2
        },
        {
            label: i18n.t('March'),
            value: 3
        },
        {
            label: i18n.t('April'),
            value: 4
        },
        {
            label: i18n.t('May'),
            value: 5
        },
        {
            label: i18n.t('June'),
            value: 6
        },
        {
            label: i18n.t('July'),
            value: 7
        },
        {
            label: i18n.t('August'),
            value: 8
        },
        {
            label: i18n.t('September'),
            value: 9
        },
        {
            label: i18n.t('October'),
            value: 10
        },
        {
            label: i18n.t('November'),
            value: 11
        },
        {
            label: i18n.t('December'),
            value: 12
        },
    ]
}
