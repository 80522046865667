import React from 'react';
import {useAppDispatch} from "../../app/hooks";
import {updateAcquirer} from "./AcquirersSlice";

const CheckboxRenderComponent = (params: any) => {
    const dispatch = useAppDispatch();
    const handleChange = (e: any) => {
        dispatch(updateAcquirer({
            acquirer_id: params.data.id,
            is_active: e.target.checked
        }))
    }

    return (
        (params && params.data.status !== null)
        &&
        (
            <>
                <input
                    className="main-checkbox"
                    type="checkbox"
                    id={params.data.id}
                    checked={params.data.is_active}
                    onChange={handleChange}
                />
                <label className="main-checkbox__label" htmlFor={params.data.id}> </label>
            </>
        )
    )
};

export default CheckboxRenderComponent;







