import React from 'react';
import {Route, Routes} from "react-router-dom";
import CounterpartiesTable from "./CounterpartiesTable";
import CounterpartiesCreate from "./CounterpartiesCreate";
import CounterpartiesEdit from "./CounterpartiesEdit";

const CounterpartiesList = () => {
    return (
        <Routes>
            <Route path="counterparties" element={<CounterpartiesTable/>}/>
            <Route path="create" element={<CounterpartiesCreate/>}/>
            <Route path=":id/edit" element={<CounterpartiesEdit/>}/>
        </Routes>
    )
};

export default CounterpartiesList;

