import React, {useContext, useEffect} from 'react';
import close from "../../assets/icon/close.svg";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {createPricePlans, FormAddNewPricePlanState, setSuccessUpdatePricePlan} from "./pricePlanSlice";
import {useForm} from "react-hook-form";
import {style} from "../../assets/formErrorStyle";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import {toast} from "react-toastify";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import {useNavigate} from "react-router-dom";
import FormErrorField from "../form-error-field/FormErrorField";
import {AbilityContext} from "../../app/can";
import {Can} from "@casl/react";
import {countryOptions} from "../utils/CountryOptions";
import TableHeader from "../table/TableHeader";

const PricePlanCreate = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ability = useContext(AbilityContext);
    const successUpdatePricePlan = useAppSelector((state: RootState) => state.pricePlans.successUpdatePricePlan);
    const pricePlanLoader = useAppSelector((state: RootState) => state.pricePlans.pricePlanLoader);
    const [country, setCountry] = React.useState('');

    useEffect(() => {
        if (successUpdatePricePlan) {
            toast.success(t('Saved'));
            dispatch(setSuccessUpdatePricePlan(false))
            resetAddNewPricePlan()
            setCountry('')
            setValueAddNewPricePlan('country_code', '')
            navigate('/price-plan/list')
        }
    }, [successUpdatePricePlan]);

    const {
        register: registerAddNewPricePlan,
        handleSubmit: handleSubmitAddNewPricePlan,
        formState: {errors: errorsAddNewPricePlan},
        setValue: setValueAddNewPricePlan,
        reset: resetAddNewPricePlan,
    } = useForm<FormAddNewPricePlanState>({mode: "all"})

    const onSubmitNewUnit = handleSubmitAddNewPricePlan((data: any) => {
        const submitData = {
            name: data.name,
            price: Number(data.price),
            add_to_balance: Number(data.add_to_balance),
        }
        if (ability.can('read', 'price-plan', 'country')) {
            dispatch(createPricePlans({...submitData, country_code: data.country_code,}));
        } else {
            dispatch(createPricePlans(submitData));
        }
    })

    const changeCountry = (val: any) => {
        setValueAddNewPricePlan('country_code', val.value)
        setCountry(val.value)
    }
    const closePricePlan = () => {
        navigate('/price-plan/list')
        resetAddNewPricePlan()
        setCountry('')
        setValueAddNewPricePlan('country_code', '')
    }
    return (
        <div className="table__container">
            {pricePlanLoader && <SecondaryLoader/>}
            <TableHeader title={t('Creating a price plan')}
                         close={closePricePlan}/>
            <div className="table__body">
                <div
                    className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmitNewUnit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errorsAddNewPricePlan.name}/>
                                <input
                                    {...registerAddNewPricePlan('name',
                                        {
                                            required: t('Required field'),
                                        })}
                                    className="form__input"
                                    style={style(errorsAddNewPricePlan?.name)}
                                    placeholder={t('Enter a name')}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errorsAddNewPricePlan.price}/>
                                <input
                                    {...registerAddNewPricePlan('price',
                                        {
                                            required: t('Required field'),
                                            pattern: {
                                                value: /^[0-9]*[.,]?[0-9]{0,2}$/,
                                                message: t('Invalid number format')
                                            }
                                        })}
                                    className="form__input"
                                    style={style(errorsAddNewPricePlan?.price)}
                                    placeholder={t('Enter price')}
                                    type="number"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errorsAddNewPricePlan.add_to_balance}/>
                                <input
                                    {...registerAddNewPricePlan('add_to_balance',
                                        {
                                            required: t('Required field'),
                                            pattern: {
                                                value: /^[0-9]*[.,]?[0-9]{0,2}$/,
                                                message: t('Invalid number format')
                                            }

                                        })}
                                    className="form__input"
                                    style={style(errorsAddNewPricePlan?.add_to_balance)}
                                    placeholder={t('Amount of credit to the balance')}
                                    type="tel"/>
                            </div>
                            <Can I='read' a={'price-plan'} field="country" ability={ability}>
                                <div className="form__input-item">
                                    <FormErrorField error={errorsAddNewPricePlan.country_code}/>
                                    <Select
                                        {...registerAddNewPricePlan('country_code', {
                                            required: t('Required field'),
                                        })}
                                        isSearchable={false}
                                        options={countryOptions()}
                                        placeholder={t('Choose a country')}
                                        className={classNames('select', errorsAddNewPricePlan?.country_code ? ' select-invalid' : '')}
                                        classNamePrefix="select"
                                        styles={selectStyles}
                                        value={countryOptions().find((e: any) => country === e.value)}
                                        onChange={changeCountry}
                                    />
                                </div>
                            </Can>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
};

export default PricePlanCreate;

