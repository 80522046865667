import React from 'react';
import {useAppDispatch} from "../../app/hooks";
import {updateCounterparties} from "./CounterpartiesSlice";

const CheckboxRenderComponent = (params: any) => {
    const dispatch = useAppDispatch();
    const handleChange = (e: any) => {
        dispatch(updateCounterparties({
            contractor_id: params.data.id,
            is_default: e.target.checked,
            name: params.data.name,
            country_code: params.data.country_code,
            vat_status: params.data.vat_status,
            api_link: params.data.api_link,
        }))
    }

    return (
        <>
            <input
                className="main-checkbox"
                type="checkbox"
                id={params.data.id}
                checked={params.data.is_default}
                onChange={handleChange}
            />
            <label className="main-checkbox__label" htmlFor={params.data.id}> </label>
        </>
    )
};

export default CheckboxRenderComponent;







