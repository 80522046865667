import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    unitImeiData: '',
}

const unitImeiSlice = createSlice({
    name: 'unit-imei',
    initialState,
    reducers: {
        getUnitImei: (state, action) => {
            state.unitImeiData = action.payload;
        },
    },
})

export const {getUnitImei} = unitImeiSlice.actions;

export default unitImeiSlice.reducer;


