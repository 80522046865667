import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import classNames from "classnames";
import {style} from "../../assets/formErrorStyle";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import {CounterpartyType, infoCounterparties, setCreateResult, updateCounterparties} from "./CounterpartiesSlice";
import {RootState} from "../../app/store";
import {toast} from "react-toastify";
import FormErrorField from "../form-error-field/FormErrorField";
import {countryOptions} from "../utils/CountryOptions";
import {vatStatusOptions} from "../utils/VatStatusOptions";
import TableHeader from "../table/TableHeader";

const CounterpartiesEdit = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const createResult = useAppSelector((state: RootState) => state.counterparties.createResult);
    const contractorInfo = useAppSelector<any>((state: RootState) => state.counterparties.contractorInfo);
    const [country, setCountry] = React.useState('');
    const [isDefault, setIsDefault] = React.useState<boolean>(true);
    const [chosenVatStatus, setChosenVatStatus] = React.useState<any>(null);
    let {id} = useParams();

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<CounterpartyType>(
        {
            mode: "all",
        }
    )
    useEffect(() => {
        if (createResult) {
            toast.success(t('Saved'));
            dispatch(setCreateResult(false))
            navigate('/counterparties-list/counterparties')
        }
    }, [createResult]);

    useEffect(() => {
        dispatch(infoCounterparties({id: Number(id)}))
    }, []);

    useEffect(() => {
        if (contractorInfo) {
            setValue('name', contractorInfo.name)
            setValue('api_link', contractorInfo.api_link)
            setValue('country_code', contractorInfo.country_code)
            setCountry(contractorInfo.country_code)
            setValue('is_default', contractorInfo.is_default)
            setIsDefault(contractorInfo.is_default)
            if (contractorInfo.vat_status) {
                setValue('vat_status', vatStatusOptions()[0].value)
                setChosenVatStatus(vatStatusOptions()[0].value)
            } else {
                setValue('vat_status', vatStatusOptions()[1].value)
                setChosenVatStatus(vatStatusOptions()[1].value)
            }
        }
    }, [contractorInfo]);

    const onSubmit = handleSubmit((data: CounterpartyType) => {
        const result = {
            contractor_id: Number(id),
            api_link: data.api_link,
            country_code: data.country_code,
            is_default: data.is_default,
            name: data.name,
        }
        if (data.vat_status === vatStatusOptions()[0].value) {
            dispatch(updateCounterparties({...result, vat_status: true}))
        } else {
            dispatch(updateCounterparties({...result, vat_status: false}))
        }
    })

    const changeCountry = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('country_code', val.value)
            setCountry(val.value)
        }
    }
    const onChangeVatStatus = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('vat_status', val.value)
            setChosenVatStatus(val.value)
        }
    }
    const changeIsDefault = (val: React.ChangeEvent<HTMLInputElement>) => {
        setValue('is_default', val.target.checked)
        setIsDefault(val.target.checked)
    }

    const closeCounterparty = () => {
        navigate('/counterparties-list/counterparties')
    }

    return (
        <div className="table__container">
            <TableHeader
                id={id}
                title={t('Edit a counterparty')}
                close={closeCounterparty}/>
            <div className="table__body">
                <div
                    className={classNames('add__block  add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.name}/>
                                <input
                                    {...register('name', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input"
                                    style={style(errors?.name)}
                                    placeholder={t('Enter a name')}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.country_code}/>
                                <Select
                                    {...register('country_code', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={countryOptions()}
                                    placeholder={t('Choose a country')}
                                    className={classNames('select', errors?.country_code ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={countryOptions().find((e: { value: string, label: string }) => country === e.value)}
                                    onChange={changeCountry}
                                />
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.vat_status}/>
                                <Select
                                    isSearchable={false}
                                    options={vatStatusOptions()}
                                    className={classNames('select', errors?.vat_status ? ' select-invalid' : '')}
                                    placeholder={t('Type of taxation')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={vatStatusOptions().find((e: { value: string, label: string }) => chosenVatStatus === e.value)}
                                    onChange={onChangeVatStatus}
                                />
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.api_link}/>
                                <input
                                    {...register('api_link', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input"
                                    style={style(errors?.api_link)}
                                    placeholder={t('Введите ссылку API 1C')}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <input
                                    className="main-checkbox"
                                    type="checkbox"
                                    id="is_default"
                                    checked={isDefault}
                                    onChange={changeIsDefault}
                                />
                                <label className="main-checkbox__label" htmlFor="is_default">{t('By default')}</label>
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default CounterpartiesEdit;

