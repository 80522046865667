import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {acquirerInfo, FormAcquirerState, setUpdateResult, updateAcquirer} from "./AcquirersSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import {toast} from "react-toastify";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import {getCity, setCities} from "../../app/authSlice";
import {useNavigate, useParams} from "react-router-dom";
import FormErrorField from "../form-error-field/FormErrorField";
import {countryOptions} from "../utils/CountryOptions";
import TableHeader from "../table/TableHeader";


const AcquirersEdit = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const acquirersLoader = useAppSelector((state: RootState) => state.acquirer.acquirersLoader);
    const updateResult = useAppSelector((state: RootState) => state.acquirer.updateResult);
    const acquirerInfoData = useAppSelector<any>((state: RootState) => state.acquirer.acquirerInfo);
    const getCityData = useAppSelector<Array<{ value: string, label: string }>>((state: RootState) => state.auth.getCityData);
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    let {id} = useParams();
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm<FormAcquirerState>()

    useEffect(() => {
        if (updateResult) {
            toast.success(t('Saved'));
            navigate('/acquirers-list/acquirers')
        }
        return () => {
            dispatch(setUpdateResult(false))
        }
    }, [updateResult]);

    useEffect(() => {
        dispatch(acquirerInfo({id: Number(id)}))
    }, [])

    useEffect(() => {
        if (acquirerInfoData) {
            dispatch(setCities([]))
            setValue('country_code', acquirerInfoData.country_code)
            setValue('city', acquirerInfoData.city)
            setCountry(acquirerInfoData.country_code)
            setCity(acquirerInfoData.city)
        }
    }, [acquirerInfoData])

    const changeCountry = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('country_code', val.value)
            setCountry(val.value)
            setValue('city', '')
            setCity('')
            dispatch(setCities([]))
        }
    }

    const changeCity = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('city', val.value)
            setCity(val.value)
        } else {
            setValue('city', '')
            setCity('')
        }
    }

    const onInputChangeCity = (val: string) => {
        if (val && val.length >= 3 && country) {
            dispatch(getCity({name: val, country_code: country}))
        }
    }

    const onSubmit = handleSubmit((data: FormAcquirerState) => {
        dispatch(updateAcquirer({
            acquirer_id: Number(id),
            country_code: data.country_code,
            city: data.city
        }))
    })
    const closeAcquirer = () => {
        navigate('/acquirers-list/acquirers')
    }
    return (
        <div className="table__container">
            {acquirersLoader && <SecondaryLoader/>}
            <TableHeader
                id={id}
                title={t('Editing the acquirer')}
                close={closeAcquirer}/>
            <div className="table__body">
                <div
                    className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.country_code}/>
                                <Select
                                    {...register('country_code', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={countryOptions()}
                                    placeholder={t('Choose a country')}
                                    className={classNames('select', errors?.country_code ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={countryOptions().find((e: { value: string, label: string }) => country === e.value)}
                                    onChange={changeCountry}
                                />
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.city}/>
                                <Select
                                    {...register('city', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={getCityData}
                                    placeholder={t('Enter city')}
                                    className={classNames('select', errors?.city ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    value={city ? {label: city, value: city} : null}
                                    styles={selectStyles}
                                    onChange={changeCity}
                                    onInputChange={onInputChangeCity}
                                />
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!city || !country}
                            >
                                {t('Save')}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
};

export default AcquirersEdit;

