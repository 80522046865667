import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import AgGridComponent from "../ag-grid/AgGridComponent";
import {useForm} from "react-hook-form";
import {selectStyles} from "../SelectStyles";
import Select from "react-select";
import {unitInvoices} from "./InvoicesSlice";
import {monthsOptions} from "../utils/MonthsOptions";

const Payments = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const unitInvoicesArrayData = useAppSelector<any>((state: RootState) => state.invoices.unitInvoicesData);
    const [pinnedBottomRowData, setPinnedBottomRowData] = React.useState<any>([]);
    const [totalPriceForTheMonth, setTotalPriceForTheMonth] = React.useState<any>(null);
    const [currency, setCurrency] = React.useState<any>('');

    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;
    let availableYears: any = [{
        label: currentYear,
        value: currentYear,
    }]

    const {
        handleSubmit,
        setValue,
        getValues,
        formState: {isValid}
    } = useForm({
        mode: 'all',
        defaultValues: {
            'year': availableYears.filter((e: any) => e.value === currentYear ? e.label : null)[0].value,
            'month': monthsOptions().filter(e => e.value === currentMonth ? e.label : null)[0].value
        }
    })

    const [column, setColumn] = React.useState<any>([]);

    const onSubmit = handleSubmit((data) => {
        console.log(data)
        dispatch(unitInvoices({year: data.year, month: data.month}))
    })

    useEffect(() => {
        for (let i = 2020; i <= currentYear; i++) {
            if (currentYear !== i) {
                availableYears.push({
                    label: i,
                    value: i
                })
            }
        }
        availableYears.sort((x1: any, x2: any) => x1.value - x2.value)
    }, [availableYears])

    const daysInMonth = (year: any, month: any) => {
        return new Date(year, month, 0).getDate();
    }

    const onSelectMonthChange = (val: any) => {
        console.log(val)
        setValue('month', val.value)
    }
    const onSelectYearChange = (val: any) => {
        console.log(val)
        setValue('year', val.value)
    }

    useEffect(() => {
        const arr: any = [
            {
                headerName: t('Object id'),
                field: 'id',
                width: 120,
                valueGetter: (params: any) => {
                    return params.data ? params.data.id : null
                },
            },
        ]
        for (let i = 1; i <= daysInMonth(getValues('year'), getValues('month')); i++) {
            arr.push(
                {
                    headerName: i.toString() + ' ' + monthsOptions().filter(e => e.value === getValues('month') ? e.label : null)[0].label.substring(0, 3),
                    field: i.toString(),
                    valueGetter: (params: any) => {
                        let price = null
                        if (params.data && params.data.invoiceData && params.data.invoiceData.length > 0) {
                            params.data.invoiceData.forEach((item: any) => {
                                if (new Date(item.created_at).getDate() === i) {
                                    price = item.price + ' ' + params.data.currency
                                }
                            })
                        }
                        return price
                    },
                    width: 100,
                }
            )
        }
        setColumn(arr)
        if (unitInvoicesArrayData && unitInvoicesArrayData[0] && unitInvoicesArrayData[0].invoiceData) {
            setCurrency(unitInvoicesArrayData[0].currency)
            let maxUnitInvoiceData = unitInvoicesArrayData[0]
            unitInvoicesArrayData.forEach((e: any) => {
                for (let i = 0; i < e.invoiceData.length; i++) {
                    if (e.invoiceData.length > maxUnitInvoiceData.invoiceData.length) {
                        maxUnitInvoiceData = e
                    }
                }
            })
            const invoiceWithTotalPrice = maxUnitInvoiceData.invoiceData.map((post: any) => (
                {...post, price: post.invoice.total_price}
            ));

            setPinnedBottomRowData(
                [{
                    id: 'Итого:',
                    currency: currency,
                    invoiceData: invoiceWithTotalPrice
                }]
            )
            let totalPriceForTheMonth = 0
            unitInvoicesArrayData.forEach((e: any) => {
                if (e && e.invoiceData && e.invoiceData.length > 0) {
                    e.invoiceData.forEach((invoiceDataItem: any) => {
                        totalPriceForTheMonth += invoiceDataItem.price
                    })
                }
            })
            setTotalPriceForTheMonth(Math.round(totalPriceForTheMonth * 100 + Number.EPSILON) / 100)
        }
    }, [unitInvoicesArrayData])

    useEffect(() => {
        dispatch(unitInvoices({year: getValues('year'), month: getValues('month')}))
    }, [])

    return (
        <div className="table__container">
            <div className="table__header table__header_multiply">
                <div className="table__title">{t('Payments')}</div>
                <form className="form-line" onSubmit={onSubmit}>
                    <Select
                        isSearchable={false}
                        options={availableYears}
                        defaultValue={availableYears.filter((e: any) => e.value === currentYear ? e.label : null)}
                        className="select form-line-select"
                        classNamePrefix="select"
                        styles={selectStyles}
                        onChange={onSelectYearChange}
                    />
                    <Select
                        isSearchable={false}
                        className="select form-line-select"
                        options={monthsOptions()}
                        classNamePrefix="select"
                        defaultValue={monthsOptions().filter(e => e.value === currentMonth ? e.label : null)}
                        styles={selectStyles}
                        onChange={onSelectMonthChange}
                    />
                    <div className="button__container">
                        <button
                            type="submit"
                            className="button"
                            disabled={!isValid}
                        >
                            {t('Apply')}
                        </button>
                    </div>
                </form>
            </div>

            <div className="table__body">
                <div className="table__with_pagination">
                    <AgGridComponent rowData={unitInvoicesArrayData}
                                     columnDefs={column}
                                     pinnedBottomRowData={pinnedBottomRowData}
                    />
                    <div className="table__bottom-container">
                        <div className="table__bottom-item">
                            <p className="table__bottom-title">
                                {t('Total for the month')} :
                            </p>
                            <p className="table__bottom-description">
                                {totalPriceForTheMonth} {currency}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Payments;

