import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {setSuccessUpdateUnitStatus, units} from "./UnitsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import classNames from "classnames";
import CheckboxRenderComponent from "./CheckboxRenderComponent";
import AgGridComponent from "../ag-grid/AgGridComponent";
import Pagination from "../pagination/Pagination";
import {PaginationType} from "../pagination/PaginationType";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import RemoveRenderComponent from "./RemoveRenderComponent";
import {setSearchOnChange, setSearchVisible} from "../search/SearchSlice";
import TableHeader from "../table/TableHeader";

const UnitsTable = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const searchValue = useAppSelector((state: RootState) => state.search.searchValue);
    const [paginationEvent, setPaginationEvent] = React.useState<PaginationType>({
        page: 1,
        per_page: 25,
        last_page: 1,
        total: 1
    });
    const unitsArrayData = useAppSelector((state: RootState) => state.units.unitsData);
    const unitsPaginate = useAppSelector((state: RootState) => state.units.paginate);
    const successUpdateUnitStatus = useAppSelector((state: RootState) => state.units.successUpdateUnitStatus);

    useEffect(() => {
        if (successUpdateUnitStatus) {
            toast.success(t('Saved'));
            dispatch(units({
                page: paginationEvent.page,
                per_page: paginationEvent.per_page,
                search: searchValue
            }))
        }
        return () => {
            dispatch(setSuccessUpdateUnitStatus(false))
        }
    }, [successUpdateUnitStatus]);

    const updateDataPagination = (value: PaginationType) => {
        setPaginationEvent({
            page: value.page,
            per_page: value.per_page,
            last_page: value.last_page,
            total: value.total
        })
    }

    useEffect(() => {
        dispatch(units({
            page: paginationEvent.page,
            per_page: paginationEvent.per_page,
            search: searchValue
        }))
    }, [paginationEvent, searchValue]);

    const columnDefs = [
        {
            headerName: "№",
            field: "id",
            width: 100,
        },
        {
            headerName: t('Name'),
            field: "name",
        },
        {
            headerName: t('IMEI'),
            field: "imei",
        },
        {
            headerName: t('Phone'),
            field: "phone",
        },
        {
            headerName: t('Last message'),
            field: "last_msg",
        },
        {
            headerName: t('Cost of the object per day'),
            field: "rate",
        },
        {
            headerName: t('Status'),
            field: 'status',
            cellRenderer: CheckboxRenderComponent,
            width: 100,
            cellClass: 'cell-center-alignment',
        },
        {
            headerName: t('Remove'),
            field: 'removed',
            cellRenderer: RemoveRenderComponent,
            width: 100,
            cellClass: 'cell-center-alignment',
        },
    ];

    /* SEARCH */
    useEffect(() => {
        dispatch(setSearchVisible(true))
        return () => {
            dispatch(setSearchVisible(false))
            dispatch(setSearchOnChange(''))
        }
    }, [])
    const createUnit = () => {
        navigate('/units-list/unit-create')
    }
    return (
        <div className="table__container">
            <TableHeader title={t('Units')}
                         create={createUnit}/>
            <div className="table__body">
                <div
                    className={classNames('table__with_pagination')}>
                    <AgGridComponent rowData={unitsArrayData} columnDefs={columnDefs}/>
                    {
                        unitsPaginate
                        &&
                        <Pagination updateDataPagination={updateDataPagination}
                                    pagination={unitsPaginate}/>
                    }
                </div>
            </div>
        </div>
    )
};

export default UnitsTable;

