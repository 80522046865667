import React from 'react';
import {AgGridReact} from 'ag-grid-react';
import classNames from "classnames";
import {defaultColDef, floatingFiltersHeight, headerHeight, rowHeight} from "../AgGridOptions";
import {useTranslation} from "react-i18next";

const AgGridComponent = (data: any) => {
    const {t} = useTranslation();
    const noRowsTemplate = `<span class="ag-no-row-title">${t('No data')}</span>`;
    return (
        <div className={classNames('ag-theme-alpine wh-100', data.onRowClicked ? ' table__edit' : '')}
        >
            <AgGridReact
                pagination={true}
                rowHeight={rowHeight}
                headerHeight={headerHeight}
                floatingFiltersHeight={floatingFiltersHeight}
                defaultColDef={defaultColDef}
                rowData={data.rowData}
                columnDefs={data.columnDefs}
                onRowClicked={data.onRowClicked}
                pinnedBottomRowData={data.pinnedBottomRowData}
                overlayNoRowsTemplate={noRowsTemplate}
            >
            </AgGridReact>
        </div>
    )
};

export default AgGridComponent;

