import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {getUser, setResult, updateUserContractor} from "./usersSlice";
import classNames from "classnames";
import {counterparties} from "../counterparties/CounterpartiesSlice";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";
import arrowRight from "../../assets/icon/arrow-right.svg";

const UsersAssignCounterparty = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let {id} = useParams();
    const navigate = useNavigate();
    const getUserData = useAppSelector<any>((state: RootState) => state.users.user);
    const counterpartiesData = useAppSelector<any>((state: RootState) => state.counterparties.counterpartiesData);
    const [counterpartiesList, setCounterpartiesList] = React.useState<any>([]);
    const [chosenCounterparty, setChosenCounterparty] = React.useState<any>([]);
    const resultFromServer = useAppSelector((state: RootState) => state.users.resultData);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm<{
        contractor_id: number
    }>({mode: 'all'})

    useEffect(() => {
        dispatch(getUser({id: Number(id)}))
    }, [])
    useEffect(() => {
        dispatch(counterparties())
    }, [])

    useEffect(() => {
        if (resultFromServer) {
            navigate('/users-list/users')
            dispatch(setResult(false))
        }
    }, [resultFromServer])

    useEffect(() => {
        if (counterpartiesData && getUserData) {
            setCounterpartiesList(counterpartiesData
                .filter((el: any) => el.country_code === getUserData.country_code)
                .map((el: string | any) => ({
                    value: el.id,
                    label: el.name,
                }))
            )
        }
    }, [counterpartiesData, getUserData])

    const changeCounterparty = (val: any) => {
        setValue('contractor_id', val.value);
        setChosenCounterparty(val.value)
    }
    const onSubmit = handleSubmit((data: any) => {
        dispatch(updateUserContractor({...data, user_id: Number(id)}))
    })
    const closeUsersCounterparty = () => {
        navigate('/users-list/users')
    }
    const formatOptionLabel = (value: any): any => {
        return <div className="select__dropdown-item">
            ID: {value.value}
            <img src={arrowRight} alt="arrow"/>
            {value.label}
        </div>
    }

    return (
        <div className="table__container">
            <TableHeader
                id={id}
                title={t('Assign a counterparty')}
                close={closeUsersCounterparty}/>
            <div className="table__body">
                <div className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.contractor_id}/>
                                <Select
                                    {...register('contractor_id', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={counterpartiesList}
                                    getOptionLabel={formatOptionLabel}
                                    placeholder={t('Choose a counterparty')}
                                    className={classNames('select', errors?.contractor_id ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={counterpartiesList.find((e: any) => chosenCounterparty === e.id)}
                                    onChange={changeCounterparty}
                                />
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UsersAssignCounterparty;

