import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {acquirer} from "./AcquirersSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import AgGridComponent from "../ag-grid/AgGridComponent";
import CountryRenderComponent from "../ContryRenderComponent";
import CheckboxRenderComponent from "./CheckboxRenderComponent";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import TableHeader from "../table/TableHeader";


const AcquirersTable = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const acquirersData = useAppSelector((state: RootState) => state.acquirer.acquirersData);
    const acquirersLoader = useAppSelector((state: RootState) => state.acquirer.acquirersLoader);
    const columnDefs = [
        {
            headerName: "ID",
            field: "id",
            width: 100
        },
        {
            headerName: t('Name'),
            field: "name",
        },
        {
            headerName: t('Status'),
            field: 'is_active',
            width: 100,
            cellRenderer: CheckboxRenderComponent,
        },
        {
            headerName: t('Country'),
            field: "country_code",
            cellRenderer: CountryRenderComponent,
        },
        {
            headerName: t('City'),
            field: "city",
        },
    ];

    useEffect(() => {
        dispatch(acquirer())
    }, []);

    const onRowClicked = (row: any) => {
        navigate('/acquirers-list/' + row.data.id + '/edit')
    }
    const createAcquirers = () => {
        navigate('/acquirers-list/create')
    }

    return (
        <div className="table__container">
            {acquirersLoader && <SecondaryLoader/>}
            <TableHeader title={t('Acquirers')}
                         create={createAcquirers}/>
            <div className="table__body">
                <div
                    className={classNames('table__with_pagination')}>
                    <AgGridComponent
                        rowData={acquirersData}
                        columnDefs={columnDefs}
                        onRowClicked={onRowClicked}/>
                </div>
            </div>
        </div>
    )
};

export default AcquirersTable;

