import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const UserRoleRenderComponent = (params: any) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="button button__cell" onClick={
            () => {
                navigate('/users-list/' + params.data.id + '/edit-role')
            }
        }> {t('Edit role')}</div>
    )
};

export default UserRoleRenderComponent;







