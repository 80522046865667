import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import classNames from "classnames";
import {setAccessCamera, setDecodedText, setQrLoader} from "../generate-qr-code/QrCodeSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import {useNavigate} from "react-router-dom";
import accessCameraImg from "../../assets/icon/ic_permission_camera_icon.svg";
import {Html5Qrcode, Html5QrcodeScanType} from "html5-qrcode";
import {toast} from "react-toastify";
import {addUnit, AddUnitState, setSuccessAddedUnit, setUnsuccessfulAddedUnit} from "./UnitsSlice";
import {useForm} from "react-hook-form";
import {style} from "../../assets/formErrorStyle";
import {user} from "../../app/authSlice";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";

const UnitScanQrCode = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const scanQrCodeLoader = useAppSelector((state: RootState) => state.qrCode.qrCodeLoader);
    let [qr, setQr] = React.useState<Html5Qrcode | null>(null)
    const decodedText = useAppSelector<any>((state: RootState) => state.qrCode.decodedText);
    const successUnitAddedFromServer = useAppSelector((state: RootState) => state.units.successAddedUnit);
    const unsuccessfulUnitAddedFromServer = useAppSelector((state: RootState) => state.units.unsuccessfulAddedUnit);
    const accessCamera = useAppSelector((state: RootState) => state.qrCode.accessCamera);
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid}
    } = useForm<AddUnitState>({mode: 'all'})
    const startScanQr = () => {
        dispatch(setQrLoader(true));
        Html5Qrcode.getCameras().then(devices => {
            dispatch(setQrLoader(false));
            if (devices && devices.length) {
                setQr(new Html5Qrcode('reader'))
            }
        }).catch(err => {
            dispatch(setQrLoader(false));
            dispatch(setAccessCamera(true));
            console.log(err)
        });
    }

    useEffect(() => {
    }, [scanQrCodeLoader]);

    const closeScanComponent = () => {
        navigate('/units-list/units')
    }

    useEffect(() => {
        startScanQr();
    }, [])

    useEffect(() => {
        let config = {
            fps: 8,
            qrbox: 200,
            faceMode: 'environment',
            rememberLastUsedCamera: true,
            supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA]
        };
        if (qr) {
            qr.start(
                {facingMode: 'environment'},
                config,
                (decodedText: any, decodedResult: any) => {
                    console.log(`Code matched = ${decodedText}`, decodedResult);
                    if (decodedText) {
                        dispatch(setDecodedText(JSON.parse(decodedText)));
                        // closeScan();
                    }
                },
                (error: any) => {
                    console.warn(`Code scan error = ${error}`);
                }
            );
        }
        return () => {
            closeScan();
        }
    }, [qr]);

    const closeScan = () => {
        if (qr) {
            qr.stop().then((ignore) => {
                console.log(ignore)
            }).catch((err) => {
                console.log(err)
            });
            setQr(null);
        }
    }

    useEffect(() => {
        if (decodedText) {
            setValue('imei', decodedText.imei);
            setQr(null);
        }
    }, [decodedText]);

    const onSubmit = handleSubmit((data: AddUnitState) => {
        dispatch(addUnit({
            name: data.name,
            imei: data.imei,
        }))
    })

    useEffect(() => {
        if (successUnitAddedFromServer) {
            dispatch(user())
            toast.success(t('Saved'));
            navigate('/units-list/units')
            dispatch(setDecodedText(null))
        }
        return () => {
            dispatch(setSuccessAddedUnit(false))
        }
    }, [successUnitAddedFromServer]);

    useEffect(() => {
        if (unsuccessfulUnitAddedFromServer) {
            toast.error(t('An error has occurred. Check imei and try again'));
        }
        return () => {
            dispatch(setUnsuccessfulAddedUnit(false))
        }
    }, [unsuccessfulUnitAddedFromServer]);

    useEffect(() => {
    }, [accessCamera]);

    return (
        <div className="table__container">
            <TableHeader title={t('Scan QR CODE')}
                         close={closeScanComponent}/>
            <div className="table__body">
                <div
                    className={classNames('add__block add__block_visible')}>
                    {scanQrCodeLoader && <SecondaryLoader/>}
                    {
                        !accessCamera
                        &&
                        (
                            <>
                                {
                                    !decodedText
                                    &&
                                    (
                                        <div className="scan__visible">
                                            <div id="reader" className="scan__visible">
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    decodedText
                                    &&
                                    (
                                        <form className="form" onSubmit={onSubmit}>
                                            <div className="form__input-container">
                                                <div className="form__input-item">
                                                    <FormErrorField error={errors.name}/>
                                                    <input
                                                        {...register('name', {
                                                            required: t('Required field'),
                                                        })}
                                                        className="form__input"
                                                        style={style(errors?.name)}
                                                        placeholder={t('Name')}
                                                        type="text"/>
                                                </div>
                                                <div className="form__input-item">
                                                    <FormErrorField error={errors.imei}/>
                                                    <input
                                                        {...register('imei', {
                                                            required: t('Required field'),
                                                        })}
                                                        className="form__input"
                                                        style={style(errors?.imei)}
                                                        disabled={decodedText.imei}
                                                        placeholder={t('IMEI')}
                                                        type="number"/>
                                                </div>
                                            </div>
                                            <div className="button__container mt-2">
                                                <button
                                                    type="submit"
                                                    className="button"
                                                    disabled={!isValid}
                                                >
                                                    {t('Save')}
                                                </button>
                                            </div>
                                        </form>
                                    )
                                }
                            </>
                        )
                    }

                    {
                        accessCamera
                        &&
                        (

                            <div className="access-camera">
                                <img src={accessCameraImg} alt="camera"/>
                                <div className="access-camera__title">
                                    {t('You need to grant access to your camera.')}
                                </div>
                            </div>

                        )
                    }
                </div>
            </div>
        </div>
    )
};

export default UnitScanQrCode;

