import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {style} from "../../assets/formErrorStyle";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {
    cardPayment,
    creditCard,
    setCardPaymentStatus,
    setRedirectUrl,
    topUpBalance,
    TopUpBalanceState
} from "./BalanceSlice";
import {toast} from "react-toastify";
import {user} from "../../app/authSlice";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";

const BalanceReplenish = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const redirectUrl = useAppSelector((state: RootState) => state.balanceReport.redirectUrl);
    const creditCards = useAppSelector((state: RootState) => state.balanceReport.creditCards);
    const cardPaymentStatus = useAppSelector((state: RootState) => state.balanceReport.cardPaymentStatus);
    const [chosenCardId, setChosenCardId] = React.useState(null);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors, isValid}
    } = useForm<TopUpBalanceState>({mode: 'all'})

    const onSubmit = handleSubmit((data) => {
        if (data.cardId === 'default') {
            dispatch(topUpBalance({amount: Number(data.amount)}))
        } else {
            dispatch(cardPayment({card_id: Number(data.cardId), amount: Number(data.amount)}))
        }
    })

    const onChangeCard = (val: any) => {
        setChosenCardId(val.target.value)
    }

    useEffect(() => {
        if (redirectUrl) {
            window.open(redirectUrl, '_self');
        }
        return () => {
            dispatch(setRedirectUrl(''))
        }
    }, [redirectUrl]);

    useEffect(() => {
        dispatch(creditCard())
    }, []);

    useEffect(() => {
        if (cardPaymentStatus === 'SUCCESS') {
            dispatch(user())
            toast.success(t('Payment was successful'));
            navigate('/balance-list/balance-report');
            dispatch(setCardPaymentStatus(''))
        }
        return () => {
            dispatch(setCardPaymentStatus(''))
        }
    }, [cardPaymentStatus]);

    const closeBalanceReplenish = () => {
        navigate('/balance-list/balance-report')
        reset()
    }
    return (
        <div className="table__container">
            <TableHeader
                title={t('Top up balance')}
                close={closeBalanceReplenish}/>
            <div className="table__body">
                <div
                    className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.amount}/>
                                <input
                                    {...register('amount', {
                                        required: t('Required field'),
                                        pattern: {
                                            value: /^[0-9]*[.,]?[0-9]{0,2}$/,
                                            message: t('Field must be a real number')
                                        }
                                    })}
                                    className="form__input"
                                    style={style(errors?.amount)}
                                    placeholder={t('Top-up amount')}
                                    type="number"/>
                            </div>
                        </div>
                        <div className="form__title">
                            {t('Payment method')}
                        </div>
                        <div className="form__card-container">
                            <div
                                className={classNames('form__card-item', chosenCardId === 'default' ? ' form__card-item_active' : '')}>
                                <div className="radio__container">
                                    <input
                                        {...register('cardId', {
                                            required: t('Required field')
                                        })}
                                        type="radio"
                                        value="default"
                                        className="radio"
                                        onClick={onChangeCard}
                                        id="default"/>
                                    <label className="radio__label w-100" htmlFor="default">
                                                <span className={'card-icon ic_payment_card_logo_default'}>

                                                </span>
                                        <p>
                                            {t('New card')}
                                        </p>

                                    </label>
                                </div>
                            </div>
                            {
                                creditCards.length > 0
                                &&
                                creditCards.map((card: any) => (
                                    <div
                                        className={classNames('form__card-item', chosenCardId === card.id.toString() ? ' form__card-item_active' : '')}
                                        key={card.id}>
                                        <div className="radio__container">
                                            <input
                                                {...register('cardId', {
                                                    required: t('Required field')
                                                })}
                                                type="radio"
                                                value={card.id}
                                                className="radio"
                                                onClick={onChangeCard}
                                                id={card.id}/>
                                            <label className="radio__label w-100" htmlFor={card.id}>
                                                <span className={'card-icon ic_payment_card_logo_' + card.type}>

                                                </span>
                                                <p>
                                                    {card.first_six_digits}** ****
                                                </p>
                                                <p>
                                                    {card.last_four_digits}
                                                </p>
                                            </label>
                                        </div>
                                    </div>
                                ))
                            }
                            {/*<div className="form__card_item">*/}
                            {/*    <div className="radio__container">*/}
                            {/*        <input type="radio" name="site_name" value="true" className="radio" id="test"/>*/}
                            {/*        <label htmlFor="test">*/}
                            {/*            **** **** *****/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>


                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Pay')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default BalanceReplenish;

