import {Navigate, Route, Routes} from "react-router-dom";
import Layout from "./components/layout/Layout";
import DashboardComponent from "./components/dashboard/DashboardComponent";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {RootState} from "./app/store";
import {languages, setSuccess, user} from "./app/authSlice";
import React, {useEffect} from "react";
import MainLoader from "./components/mainLoader/MainLoader";
import Profile from "./components/profile/Profile";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation} from "react-i18next";
import PricePlanTable from "./components/price-plan/PricePlanTable";
import QrCode from "./components/generate-qr-code/QrCode";
import PricePlan from "./components/price-plan/PricePlan";
import Authentication from "./components/authentication/Authentication";
import SingIn from "./components/authentication/Sing-in";
import SingUp from "./components/authentication/Sign-up";
import EmailVerify from "./components/authentication/Email-verify";
import UnitsList from "./components/units/UnitsList";
import UnitCreate from "./components/units/UnitCreate";
import UnitScanQrCode from "./components/units/UnitScanQrCode";
import UnitsTable from "./components/units/UnitsTable";
import BalanceReportList from "./components/balance-list/BalanceReportList";
import BalanceReplenish from "./components/balance-list/BalanceReplenish";
import BalanceReportTable from "./components/balance-list/BalanceReportTable";
import Payments from "./components/invoices/Payments";
import Recovery from "./components/authentication/Recovery";
import RestorePassword from "./components/authentication/RestorePassword";
import AcquirersList from "./components/acquirers/AcquirersList";
import AcquirersCreate from "./components/acquirers/AcquirersCreate";
import AcquirersTable from "./components/acquirers/AcquirersTable";
import UsersList from "./components/users/UsersList";
import UsersAddBalance from "./components/users/UsersAddBalance";
import UsersTable from "./components/users/UsersTable";
import UsersEditRole from "./components/users/UsersEditRole";
import AcquirersEdit from "./components/acquirers/AcquirersEdit";
import PricePlanCreate from "./components/price-plan/PricePlanCreate";
import Contract from "./components/documents/Contract";
import Deeds from "./components/documents/Deeds";
import {defineAbilitiesFor} from "./app/ability";
import {AbilityContext} from "./app/can";
import Requisites from "./components/requisites/Requisites";
import CounterpartiesList from "./components/counterparties/CounterpartiesList";
import CounterpartiesTable from "./components/counterparties/CounterpartiesTable";
import CounterpartiesCreate from "./components/counterparties/CounterpartiesCreate";
import CounterpartiesEdit from "./components/counterparties/CounterpartiesEdit";
import UsersAssignCounterparty from "./components/users/UsersAssignCounterparty";
import {setLanguage} from "./app/appSlice";
import i18n from "i18next";
import Imei from "./components/unit-imei/UnitImei";
import UnitImei from "./components/unit-imei/UnitImei";
import FranchiseeList from "./components/franchisee/FranchiseeList";
import FranchiseeTable from "./components/franchisee/FranchiseeTable";

export let getToken: string | null = null;

function App() {
    const {t} = useTranslation();
    const authLoading = useAppSelector((state: RootState) => state.auth.authLoading);
    const token = useAppSelector((state: RootState) => state.auth.access_token);
    const dispatch = useAppDispatch();
    const refresh_token = localStorage.getItem('refresh_token');
    const successMessageFromServer = useAppSelector((state: RootState) => state.auth.successAuthMessage);
    const userData = useAppSelector((state: RootState) => state.auth.userData);
    const authenticationRole = useAppSelector((state: RootState) => state.auth.authenticationRole);

    useEffect(() => {
        console.log(authenticationRole)
        defineAbilitiesFor(authenticationRole);
    }, [authenticationRole]);

    useEffect(() => {
        if (successMessageFromServer) {
            toast.success(t('Saved'));
            dispatch(setSuccess(false))
        }
    }, [successMessageFromServer])

    useEffect(() => {
        console.log(token)
        if (token) {
            getToken = token;
            dispatch(user())
        }
    }, [token]);

    const getLanguages = (language: string) => {
        dispatch(setLanguage(language));
        i18n.changeLanguage(language);
    }

    useEffect(() => {
        const languageLocalStorage = localStorage.getItem('language');
        if (languageLocalStorage) {
            getLanguages(languageLocalStorage)
        } else if (navigator.language) {
            languages.forEach(lang => {
                if (navigator.language.includes(lang.value)) {
                    getLanguages(lang.value)
                } else {
                    getLanguages(languages[0].value)
                }
            })
        } else {
            getLanguages(languages[0].value)
        }
    }, []);

    return (
        <AbilityContext.Provider value={defineAbilitiesFor(authenticationRole)}>
            {authLoading
            &&
            <MainLoader/>
            }
            {
                // token && refresh_token && userData.id
                (userData && userData.id && refresh_token)
                &&
                (
                    <Routes>
                        <Route element={<Layout/>}>
                            <Route path="dashboard" element={<DashboardComponent/>}/>
                            <Route path="units-list" element={<UnitsList/>}>
                                <Route path="units" element={<UnitsTable/>}/>
                                <Route path="unit-create" element={<UnitCreate/>}/>
                                <Route path="unit-scan-qr-code" element={<UnitScanQrCode/>}/>
                            </Route>
                            <Route path="franchisee-list" element={<FranchiseeList/>}>
                                <Route path="franchisee" element={<FranchiseeTable/>}/>
                            </Route>
                            <Route path="profile" element={<Profile/>}/>
                            <Route path="balance-list" element={<BalanceReportList/>}>
                                <Route path="balance-report" element={<BalanceReportTable/>}/>
                                <Route path="balance-replenish" element={<BalanceReplenish/>}/>
                            </Route>
                            <Route path="payments" element={<Payments/>}/>
                            {
                                authenticationRole !== 'customer'
                                &&
                                (
                                    <>
                                        <Route path="users-list" element={<UsersList/>}>
                                            <Route path="users" element={<UsersTable/>}/>
                                            <Route path=":id/add-balance" element={<UsersAddBalance/>}/>
                                            <Route path=":id/edit-role" element={<UsersEditRole/>}/>
                                            <Route path=":id/assign-counterparty"
                                                   element={<UsersAssignCounterparty/>}/>
                                        </Route>
                                        <Route path="price-plan" element={<PricePlan/>}>
                                            <Route path="list" element={<PricePlanTable/>}/>
                                            <Route path="list/price-plan-create" element={<PricePlanCreate/>}/>
                                            <Route path="qr-code" element={<QrCode/>}/>
                                        </Route>
                                        <Route path="acquirers-list" element={<AcquirersList/>}>
                                            <Route path="acquirers" element={<AcquirersTable/>}/>
                                            <Route path="create" element={<AcquirersCreate/>}/>
                                            <Route path=":id/edit" element={<AcquirersEdit/>}/>
                                        </Route>
                                        <Route path="counterparties-list" element={<CounterpartiesList/>}>
                                            <Route path="counterparties" element={<CounterpartiesTable/>}/>
                                            <Route path="create" element={<CounterpartiesCreate/>}/>
                                            <Route path=":id/edit" element={<CounterpartiesEdit/>}/>
                                        </Route>
                                    </>
                                )
                            }
                            {
                                (authenticationRole === 'customer')
                                &&
                                (
                                    <>
                                        <Route path="contract" element={<Contract/>}/>
                                        <Route path="deeds" element={<Deeds/>}/>
                                        <Route path="requisites" element={<Requisites/>}/>
                                    </>
                                )
                            }
                        </Route>
                        <Route path="unit-imei" element={<UnitImei/>}/>
                        <Route path="*" element={<Navigate replace to="/dashboard"/>}/>
                    </Routes>
                )
            }
            {
                !refresh_token
                &&
                (
                    <Routes>
                        <Route path="login/*" element={<Authentication/>}>
                            <Route path="sing-in" element={<SingIn/>}/>
                            <Route path="sing-up" element={<SingUp/>}/>
                            <Route path="recovery" element={<Recovery/>}/>
                            <Route path="restore-password" element={<RestorePassword/>}/>
                        </Route>
                        <Route path="login" element={<Navigate replace to="/login/sing-in"/>}/>
                        <Route path="email-verify" element={<EmailVerify/>}/>
                        <Route path="*" element={<Navigate replace to="/login/sing-in"/>}/>
                        <Route path="unit-imei" element={<Imei/>}/>
                    </Routes>
                )
            }

            <ToastContainer/>
        </AbilityContext.Provider>
    )
}

export default App;
