import React, {useEffect} from 'react';
import "../../css/main.scss";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {useTranslation} from "react-i18next";
import {setSearchOnChange} from "./SearchSlice";
import {RootState} from "../../app/store";

function Search() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const searchValue = useAppSelector((state: RootState) => state.search.searchValue);
    const onChange = (val: any) => {
        dispatch(setSearchOnChange(val.target.value))
    }

    return (
        <div className="header__search">
            <input
                placeholder={t('Search')}
                onChange={onChange}
                className="header__search-input"
                value={searchValue ? searchValue : ''}
                type="text"/>
        </div>
    )
}

export default Search;

