import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {customers} from "./DashboardSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import DonutChart from "react-donut-chart";
import {dashboardColors} from "./DashboardComponent";
import {countryOptions} from "../utils/CountryOptions";

const DashboardCustomers = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const customersData = useAppSelector<any>((state: RootState) => state.dashboard.customersData);
    const dashboardPreloader = useAppSelector((state: RootState) => state.dashboard.dashboardPreloader);

    useEffect(() => {
        dispatch(customers())
    }, []);

    return (
        <div className="dashboard__container w-100">
            <div className="dashboard__row w-100">
                {dashboardPreloader &&
                <SecondaryLoader/>}
                <div className="dashboard__item_donut-container w-100">
                    <h2 className="title__secondary">{t('Number of users')}</h2>
                    <div className="dashboard__item_donut-body">
                        {customersData.map((item: any) => (
                            <div key={item.id} className="donut__item">
                                <div className="country__container dashboard__country-container">
                                    <div className={'icon-country icon_wh-1-25 icon-country-' + item.country_code}>
                                    </div>
                                    {countryOptions().find((e: any) => item.country_code === e.value)?.label}
                                </div>
                                <DonutChart
                                    legend={false}
                                    height={200}
                                    width={200}
                                    formatValues={(values: any) => `${values}`}
                                    innerRadius={0.70}
                                    strokeColor='transparent'
                                    colors={dashboardColors}
                                    data={[
                                        {
                                            label: t('Administrators'),
                                            value: item.data.count_managers ? item.data.count_managers : 0
                                        },
                                        {
                                            label: t('Users'),
                                            value: item.data.count_customers ? item.data.count_customers : 0
                                        },
                                    ]}
                                />
                            </div>
                        ))
                        }
                    </div>
                    <div className="dashboard__item_donut-legends">
                        <div className="legends__item">
                            <div className="legends__square" style={{background: dashboardColors[0]}}>
                            </div>
                            <div className="legends__title">
                                {t('Administrators')}
                            </div>
                        </div>
                        <div className="legends__item">
                            <div className="legends__square" style={{background: dashboardColors[1]}}>
                            </div>
                            <div className="legends__title">
                                {t('Users')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DashboardCustomers;

