import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {toast} from "react-toastify";
import {setError, setSuccess, updateUser} from "../../app/authSlice";
import arrowRight from "../../assets/icon/arrow-right.svg";
import close from "../../assets/icon/close.svg";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {style} from "../../assets/formErrorStyle";
import {useNavigate, useParams} from "react-router-dom";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";

const UsersAddBalance = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    let {id} = useParams();
    const successMessageFromServer = useAppSelector((state: RootState) => state.auth.successAuthMessage);
    const errorMessageFromServer = useAppSelector((state: RootState) => state.auth.errorAuthMessage);

    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<{
        add_balance: string,
        description: string,
    }>({mode: 'all'})

    useEffect(() => {
        if (successMessageFromServer) {
            navigate('/users-list/users')
            dispatch(setSuccess(false))
        }
    }, [successMessageFromServer])

    useEffect(() => {
        if (errorMessageFromServer) {
            toast.error(t('Failed to top up'));
            dispatch(setError(false))
        }
    }, [errorMessageFromServer])

    const onSubmit = handleSubmit((data: any) => {
        dispatch(updateUser({
            add_balance: Number(data.add_balance),
            description: data.description,
            user_id: Number(id)
        }))
    })

    const closeUserBalance = () => {
        navigate('/users-list/users')
    }

    return (
        <div className="table__container">
            <TableHeader
                id={id}
                title={t('Balance replenishment')}
                close={closeUserBalance}/>
            <div className="table__body">
                <div className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.add_balance}/>
                                <input
                                    {...register('add_balance',
                                        {
                                            required: t('Required field'),
                                            pattern: {
                                                value: /^[0-9]*[.,]?[0-9]{0,2}$/,
                                                message: t('Invalid number format')
                                            }

                                        })}
                                    className="form__input"
                                    style={style(errors?.add_balance)}
                                    placeholder={t('Amount of credit to the balance')}
                                    type="tel"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.description}/>
                                <textarea
                                    {...register('description',
                                        {
                                            required: t('Required field'),
                                        })}
                                    style={style(errors?.description)}
                                    placeholder={t('Description')}
                                />
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UsersAddBalance;

