import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import classNames from "classnames";
import {RootState} from "../../app/store";
import AgGridComponent from "../ag-grid/AgGridComponent";
import {useNavigate} from "react-router-dom";
import Pagination from "../pagination/Pagination";
import {PaginationType} from "../pagination/PaginationType";
import {balanceReport} from "./BalanceSlice";
import TableHeader from "../table/TableHeader";


const BalanceReportTable = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const balanceReportArray = useAppSelector((state: RootState) => state.balanceReport.balanceReport);
    const balancePaginate = useAppSelector((state: RootState) => state.balanceReport.paginate);
    const [paginationEvent, setPaginationEvent] = React.useState<PaginationType>({
        page: 1,
        per_page: 25,
        last_page: 1,
        total: 1
    });
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(balanceReport({
            page: paginationEvent.page,
            per_page: paginationEvent.per_page
        }))
    }, [paginationEvent]);

    const columnDefs = [
        {
            headerName: "Id",
            field: "id",
            width: 100,
        },
        {
            headerName: t('Amount'),
            field: "amount",
        },
        {
            headerName: t('Time of operation'),
            field: "created_at",
        },
    ];
    const updateDataPagination = (value: PaginationType) => {
        setPaginationEvent({
            page: value.page,
            per_page: value.per_page,
            last_page: value.last_page,
            total: value.total
        })
    }
    const createBalance = () => {
        navigate('/balance-list/balance-replenish')
    }
    return (
        <div className="table__container">
            <TableHeader
                title={t('History of balance')}
                create={createBalance}/>
            <div className="table__body">
                <div
                    className={classNames('table__with_pagination')}>
                    <AgGridComponent rowData={balanceReportArray}
                                     columnDefs={columnDefs}/>
                    {
                        balancePaginate
                        &&
                        <Pagination updateDataPagination={updateDataPagination}
                                    pagination={balancePaginate}/>
                    }
                </div>
            </div>
        </div>
    )
};

export default BalanceReportTable;

