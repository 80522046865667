import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

const FRANCHISEE = gql`
query {
franchiseeList {
        id
        name
        phone
        city
  } 
}
`;

export const franchiseeList = createAsyncThunk(
    'franchisee',
    async () => {
        const response = await client.mutate({mutation: FRANCHISEE})
        return response.data
    }
)

const initialState = {
    franchiseeListData: [],
    franchiseeLoader: false,
}

const franchiseeSlice = createSlice({
    name: 'franchisee',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(franchiseeList.pending, (state: any) => {
                    state.franchiseeLoader = true;
                }
            )
            .addCase(franchiseeList.fulfilled, (state: any, action) => {
                    state.franchiseeListData = action.payload.franchiseeList
                    state.franchiseeLoader = false;
                }
            )
            .addCase(franchiseeList.rejected, (state: any) => {
                    state.franchiseeLoader = false;
                }
            )
    }
})

export default franchiseeSlice.reducer;


