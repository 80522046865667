import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from '@apollo/client';
import {onError} from "@apollo/client/link/error";
import {environment} from "../environments/environment";
import {toast} from "react-toastify";
import {getToken} from "../App";
import {logOut} from "../components/layout/Layout";

const httpLink = new HttpLink({uri: environment.apiBasepoint});

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({headers = {}}) => ({
        headers: {
            ...headers,
            authorization: getToken ? 'Bearer ' + getToken : null,
        }
    }));
    return forward(operation);
})

const errorLink = onError(({graphQLErrors, networkError}) => {

        if (graphQLErrors) {

            if (
                graphQLErrors[0].message === 'Unauthenticated.'
                || (graphQLErrors[0] as any).debugMessage === 'User not found'
                || (graphQLErrors[0] as any).debugMessage === 'DB User not found'
                || (graphQLErrors[0] as any).debugMessage === 'Authorization failed'
            ) {
                // logout
                console.log(graphQLErrors[0].message)
                logOut();
            }

            if ((graphQLErrors[0] as any).debugMessage) {
                toast.error((graphQLErrors[0] as any).debugMessage);
            }

            graphQLErrors.forEach(({extensions}) => {
                    console.log(extensions)
                    if (extensions.category === 'authentication' || extensions.category === 'notifications') {
                        toast.error(extensions.reason);
                        // if(extensions.reason === 'The refresh token is invalid.') {
                        //     // localStorage.removeItem('refresh_token');
                        // }
                    }
                    if (extensions.category === 'graphql') {
                        toast.error('Server error');
                    }
                    const validationErrorMessages: any = []
                    if (extensions.category === 'validation') {
                        if (extensions.validation) {
                            Object.keys(extensions.validation).forEach(
                                result => validationErrorMessages.push({
                                    name: result,
                                    errorMessage: extensions.validation[result][0],
                                })
                            );
                            validationErrorMessages.forEach((message: any) => {
                                toast.error(message.errorMessage);
                            })
                        }
                    }
                }
            )
        }

    }
);


export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
        authMiddleware,
        errorLink,
        httpLink
    ]),
});


