import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {users} from "./usersSlice";
import {RootState} from "../../app/store";
import AgGridComponent from "../ag-grid/AgGridComponent";
import Pagination from "../pagination/Pagination";
import {PaginationType} from "../pagination/PaginationType";
import {setSearchOnChange, setSearchVisible} from "../search/SearchSlice";
import EnterRenderComponent from "./EnterRenderComponent";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";
import UserRoleRenderComponent from "./UserRoleRenderComponent";
import AssignCounterpartyRenderComponent from "./AssignCounterpartyRenderComponent";
import TableHeader from "../table/TableHeader";

const UsersTable = () => {
        const {t} = useTranslation();
        const dispatch = useAppDispatch();
        const navigate = useNavigate();
        const searchValue = useAppSelector((state: RootState) => state.search.searchValue);
        const usersArrayData = useAppSelector((state: RootState) => state.users.usersData);
        const usersPaginate = useAppSelector((state: RootState) => state.users.paginate);
        const columnDefs = [
            {
                headerName: t("Log in"),
                field: "enter",
                width: 80,
                cellRenderer: EnterRenderComponent,
            },
            {
                headerName: "id",
                field: "id",
                width: 100,
            },
            {
                headerName: t('Name'),
                field: "name",
            },
            {
                headerName: t('E-mail'),
                field: "email",
            },
            {
                headerName: t('Phone'),
                field: "phone",
            },
            {
                headerName: t('City'),
                field: "city",
            },
            {
                headerName: t('Date of creation'),
                field: "created_at",
            },
            {
                headerName: t('Last update'),
                field: "updated_at",
            },
            {
                headerName: t('Balance'),
                field: "balance",
                valueGetter: (params: any) => {
                    return params.data && params.data.balance ? params.data.balance + ' ' + params.data.currency : null;
                },
            },

            {
                headerName: t('Role'),
                field: "role",
                valueGetter: (params: any) => {
                    if (params.data && params.data.role) {
                        switch (params.data.role) {
                            case 'customer':
                                return t('User');
                            case 'distributor':
                                return t('Distributor');
                            case 'root':
                                return t('Root');
                        }
                    }
                    return null;
                },
            },
            {
                headerName: t('Edit role'),
                width: 200,
                cellRenderer: UserRoleRenderComponent,
            },
            {
                headerName: t('Assign a counterparty'),
                width: 250,
                cellRenderer: AssignCounterpartyRenderComponent,
            }
        ];

        const [paginationEvent, setPaginationEvent] = React.useState<PaginationType>({
            page: 1,
            per_page: 25,
            last_page: 1,
            total: 1
        });

        useEffect(() => {
            dispatch(users({
                page: paginationEvent.page,
                per_page: paginationEvent.per_page,
                search: searchValue
            }))
        }, [paginationEvent, searchValue]);

        const updateDataPagination = (value: PaginationType) => {
            setPaginationEvent({
                page: value.page,
                per_page: value.per_page,
                last_page: value.last_page,
                total: value.total
            })
        }

        /* SEARCH */
        useEffect(() => {
            dispatch(setSearchVisible(true))
            return () => {
                dispatch(setSearchVisible(false))
                dispatch(setSearchOnChange(''))
            }
        }, [])

        const onRowClicked = (user: any) => {
            navigate('/users-list/' + user.data.id + '/add-balance')
        }

        return (
            <div className="table__container">
                <TableHeader
                    title={t('Clients')}/>
                <div className="table__body">
                    <div className={classNames('table__with_pagination')}>
                        <AgGridComponent
                            rowData={usersArrayData}
                            columnDefs={columnDefs}
                            onRowClicked={onRowClicked}
                        />
                        {
                            usersPaginate
                            &&
                            <Pagination updateDataPagination={updateDataPagination}
                                        pagination={usersPaginate}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
;

export default UsersTable;

