import React from 'react';
import {Route, Routes} from "react-router-dom";
import UsersAddBalance from "./UsersAddBalance";
import UsersTable from "./UsersTable";
import UsersEditRole from "./UsersEditRole";
import UsersAssignCounterparty from "./UsersAssignCounterparty";

const UsersList = () => {

    return (
        <Routes>
            <Route path="users" element={<UsersTable/>}/>
            <Route path=":id/add-balance" element={<UsersAddBalance/>}/>
            <Route path=":id/edit-role" element={<UsersEditRole/>}/>
            <Route path=":id/assign-counterparty" element={<UsersAssignCounterparty/>}/>
        </Routes>
    )
};

export default UsersList;

