import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import classNames from "classnames";
import {counterparties, setCreateResult} from "./CounterpartiesSlice";
import AgGridComponent from "../ag-grid/AgGridComponent";
import {useNavigate} from "react-router-dom";
import CountryRenderComponent from "../ContryRenderComponent";
import CheckboxRenderComponent from "./CheckboxRenderComponent";
import {toast} from "react-toastify";
import {AbilityContext} from "../../app/can";
import TableHeader from "../table/TableHeader";

const CounterpartiesTable = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ability = useContext(AbilityContext);
    const counterpartiesData = useAppSelector((state: RootState) => state.counterparties.counterpartiesData);
    const createResult = useAppSelector((state: RootState) => state.counterparties.createResult);
    const [columnDefs, setColumnDefs] = React.useState<any>(
        [
            {
                headerName: t('ID'),
                field: "id",
                width: 100,
            },
            {
                headerName: t('Name'),
                field: "name",
            },
            {
                headerName: t('Type of taxation'),
                field: "vat_status",
                valueGetter: (p: any) => {
                    return p.data && p.data.vat_status ? t('including VAT') : t('excluding VAT')
                },
            },
            {
                headerName: t('API URL'),
                field: "api_link",
            },
            {
                headerName: t('By default'),
                field: "is_default",
                cellRenderer: CheckboxRenderComponent,
            },
        ]
    )
    useEffect(() => {
        if (createResult) {
            toast.success(t('Saved'));
            dispatch(setCreateResult(false))
            dispatch(counterparties())
        }
    }, [createResult]);

    useEffect(() => {
        dispatch(counterparties())
    }, [])

    useEffect(() => {
        if (ability.can('read', 'counterparties', 'country')) {
            const countries = {
                headerName: t('Country'),
                field: "country_code",
                cellRenderer: CountryRenderComponent,
            }

            if (!columnDefs.find((el: any) => el.field === 'country_code')) {
                setColumnDefs([...columnDefs, countries])
            }
        }
    }, [ability])

    const onRowClicked = (row: any) => {
        navigate('/counterparties-list/' + row.data.id + '/edit')
    }
    const createCounterparty = () => {
        navigate('/counterparties-list/create')
    }
    return (
        <div className="table__container">
            <TableHeader
                title={t('Counterparties')}
                create={createCounterparty}/>
            <div className="table__body">
                <div
                    className={classNames('table__with_pagination')}>
                    <AgGridComponent
                        rowData={counterpartiesData}
                        columnDefs={columnDefs}
                        onRowClicked={onRowClicked}
                    />
                </div>
            </div>
        </div>
    )
};

export default CounterpartiesTable;

