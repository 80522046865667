import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState = {
    searchVisible: false,
    searchValue: null,
}

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setSearchVisible(state: any, action: PayloadAction<boolean>) {
            state.searchVisible = action.payload;
            state.searchValue = '';
        },
        setSearchOnChange(state: any, action: any) {
            state.searchValue = action.payload;
        },
    }
})

export const {
    setSearchVisible,
    setSearchOnChange,
} = searchSlice.actions;

export default searchSlice.reducer;


