import React, {useEffect} from 'react';
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {
    FormPricePlanState,
    pricePlans,
    setSelectedPricePlan,
    setSuccessUpdatePricePlan,
    updatePricePlans
} from "./pricePlanSlice";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import AgGridComponent from "../ag-grid/AgGridComponent";
import {useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {style} from "../../assets/formErrorStyle";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";

const PricePlanTable = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [state, setState] = React.useState<any>({openPricePlan: false, pricePlanData: null});
    const pricePlanArrayData = useAppSelector((state: RootState) => state.pricePlans.pricePlans);
    const selectedPricePlan = useAppSelector((state: RootState) => state.pricePlans.selectedPricePlan);
    const successUpdatePricePlan = useAppSelector((state: RootState) => state.pricePlans.successUpdatePricePlan);
    const pricePlanLoader = useAppSelector((state: RootState) => state.pricePlans.pricePlanLoader);

    useEffect(() => {
        if (successUpdatePricePlan) {
            toast.success(t('Saved'));
            dispatch(pricePlans())
            dispatch(setSuccessUpdatePricePlan(false))
            setState({...state, openPricePlan: false, pricePlanData: null})
        }
    }, [successUpdatePricePlan]);

    const {
        register,
        handleSubmit,
        setValue,
        reset,
        formState: {errors, isValid}
    } = useForm<FormPricePlanState>({mode: "all"})

    useEffect(() => {
        dispatch(pricePlans())
    }, []);

    const columnDefs = [
        {
            headerName: "ID",
            field: "rate_id",
            width: 100
        },
        {
            headerName: t('Title'),
            field: "name",
        },
        {
            headerName: t('Price'),
            field: "price",
        },
        {
            headerName: t('Country'),
            field: "country",
            valueGetter: (p: any) => {
                if (p.data.country) {
                    if (p.data.country === 'BY') {
                        return t('Belarus')
                    }
                    if (p.data.country === 'RU') {
                        return t('Russia')
                    }
                    if (p.data.country === 'LT') {
                        return t('Lithuania')
                    }
                }
                return null;
            },
        },
        {
            headerName: t('Amount credited to the balance'),
            field: "add_to_balance",
        },
    ];
    const onRowClicked = (pricePlan: any) => {
        setState({...state, openPricePlan: true, pricePlanData: pricePlan.data})
        dispatch(setSelectedPricePlan(pricePlan.data))
        setValue('price', pricePlan.data.price);
    }
    const onSubmit = handleSubmit((data) => {
        dispatch(updatePricePlans({
            rate_id: Number(selectedPricePlan.rate_id),
            price: Number(data.price),
            country_code: state.pricePlanData.country,
        }));
    })
    const createPricePlan = () => {
        navigate('price-plan-create')
    }

    const closePricePlan = () => {
        setState({...state, openPricePlan: false})
        reset()
    }
    return (
        <div className="table__container">
            {pricePlanLoader && <SecondaryLoader/>}
            {
                !state.openPricePlan
                &&
                (
                    <TableHeader title={t('Price plans')}
                                 create={createPricePlan}/>
                )
            }

            {
                state.openPricePlan
                &&
                (
                    <TableHeader
                        id={state.pricePlanData.rate_id}
                        title={t('Editing a tariff plan')}
                        close={closePricePlan}/>
                )
            }

            <div className="table__body">
                <div
                    className={classNames('table__with_pagination', state.openPricePlan ? ' table__with_pagination-hidden' : '')}>
                    <AgGridComponent rowData={pricePlanArrayData}
                                     columnDefs={columnDefs}
                                     onRowClicked={onRowClicked}/>
                </div>
                <div
                    className={classNames('add__block add__block_hidden', state.openPricePlan ? ' add__block_visible' : '')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.price}/>
                                <input
                                    {...register('price',
                                        {
                                            required: t('Required field'),
                                            pattern: {
                                                value: /^[0-9]*[.,]?[0-9]{0,2}$/,
                                                message: t('Invalid number format')
                                            }
                                        })}
                                    className="form__input"
                                    style={style(errors?.price)}
                                    placeholder={t('Enter price')}
                                    type="tel"/>
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default PricePlanTable;

