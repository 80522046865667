import React from 'react';
import plus from "../../assets/icon/plus.svg";
import closeIcon from "../../assets/icon/close.svg";
import qrCode from "../../assets/icon/qr.svg";
import arrowRight from "../../assets/icon/arrow-right.svg";

type TableHeaderProps = {
    id?: number | string;
    title?: string;
    create?: () => void
    close?: () => void
    scanQrCode?: () => void
}

const TableHeader = ({id, title, create, close, scanQrCode}: TableHeaderProps) => {
    const onCLickCreate = () => {
        if (create) {
            create()
        }
    }

    const onCLickScanQrCode = () => {
        if (scanQrCode) {
            scanQrCode()
        }
    }

    const onCLickClose = () => {
        if (close) {
            close()
        }
    }

    return (
        <div className="table__header">

            <div className="table__title">
                {
                    id ?
                        <>
                            ID: {id}
                            <img className="icon icon_wh-14" src={arrowRight} alt="arrow"/>
                        </> : ''
                }
                {title}
            </div>
            <div className="button__container_g-1 ">
                {
                    create ?
                        <div className="button button__round" onClick={onCLickCreate}>
                            <img className="icon icon_wh-24" src={plus} alt="+"/>
                        </div>
                        : ''
                }
                {
                    scanQrCode ?
                        <div className="button button__round button__round_light" onClick={onCLickScanQrCode}>
                            <img className="icon icon_wh-24" src={qrCode} alt="qr"/>
                        </div>
                        : ''
                }
                {
                    close ?
                        <div className="button button__round button__round_light" onClick={onCLickClose}>
                            <img className="icon icon_wh-24" src={closeIcon} alt="close"/>
                        </div>
                        : ''
                }
            </div>
        </div>
    )
};

export default TableHeader;







