import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

export interface CounterpartyType {
    name: string
    country_code: string
    vat_status: string
    api_link: string
    is_default: boolean
}

const COUNTERPARTIES = gql`
query {
contractorList {
     id
     name
     country_code
     vat_status
     api_link
     is_default
  } 
}
`;
const ADD_CONTRACTOR = gql`
mutation addContractor($input: AddContractor!) {
addContractor(input: $input) {
  result
  }
}
`;
const UPDATE_CONTRACTOR = gql`
mutation updateContractor($input: AddContractor!) {
updateContractor(input: $input) {
  result
  }
}
`;

const INFO_CONTRACTOR = gql`
mutation contractorInfo($input: ContractorInfo!) {
contractorInfo(input: $input) {
     id
     name
     country_code
     vat_status
     api_link
     is_default
  }
}
`;

export const counterparties = createAsyncThunk(
    'counterparties',
    async () => {
        const response = await client.mutate({mutation: COUNTERPARTIES})
        return response.data
    }
)
export const createCounterparties = createAsyncThunk(
    'createCounterparties',
    async (form: any) => {
        const response = await client.mutate({mutation: ADD_CONTRACTOR, variables: {input: form}})
        return response.data
    }
)
export const updateCounterparties = createAsyncThunk(
    'updateCounterparties',
    async (form: any) => {
        const response = await client.mutate({mutation: UPDATE_CONTRACTOR, variables: {input: form}})
        return response.data
    }
)
export const infoCounterparties = createAsyncThunk(
    'infoCounterparties',
    async (form: any) => {
        const response = await client.mutate({mutation: INFO_CONTRACTOR, variables: {input: form}})
        return response.data
    }
)
const initialState = {
    counterpartiesData: null,
    counterpartiesLoader: false,
    createResult: false,
    contractorInfo: null
}

const counterpartiesSlice = createSlice({
    name: 'counterparties',
    initialState,
    reducers: {
        setCreateResult: (state: any, action: PayloadAction<boolean>) => {
            state.createResult = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(counterparties.pending, (state: any) => {
                    state.counterpartiesLoader = true;
                }
            )
            .addCase(counterparties.fulfilled, (state: any, action: PayloadAction<{ contractorList: CounterpartyType[] }>) => {
                    state.counterpartiesLoader = false;
                    state.counterpartiesData = action.payload.contractorList
                }
            )
            .addCase(counterparties.rejected, (state: any) => {
                    state.counterpartiesLoader = false;
                }
            )
            .addCase(createCounterparties.pending, (state: any) => {
                    state.counterpartiesLoader = true;
                }
            )
            .addCase(createCounterparties.fulfilled, (state: any, action: PayloadAction<{ addContractor: { result: boolean } }>) => {
                    state.counterpartiesLoader = false;
                    state.createResult = action.payload.addContractor.result;
                }
            )
            .addCase(createCounterparties.rejected, (state: any) => {
                    state.counterpartiesLoader = false;
                }
            )
            .addCase(updateCounterparties.pending, (state: any) => {
                    state.counterpartiesLoader = true;
                }
            )
            .addCase(updateCounterparties.fulfilled, (state: any, action: PayloadAction<{ updateContractor: { result: boolean } }>) => {
                    state.counterpartiesLoader = false;
                    state.createResult = action.payload.updateContractor.result;
                }
            )
            .addCase(updateCounterparties.rejected, (state: any) => {
                    state.counterpartiesLoader = false;
                }
            )
            .addCase(infoCounterparties.pending, (state: any) => {
                    state.counterpartiesLoader = true;
                }
            )
            .addCase(infoCounterparties.fulfilled, (state: any, action: PayloadAction<{ contractorInfo: CounterpartyType }>) => {
                    state.counterpartiesLoader = false;
                    state.contractorInfo = action.payload.contractorInfo;
                }
            )
            .addCase(infoCounterparties.rejected, (state: any) => {
                    state.counterpartiesLoader = false;
                }
            )
    }
})

export const {
    setCreateResult
} = counterpartiesSlice.actions;

export default counterpartiesSlice.reducer;
