import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import TableHeader from "../table/TableHeader";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {franchiseeList} from "./FranchiseeSlice";
import {RootState} from "../../app/store";
import AgGridComponent from "../ag-grid/AgGridComponent";

const FranchiseeTable = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();

    const franchiseeListArrayData = useAppSelector((state: RootState) => state.franchisee.franchiseeListData);

    useEffect(() => {
        dispatch(franchiseeList())
    }, []);

    const columnDefs = [
        {
            headerName: "ID",
            field: "id",
            width: 100,
        },
        {
            headerName: t('Name'),
            field: "name",
        },
        {
            headerName: t('Phone'),
            field: "phone",
        },
        {
            headerName: t('City'),
            field: "city",
        },
    ];

    return (
        <div className="table__container">
            <TableHeader title={t('Franchisee')}/>
            <div className="table__body">
                <div
                    className="table__with_pagination">
                    <AgGridComponent rowData={franchiseeListArrayData} columnDefs={columnDefs}/>
                </div>
            </div>
        </div>
    )
};

export default FranchiseeTable;

