import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";
import {toast} from "react-toastify";
import i18n from '../../app/i18n';

const USERS = gql`
mutation users($input:  UserList!) {
users(input: $input) {
       list {
        id
        name
        phone
        email
        currency
        balance
        created_at
        updated_at
        role
        monitoring
        country_code
        city
        } 
       paginate{
       total
       last_page
       per_page
       current_page
       }
  }
}
`;

const GET_USER = gql`
mutation userInfo($input:  UserInfo!) {
userInfo(input: $input) {
        id
        name
        phone
        email
        currency
        balance
        created_at
        updated_at
        role
        monitoring
        country_code
        city
        contractor_id
  }
}
`;

const UPDATE_USER_COUNTERPARTY = gql`
mutation updateUserContractor($input: UserContractor!) {
updateUserContractor(input: $input) {
        result
  }
}
`;

export const users = createAsyncThunk(
    'users',
    async (form: any) => {
        const response = await client.mutate({mutation: USERS, variables: {input: form}})
        return response.data
    }
)
export const getUser = createAsyncThunk(
    'getUser',
    async (form: any) => {
        const response = await client.mutate({mutation: GET_USER, variables: {input: form}})
        return response.data
    }
)
export const updateUserContractor = createAsyncThunk(
    'updateUserContractor',
    async (form: any) => {
        const response = await client.mutate({mutation: UPDATE_USER_COUNTERPARTY, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    usersData: [],
    paginate: null,
    user: null,
    usersLoader: false,
    resultData: false
}

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setResult: (state: any, action: PayloadAction<boolean>) => {
            state.successUpdatePricePlan = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(users.pending, (state) => {
                state.usersLoader = true
            })
            .addCase(users.fulfilled, (state, action) => {
                state.usersData = action.payload.users.list
                state.paginate = action.payload.users.paginate
                state.usersLoader = false
            })
            .addCase(users.rejected, (state) => {
                state.usersLoader = false
            })
            .addCase(getUser.pending, (state) => {
                state.usersLoader = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.user = action.payload.userInfo
                state.usersLoader = false
            })
            .addCase(getUser.rejected, (state) => {
                state.usersLoader = false
            })
            .addCase(updateUserContractor.pending, (state) => {
                state.usersLoader = true
            })
            .addCase(updateUserContractor.fulfilled, (state, action) => {
                state.usersLoader = false
                state.resultData = action.payload.updateUserContractor.result
                if (!action.payload.updateUserContractor.result) {
                    const translate = i18n.t('Error')
                    toast.error(translate)
                }
            })
            .addCase(updateUserContractor.rejected, (state) => {
                state.usersLoader = false
            })
    }
})
export const {setResult} = usersSlice.actions;

export default usersSlice.reducer;

