import React from 'react';
import {Route, Routes} from "react-router-dom";
import BalanceReplenish from "./BalanceReplenish";
import BalanceReportTable from "./BalanceReportTable";


const BalanceReportList = () => {
    return (
        <Routes>
            <Route path="balance-report" element={<BalanceReportTable/>}/>
            <Route path="balance-replenish" element={<BalanceReplenish/>}/>
        </Routes>
    )
};

export default BalanceReportList;

