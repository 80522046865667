import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "./apolloClient";
import {toast} from "react-toastify";

export interface RegistrationState {
    name: string,
    phone_country: string,
    city: string,
    email: string
    tax_number?: string
    name_company?: string
    address_company?: string
    phone: string
    password: string
    password_confirmation: string
}

export interface AccessTokenState {
    access_token: string
}

export interface SuccessMessageState {
    successAuthMessage: boolean
}

export interface ErrorMessageState {
    errorAuthMessage: boolean
}

export interface LoginState {
    username: string
    password: string
}

export interface RecoverState {
    email: string
}

export interface RestorePasswordState {
    email: string
    token: string
    password: string
    password_confirmation: string
}

export interface RefreshTokenState {
    refresh_token: string
}

export interface verifyEmailState {
    token: string
}

export interface UserState {
    legal_name: string
    email: string
    phone: string
    password: string
    password_confirmation: string
    country_code: string
    city: string
}

const REGISTER = gql`
mutation register($input:  RegisterInput!) {
register(input: $input) {
    tokens {
      access_token
      refresh_token
      expires_in
      token_type
    }
    status
    message
  }
}
`;

const LOGIN = gql`
mutation login($input: LoginInput!) {
login(input: $input) {
      access_token
      refresh_token
      expires_in
      token_type
    status
  }
}
`;

const FORGOT_PASSWORD = gql`
mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
         status
         message
      }
    }
`
;

const UPDATE_FORGOTTEN_PASSWORD = gql`
mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
    updateForgottenPassword(input: $input) {
         status
         message
      }
    }
`
;

const REFRESH_TOKEN = gql`
mutation refreshToken($input:  RefreshTokenInput!) {
refreshToken(input: $input) {
       access_token
      refresh_token
      expires_in
      token_type
  }
}
`
;
const VERIFY_EMAIL = gql`
mutation verifyEmail($input: VerifyEmailInput!) {
verifyEmail(input: $input) {
    access_token 
    refresh_token 
    expires_in 
    token_type 
  }
}
`
;

const LOCATION = gql`
mutation {
    location {
        ip
        status
        location {
           country_code
           country
           city
        }
   }
}
`;

const GET_CITY = gql`
mutation getCity($input: City!) {
getCity(input: $input) {
    cities
  }
}
`
;

const USER = gql`
query {
selfData {
        id
        name
        phone
        email
        photo {big small} 
        legal_name
        balance
        currency
        role
        city
        country_code
        account_details
        contractor_id
        vat_status
  } 
}
`;

const COUNTRY = gql`
query {
country {
   code
   name
   tax_number
   length_tax_number
  }
}
`;

const UPDATE_USER = gql`
mutation updateUser($input: UpdateUser!) {
updateUser(input: $input) {
result {error success}
  }
}
`
;

const VERIFY_TAX_NUMBER = gql`
mutation verifyTaxNumber($input: TaxNumber!) {
verifyTaxNumber(input: $input) {
    name_company
    address_company
    result
  }
}
`
;
const MONITORING_LINK = gql`
mutation {
 monitoring {
  link
 }
}
`
;

export const languages = [
    {value: 'RU', label: 'RU'},
    {value: 'EN', label: 'EN'},
]

export const getCountries = createAsyncThunk(
    'auth/country',
    async () => {
        const response = await client.mutate({mutation: COUNTRY})
        return response.data
    }
)

export const registration = createAsyncThunk(
    'auth/registration',
    async (form: RegistrationState) => {
        const response = await client.mutate({mutation: REGISTER, variables: {input: form}})
        return response.data
    }
)
export const login = createAsyncThunk(
    'auth/login',
    async (form: LoginState) => {
        const response = await client.mutate({mutation: LOGIN, variables: {input: form}})
        return response.data
    }
)

export const forgotPassword = createAsyncThunk(
    'auth/forgotPassword',
    async (form: RecoverState) => {
        const response = await client.mutate({mutation: FORGOT_PASSWORD, variables: {input: form}})
        return response.data
    }
)
export const updateForgottenPassword = createAsyncThunk(
    'auth/updateForgottenPassword',
    async (form: RestorePasswordState) => {
        const response = await client.mutate({mutation: UPDATE_FORGOTTEN_PASSWORD, variables: {input: form}})
        return response.data
    }
)
export const refreshToken = createAsyncThunk(
    'auth/refreshToken',
    async (form: RefreshTokenState) => {
        const response = await client.mutate({mutation: REFRESH_TOKEN, variables: {input: form}})
        return response.data
    }
)
export const verifyEmail = createAsyncThunk(
    'auth/verifyEmail',
    async (form: verifyEmailState) => {
        const response = await client.mutate({mutation: VERIFY_EMAIL, variables: {input: form}})
        return response.data
    }
)
export const location = createAsyncThunk(
    'auth/location',
    async () => {
        const response = await client.mutate({mutation: LOCATION})
        return response.data
    }
)

export const getCity = createAsyncThunk(
    'auth/getCity',
    async (form: any) => {
        const response = await client.mutate({mutation: GET_CITY, variables: {input: form}})
        return response.data
    }
)

export const user = createAsyncThunk(
    'auth/user',
    async () => {
        const response = await client.mutate({mutation: USER})
        return response.data
    }
)

export const updateUser = createAsyncThunk(
    'auth/updateUser',
    async (form: any) => {
        const response = await client.mutate({mutation: UPDATE_USER, variables: {input: form}})
        return response.data
    }
)

export const verifyTaxNumber = createAsyncThunk(
    'auth/verifyTaxNumber',
    async (form: any) => {
        const response = await client.mutate({mutation: VERIFY_TAX_NUMBER, variables: {input: form}})
        return response.data
    }
)

export const monitoringLink = createAsyncThunk(
    'auth/MonitoringLink',
    async () => {
        const response = await client.mutate({mutation: MONITORING_LINK})
        return response.data
    }
)

const initialState = {
    access_token: '',
    expires_in: 0,
    refresh_token: '',
    status: '',
    userData: {
        name: '',
        balance: '',
        currency: '',
        email: '',
        id: '',
        legal_name: '',
        phone: '',
        city: '',
        role: '',
        country_code: '',
        photo: {big: '', small: ''},
        account_details: null,
    },
    authLoading: false,
    locationData: {},
    getCityData: [],
    countries: [],
    successAuthMessage: false,
    errorAuthMessage: false,
    monitoringLink: '',
    authenticationRole: '',
    recoveryData: null,
    updateForgottenPasswordData: null,
    verifyTaxResult: null,
}

const registrationSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAccessToken: (state: AccessTokenState, action: PayloadAction<string>) => {
            state.access_token = action.payload;
        },
        setSuccess: (state: SuccessMessageState, action: PayloadAction<boolean>) => {
            state.successAuthMessage = action.payload;
        },
        setError: (state: ErrorMessageState, action: PayloadAction<boolean>) => {
            state.errorAuthMessage = action.payload;
        },
        setRecoveryData: (state: any, action: PayloadAction<any>) => {
            state.recoveryData = action.payload;
        },
        setRegistrationStatus: (state: any, action: PayloadAction<string>) => {
            state.status = action.payload;
        },
        setAuthLoading: (state: any, action: PayloadAction<boolean>) => {
            state.authLoading = action.payload;
        },
        setUser: (state: any, action: any) => {
            state.userData = action.payload;
        },
        setCities: (state: any, action: any) => {
            state.getCityData = action.payload;
        },
    },

    extraReducers(builder) {
        builder
            .addCase(registration.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(registration.fulfilled, (state: any, action) => {
                    state.status = action.payload.register.status;
                    state.authLoading = false;
                }
            )
            .addCase(registration.rejected, (state: any) => {
                    state.authLoading = false;
                }
            )
            .addCase(verifyEmail.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(verifyEmail.fulfilled, (state: any, action) => {
                    state.authLoading = false;
                    state.access_token = action.payload.verifyEmail.access_token;
                    state.refresh_token = action.payload.verifyEmail.refresh_token;
                    localStorage.setItem('refresh_token', action.payload.verifyEmail.refresh_token);
                }
            )
            .addCase(verifyEmail.rejected, (state: any) => {
                    state.authLoading = false;
                }
            )
            .addCase(login.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(login.fulfilled, (state: any, action) => {
                    state.access_token = action.payload.login.access_token;
                    state.refresh_token = action.payload.login.refresh_token;
                    localStorage.setItem('refresh_token', action.payload.login.refresh_token);
                    state.authLoading = false;
                }
            )
            .addCase(login.rejected, (state: any) => {
                    state.authLoading = false;
                }
            )
            .addCase(forgotPassword.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(forgotPassword.fulfilled, (state: any, action) => {
                    state.recoveryData = action.payload.forgotPassword;
                    state.authLoading = false;
                }
            )
            .addCase(forgotPassword.rejected, (state: any) => {
                    state.authLoading = false;
                }
            )
            .addCase(updateForgottenPassword.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(updateForgottenPassword.fulfilled, (state: any, action) => {
                    state.updateForgottenPasswordData = action.payload.updateForgottenPassword
                    state.authLoading = false;
                }
            )
            .addCase(updateForgottenPassword.rejected, (state: any) => {
                    state.authLoading = false;
                }
            )
            .addCase(refreshToken.pending, (state: any) => {
                    state.authLoading = true;
                }
            )
            .addCase(refreshToken.fulfilled, (state: any, action) => {
                    state.access_token = action.payload.refreshToken.access_token;
                    state.refresh_token = action.payload.refreshToken.refresh_token;
                    localStorage.setItem('refresh_token', action.payload.refreshToken.refresh_token);
                    state.authLoading = false;
                }
            )
            .addCase(refreshToken.rejected, (state: any) => {
                    state.access_token = null;
                    state.refresh_token = null;
                    localStorage.removeItem('refresh_token');
                    state.authLoading = false;
                }
            )
            .addCase(location.fulfilled, (state: any, action: any) => {
                    state.locationData = action.payload.location
                }
            )
            .addCase(getCity.fulfilled, (state: any, action: any) => {
                    state.getCityData = action.payload.getCity.cities.map((city: any) => ({value: city, label: city}))
                }
            )
            .addCase(user.pending, (state: any) => {
                state.authLoading = true;
            })
            .addCase(user.fulfilled, (state: any, action: any) => {
                state.userData = action.payload.selfData
                state.authenticationRole = action.payload.selfData.role
                state.authLoading = false;
            })
            .addCase(user.rejected, (state: any) => {
                state.authLoading = false;
            })
            .addCase(updateUser.fulfilled, (state: any, action: any) => {
                if (action.payload.updateUser.result.error === null) {
                    state.successAuthMessage = true;
                } else {
                    toast.error(action.payload.updateUser.result.error)
                    state.errorAuthMessage = true;
                }
            })
            .addCase(monitoringLink.rejected, (state: any, action: any) => {
                console.log(action)
            })
            .addCase(monitoringLink.fulfilled, (state: any, action: any) => {
                state.monitoringLink = action.payload.monitoring.link
            })
            .addCase(getCountries.fulfilled, (state: any, action: any) => {
                state.countries = action.payload.country
            })
            .addCase(verifyTaxNumber.fulfilled, (state: any, action: any) => {
                state.verifyTaxResult = action.payload.verifyTaxNumber
            })
    }
})

export const {
    setAccessToken,
    setSuccess,
    setError,
    setRecoveryData,
    setRegistrationStatus,
    setAuthLoading,
    setUser,
    setCities
} = registrationSlice.actions;

export default registrationSlice.reducer;


