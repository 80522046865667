import React, {useEffect} from 'react';
import RequisitesIndividual from "./RequisitesIndividual";
import RequisitesLegalEntity from "./RequisitesLegalEntity";
import {toast} from "react-toastify";
import {requisites, setUpdateResult} from "./RequisitesSlice";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {user} from "../../app/authSlice";
import Contractor from "./Contractor";

const Requisites = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const resultData = useAppSelector((state: RootState) => state.requisites.resultData);
    const userData = useAppSelector<any>((state: RootState) => state.auth.userData);

    useEffect(() => {
        dispatch(requisites())
    }, []);

    useEffect(() => {
        if (resultData) {
            toast.success(t('Saved'));
            dispatch(user())
        }
        return () => {
            dispatch(setUpdateResult(false))
        }
    }, [resultData]);

    return (
        <div className="requisites__component w-100">
            {
                (userData && userData.account_details && JSON.parse(userData.account_details).type === 'person')
                && <RequisitesIndividual/>
            }
            {
                (userData && userData.account_details && JSON.parse(userData.account_details).type === 'entity')
                && <RequisitesLegalEntity/>
            }
            <Contractor/>
        </div>
    )
};

export default Requisites;

