import React from 'react';
import arrowDoubleLeft from "../../assets/icon/arrow-double-left.svg";
import arrowLeft from "../../assets/icon/arrow-left.svg";
import arrowDoubleRight from "../../assets/icon/arrow-double-right.svg";
import arrowRight from "../../assets/icon/arrow-right.svg";
import {selectStyles} from "../SelectStyles";
import Select from "react-select";

const Pagination = (params: any) => {

    const startRow = () => {
        return (params.pagination.current_page - 1) * params.pagination.per_page + 1
    }

    const endRow = () => {
        if (params.pagination.current_page * params.pagination.per_page >= params.pagination.total) {
            return params.pagination.total;
        } else {
            return params.pagination.current_page * params.pagination.per_page
        }
    }

    const paginationOptions = [
        {value: 10, label: 10},
        {value: 25, label: 25},
        {value: 100, label: 100},
    ]

    const onBtFirst = () => {
        if (params.pagination.current_page > 1) {
            params.updateDataPagination({
                page: 1,
                per_page: params.pagination.per_page,
                last_page: params.pagination.last_page,
                total: params.pagination.total
            })
        }
    };

    const onBtPrevious = () => {
        if (params.pagination.current_page > 1) {
            params.updateDataPagination({
                page: params.pagination.current_page - 1,
                per_page: params.pagination.per_page,
                last_page: params.pagination.last_page,
                total: params.pagination.total
            })
        }
    };
    const onBtNext = () => {
        if (params.pagination.current_page < params.pagination.last_page) {
            params.updateDataPagination({
                page: params.pagination.current_page + 1,
                per_page: params.pagination.per_page,
                last_page: params.pagination.last_page,
                total: params.pagination.total
            })

        }
    };
    const onBtLast = () => {
        if (params.pagination.current_page < params.pagination.last_page) {
            params.updateDataPagination({
                page: params.pagination.last_page,
                per_page: params.pagination.per_page,
                last_page: params.pagination.last_page,
                total: params.pagination.total
            })
        }
    };


    const changePage = (page: any) => {
        if (page.target.value >= 1 && page.target.value <= params.pagination.last_page) {
            params.updateDataPagination({
                page: Number(page.target.value),
                per_page: params.pagination.per_page,
                last_page: params.pagination.last_page,
                total: params.pagination.total
            })
        }
    };

    const onPageSizeChange = (event: any) => {
        params.updateDataPagination({
            page: 1,
            per_page: event.value,
            last_page: params.pagination.last_page,
            total: params.pagination.total
        })
    };

    return (
        <div className="pagination">
            <div className="pagination__input-container">
                <div className="pagination__arrow-container" onClick={onBtFirst}>
                    <img src={arrowDoubleLeft} alt="arrow"/>
                </div>
                <div className="pagination__arrow-container" onClick={onBtPrevious}>
                    <img src={arrowLeft} alt="arrow"/>
                </div>
                <input
                    value={params.pagination.current_page}
                    className="pagination__input"
                    onChange={changePage}
                    type="number"/> / {params.pagination.last_page}
                <div className="pagination__arrow-container" onClick={onBtNext}>
                    <img src={arrowRight} alt="arrow"/>
                </div>
                <div className="pagination__arrow-container" onClick={onBtLast}>
                    <img src={arrowDoubleRight} alt="arrow"/>
                </div>
            </div>
            <div className="pagination__select-container">
                <div className="pagination__count">
                    {startRow()} – {endRow()} / {params.pagination.total}
                </div>
                <Select
                    isSearchable={false}
                    menuPlacement="top"
                    options={paginationOptions}
                    className="select"
                    classNamePrefix="select"
                    styles={selectStyles}
                    defaultValue={{label: params.pagination.per_page, value: params.pagination.per_page}}
                    onChange={onPageSizeChange}
                />
            </div>

        </div>
    )
};

export default Pagination;







