import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import unitLogo from "../../assets/img/unit-logo.png";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {useForm} from "react-hook-form";
import {getCity, updateUser, user, UserState} from "../../app/authSlice";
import {style} from "../../assets/formErrorStyle";
import FormErrorField from "../form-error-field/FormErrorField";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import classNames from "classnames";

const Profile = () => {
    const {t} = useTranslation();
    const userData = useAppSelector((state: RootState) => state.auth.userData);
    const dispatch = useAppDispatch();
    const successMessageFromServer = useAppSelector((state: RootState) => state.auth.successAuthMessage);
    const [passwordsDoNotMatch, setPasswordsDoNotMatch] = React.useState(false);
    const getCityData = useAppSelector<Array<{ value: string, label: string }>>((state: RootState) => state.auth.getCityData);
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    const [chooseCountry, setChooseCountry] = React.useState(false);
    const [passwordType, setPasswordType] = React.useState(false);
    const [passwordConfirmationType, setPasswordConfirmationType] = React.useState(false);

    useEffect(() => {
        if (successMessageFromServer) {
            setValue('password', '');
            setValue('password_confirmation', '');
            dispatch(user())
        }
    }, [successMessageFromServer]);

    let {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid}
    } = useForm<UserState>({mode: "all"})

    const onSubmit = handleSubmit((data) => {
        if (data.password_confirmation !== data.password) {
            setPasswordsDoNotMatch(true)
        } else {
            setPasswordsDoNotMatch(false)
            dispatch(updateUser({
                legal_name: data.legal_name,
                user_id: Number(userData.id),
                email: data.email,
                phone: data.phone,
                city: country ? data.city : '',
                password: data.password,
                password_confirmation: data.password_confirmation,
            }))
        }
    })
    useEffect(() => {
        setValue('legal_name', userData.legal_name);
        setValue('email', userData.email);
        setValue('phone', userData.phone);
        setValue('city', userData.city);
        setValue('country_code', userData.country_code);
        setCity(userData.city)
        setCountry(userData.country_code)
    }, [userData]);

    const changeCity = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('city', val.value)
            setCity(val.value)
        } else {
            setCity('')
            setValue('city', '')
        }
    }

    const onInputChangeCity = (val: string) => {
        if (val && val.length >= 3 && country) {
            dispatch(getCity({name: val, country_code: country}))
        }
        if (country) {
            setChooseCountry(false)
        } else {
            setChooseCountry(true)
        }
    }

    const passwordTypeClick = (val: string) => {
        if (val === 'password') {
            setPasswordType(!passwordType)
        }
        if (val === 'password_confirmation') {
            setPasswordConfirmationType(!passwordConfirmationType)
        }
    }

    return (
        <div className="profile__container table__container">
            <div className="profile__header">
                <div className="profile__info">
                    <div className="user__img icon_wh-74">
                        <img className="wh-100" src={unitLogo} alt="user"/>
                    </div>
                    <div className="user__description">
                        <div className="user__name">{userData.name}</div>
                        <div className="user__balance profile__balance">{userData.balance} {userData.currency}</div>
                    </div>
                </div>

            </div>
            <div className="profile__body mt-1-5">
                <div className="profile__title mb-1">
                    {t('Account settings')}
                </div>
                <form className="form" onSubmit={onSubmit}>
                    <div className="form__input-container">
                        <div className="form__input-item">
                            <input
                                {...register('legal_name')}
                                className="form__input"
                                placeholder={t('Full name')}
                                type="text"/>
                        </div>
                        <div className="form__input-item">
                            <input
                                {...register('email')}
                                className="form__input"
                                placeholder="E-mail"
                                type="text"/>
                        </div>
                        <div className="form__input-item">
                            <FormErrorField error={errors.phone}/>
                            <input
                                {...register('phone', {
                                    required: t('Required field')
                                })}
                                className="form__input"
                                style={style(errors?.phone)}
                                placeholder={t('phone')}
                                type="text"/>
                        </div>
                        <div className="form__input-item">
                            <FormErrorField
                                errorText={chooseCountry ? t('Country not defined') : null}/>
                            <Select
                                {...register('city')}
                                isSearchable={true}
                                isClearable={true}
                                options={getCityData}
                                placeholder={t('Enter city')}
                                className={classNames('select', chooseCountry ? ' select-invalid' : '')}
                                classNamePrefix="select"
                                value={city ? {label: city, value: city} : null}
                                styles={selectStyles}
                                onChange={changeCity}
                                onInputChange={onInputChangeCity}
                            />
                        </div>
                        <div className="form__input-item">
                            <FormErrorField error={errors.password}/>
                            <FormErrorField
                                errorText={passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                            <div className="input_eye">
                                <input
                                    {...register('password', {
                                        required: t('Required field'),
                                        minLength: {
                                            value: 6,
                                            message: t('Password must be at least 6 characters')
                                        }
                                    })}
                                    className="form__input w-100"
                                    style={style(errors?.password || passwordsDoNotMatch)}
                                    placeholder={t('Enter password')}
                                    autoComplete="off"
                                    type={passwordType ? 'text' : 'password'}/>
                                <div
                                    className={classNames('eye', passwordType ? 'eye_open' : 'eye_close')}
                                    onClick={() => passwordTypeClick('password')}>
                                </div>
                            </div>
                        </div>
                        <div className="form__input-item">
                            <FormErrorField error={errors.password_confirmation}/>
                            <FormErrorField
                                errorText={passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                            <div className="input_eye">
                                <input
                                    {...register('password_confirmation', {
                                        required: t('Required field'),
                                        minLength: {
                                            value: 6,
                                            message: t('Password must be at least 6 characters')
                                        }
                                    })}
                                    className="form__input w-100"
                                    style={style(errors?.password_confirmation || passwordsDoNotMatch)}
                                    placeholder={t('Repeat password')}
                                    autoComplete="off"
                                    type={passwordConfirmationType ? 'text' : 'password'}/>
                                <div
                                    className={classNames('eye', passwordConfirmationType ? 'eye_open' : 'eye_close')}
                                    onClick={() => passwordTypeClick('password_confirmation')}>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button__container mt-2">
                        <button
                            type="submit"
                            className="button"
                            disabled={!isValid}
                        >
                            {t('Save')}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    )
};

export default Profile;

