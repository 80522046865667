import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

const UNIT_INVOICES = gql`
mutation  unitInvoices($input: MonthInvoice!) {
unitInvoices(input: $input) {
        id
        name
        currency
        invoiceData{
            unit_id
            price
            invoice{id total_price status date_paid}
            comment
            created_at
        }
    }
    }
`;

export const unitInvoices = createAsyncThunk(
    'unitInvoices',
    async (form: any) => {
        const response = await client.mutate({mutation: UNIT_INVOICES, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    unitInvoicesData: [],
    invoicesLoader: false
}

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(unitInvoices.pending, (state: any) => {
                state.invoicesLoader = true
            })
            .addCase(unitInvoices.fulfilled, (state: any, action: any) => {
                state.unitInvoicesData = action.payload.unitInvoices
                state.invoicesLoader = false
            })
            .addCase(unitInvoices.rejected, (state: any) => {
                state.invoicesLoader = false
            })
    }
})


export default invoicesSlice.reducer;

