import React from 'react';
import {Route, Routes} from "react-router-dom";
import FranchiseeTable from "./FranchiseeTable";

const FranchiseeList = () => {
    return (
        <Routes>
            <Route path="franchisee" element={<FranchiseeTable/>}/>
        </Routes>
    )
};

export default FranchiseeList;

