import React from 'react';
import "./SecondaryLoader.scss";

const SecondaryLoader = () => {
    return (
        <div className="secondary-preloader">
            <div className="lds-roller">
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
                <div className="lds-roller__item">
                </div>
            </div>
        </div>

    )
};

export default SecondaryLoader;

