import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";


export interface QrCodeState {
    imei: number | null | string
    rate_id: number | null | string
}

const QR_CODE = gql`
mutation qrCode($input: qrCode!) {
qrCode(input: $input) {
  qrCode
  }
}
`;

const GET_HW_TYPES = gql`
mutation hwType($input: Imei!) {
hwType(input: $input) {
    hwType
  }
}
`;
export const qrCode = createAsyncThunk(
    'qrCode',
    async (form: QrCodeState) => {
        const response = await client.mutate({mutation: QR_CODE, variables: {input: form}})
        return response.data
    }
)
export const getHwType = createAsyncThunk(
    'getHwType',
    async (form: any) => {
        const response = await client.mutate({mutation: GET_HW_TYPES, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    qrCodeResult: '',
    qrCodeLoader: false,
    successfullyGenerated: false,
    decodedText: '',
    accessCamera: false,
    hwType: null
}

const qrCodeSlice = createSlice({
    name: 'qrCode',
    initialState,
    reducers: {
        setSuccessfullyGenerated: (state: any, action: PayloadAction<boolean>) => {
            state.successfullyGenerated = action.payload;
        },
        setQrCodeResult: (state: any, action: PayloadAction<string>) => {
            state.qrCodeResult = action.payload;
        },
        setQrLoader: (state: any, action: PayloadAction<boolean>) => {
            state.qrCodeLoader = action.payload;
        },
        setDecodedText: (state: any, action: any) => {
            state.decodedText = action.payload;
        },
        setAccessCamera: (state: any, action: PayloadAction<boolean>) => {
            state.accessCamera = action.payload;
        },
        setHwType: (state: any, action: PayloadAction<any>) => {
            state.hwType = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(qrCode.pending, (state: any) => {
                    state.qrCodeLoader = true
                }
            )
            .addCase(qrCode.fulfilled, (state: any, action) => {
                    state.qrCodeLoader = false
                    state.qrCodeResult = action.payload.qrCode.qrCode
                    state.successfullyGenerated = true;
                }
            )
            .addCase(qrCode.rejected, (state: any, action) => {
                    state.qrCodeLoader = false
                    console.log(action.payload)
                }
            )
            .addCase(getHwType.pending, (state: any) => {
                    state.qrCodeLoader = true
                }
            )
            .addCase(getHwType.fulfilled, (state: any, action) => {
                    state.hwType = action.payload.hwType
                    state.qrCodeLoader = false
                }
            )
            .addCase(getHwType.rejected, (state: any) => {
                    state.qrCodeLoader = false
                }
            )
    }
})
export const {
    setSuccessfullyGenerated,
    setQrCodeResult,
    setQrLoader,
    setDecodedText,
    setAccessCamera,
    setHwType
} = qrCodeSlice.actions;

export default qrCodeSlice.reducer;


