import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import classNames from "classnames";
import {style} from "../../assets/formErrorStyle";
import {
    AcquirerType,
    acquirerType,
    createAcquirer,
    FormAcquirerCreateType,
    setErrorAddedAcquirer,
    setSuccessAddedAcquirer
} from "./AcquirersSlice";
import {RootState} from "../../app/store";
import {toast} from "react-toastify";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import {getCity, setCities, user} from "../../app/authSlice";
import FormErrorField from "../form-error-field/FormErrorField";
import {AbilityContext} from "../../app/can";
import {countryOptions} from "../utils/CountryOptions";
import TableHeader from "../table/TableHeader";

const AcquirersCreate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const ability = useContext(AbilityContext);
    const userData = useAppSelector((state: RootState) => state.auth.userData);
    const acquirerTypes = useAppSelector<any>((state: RootState) => state.acquirer.acquirerTypes);
    const acquirerTypesSelection = useAppSelector((state: RootState) => state.acquirer.acquirerTypesSelection);
    const successResultFromServer = useAppSelector((state: RootState) => state.acquirer.successResultFromServer);
    const errorResultFromServer = useAppSelector((state: RootState) => state.acquirer.errorResultFromServer);
    const getCityData = useAppSelector<Array<{ value: string, label: string }>>((state: RootState) => state.auth.getCityData);
    const [selectedType, setSelectedType] = React.useState(null);
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid},
    } = useForm<FormAcquirerCreateType>(
        {
            mode: "all",
        }
    )

    useEffect(() => {
        if (successResultFromServer) {
            toast.success(t('Saved'));
            navigate('/acquirers-list/acquirers')
        }
        return () => {
            dispatch(setSuccessAddedAcquirer(false))
        }
    }, [successResultFromServer]);

    useEffect(() => {
        if (errorResultFromServer) {
            toast.error(t('Failed to create an acquirer'));
        }
        return () => {
            dispatch(setErrorAddedAcquirer(false))
        }
    }, [errorResultFromServer]);

    useEffect(() => {
        if (userData) {
            setValue('country_code', userData.country_code)
            setCountry(userData.country_code)
            setValue('city', userData.city)
            setCity(userData.city)
        }
    }, [userData]);
    useEffect(() => {
        return () => {
            dispatch(setCities([]))
        }
    }, []);

    const onSubmit = handleSubmit((data: FormAcquirerCreateType) => {
        let result: FormAcquirerCreateType = {
            name: data.name,
            type: data.type,
            login: data.login,
            password: data.password,
        }
        if (ability.can('read', 'acquirers', 'country')) {
            result = {...result, country_code: data.country_code}
        }
        if (ability.can('read', 'acquirers', 'city')) {
            result = {
                ...result,
                city: data.city,
            }
        }
        dispatch(createAcquirer(result))
    })

    useEffect(() => {
        dispatch(acquirerType())
        dispatch(user())
    }, []);

    const onSelectAcquirerType = (val: any) => {
        setValue('type', val.value)
        setSelectedType(val.value)
    }

    const changeCountry = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('country_code', val.value)
            setCountry(val.value)
            setValue('city', '')
            setCity('')
            dispatch(setCities([]))
        }
    }

    const changeCity = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('city', val.value)
            setCity(val.value)
        } else {
            setValue('city', '')
            setCity('')
        }
    }

    const onInputChangeCity = (val: string) => {
        if (val && val.length >= 3 && country) {
            dispatch(getCity({name: val, country_code: country}))
        }
    }
    const closeAcquirer = () => {
        navigate('/acquirers-list/acquirers')
    }
    return (
        <div className="table__container">
            <TableHeader
                title={t('Create an acquirer')}
                close={closeAcquirer}/>
            <div className="table__body">
                <div
                    className={classNames('add__block  add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.name}/>
                                <input
                                    {...register('name', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input"
                                    style={style(errors?.name)}
                                    placeholder={t('Enter a name')}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.type}/>
                                <Select
                                    {...register('type', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={acquirerTypesSelection}
                                    placeholder={t('Choose type')}
                                    className={classNames('select', errors?.type ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={selectedType ? {label: selectedType, value: selectedType} : ''}
                                    onChange={onSelectAcquirerType}
                                />
                            </div>
                            {
                                selectedType &&
                                (
                                    <>
                                        <div className="form__input-item">
                                            <FormErrorField error={errors.login}/>
                                            <input
                                                {...register('login', {
                                                    required: t('Required field'),
                                                })}
                                                className="form__input"
                                                style={style(errors?.login)}
                                                placeholder={
                                                    acquirerTypes.find((el: AcquirerType) => selectedType === el.type)
                                                        ? (acquirerTypes.find((el: AcquirerType) => selectedType === el.type).label.login)
                                                        : t('Enter login')
                                                }
                                                type="text"/>
                                        </div>
                                        <div className="form__input-item">
                                            <FormErrorField error={errors.password}/>
                                            <input
                                                {...register('password', {
                                                    required: t('Required field'),
                                                })}
                                                className="form__input"
                                                style={style(errors?.password)}
                                                placeholder={
                                                    acquirerTypes.find((el: AcquirerType) => selectedType === el.type)
                                                        ? (acquirerTypes.find((el: AcquirerType) => selectedType === el.type).label.password)
                                                        : t('Enter password')
                                                }
                                                type="text"/>
                                        </div>
                                    </>
                                )
                            }
                            {
                                (ability.can('read', 'acquirers', 'country'))
                                && (
                                    <>
                                        <div className="form__input-item">
                                            <FormErrorField error={errors.country_code}/>
                                            <Select
                                                {...register('country_code', {
                                                    required: t('Required field'),
                                                })}
                                                isSearchable={false}
                                                options={countryOptions()}
                                                placeholder={t('Choose a country')}
                                                className={classNames('select', errors?.country_code ? ' select-invalid' : '')}
                                                classNamePrefix="select"
                                                styles={selectStyles}
                                                value={countryOptions().find((e: { value: string, label: string }) => country === e.value)}
                                                onChange={changeCountry}
                                            />
                                        </div>
                                    </>
                                )
                            }
                            {
                                (ability.can('read', 'acquirers', 'city'))
                                &&
                                (
                                    <>
                                        <div className="form__input-item">
                                            <FormErrorField error={errors.city}/>
                                            <Select
                                                {...register('city', {
                                                    required: t('Required field'),
                                                })}
                                                isSearchable={true}
                                                isClearable={true}
                                                options={getCityData}
                                                placeholder={t('Enter city')}
                                                className={classNames('select', errors?.city ? ' select-invalid' : '')}
                                                classNamePrefix="select"
                                                value={city ? {label: city, value: city} : null}
                                                styles={selectStyles}
                                                onChange={changeCity}
                                                onInputChange={onInputChangeCity}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default AcquirersCreate;

