import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {contractor} from "./RequisitesSlice";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import TableHeader from "../table/TableHeader";

const Contractor = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const contractorData = useAppSelector<any>((state: RootState) => state.requisites.contractorData);

    useEffect(() => {
        dispatch(contractor())
    }, []);

    return (
        <div className="table__container requisites__table">
            <TableHeader
                title={t('The contract is concluded with:')}/>
            <div className="table__body">
                <div className={classNames('add__block  add__block_visible')}>
                    <div className="requisites__items-container">
                        {contractorData && contractorData.length > 0 ? contractorData.map((item: any) => (
                                <div key={item} className="requisites__item">
                                    {item}
                                </div>
                            )
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Contractor;

