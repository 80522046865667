import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {createAccountDetails} from "./RequisitesSlice";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {style} from "../../assets/formErrorStyle";
import TableHeader from "../table/TableHeader";

const RequisitesIndividual = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const userData = useAppSelector<any>((state: RootState) => state.auth.userData);
    const requisitesData = useAppSelector<any>((state: RootState) => state.requisites.requisitesData);
    const [person, setPerson] = React.useState<any>(null);
    const [personValue, setPersonValue] = React.useState<any>(null);

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<any>({mode: "all"})

    useEffect(() => {
        if (requisitesData) {
            requisitesData.forEach((item: any) => {
                if (item.type === 'person') {
                    const arr: any = []
                    const requisitesFields = JSON.parse(item.fields);
                    Object.keys(requisitesFields).forEach(
                        result =>
                            arr.push(
                                {
                                    name: result,
                                    value: '',
                                    placeholder: requisitesFields[result],
                                }
                            )
                    );
                    setPerson(arr)
                    setPersonValue(arr)
                }
            })
        }
    }, [requisitesData]);

    useEffect(() => {
        if (person && person.length > 0) {
            if (userData && userData.account_details) {
                const accountDetails = JSON.parse(userData.account_details);
                if (accountDetails.type === 'person') {
                    const arr: any = []
                    Object.keys(accountDetails).forEach(
                        result => {
                            if (result !== 'type') {
                                person.forEach((item: any) => {
                                    if (item.name === result) {
                                        arr.push({...item, value: accountDetails[result]})
                                    }
                                })
                            }
                        }
                    );
                    setPersonValue(arr)
                }
            }
        }
    }, [person]);

    const onSubmit = handleSubmit((data: any) => {
        dispatch(createAccountDetails(Object.assign({}, ...personValue.map((n: any) => ({[n.name]: n.value})), {type: 'person'})))
    })

    const onChange = (val: any, name: any) => {
        const changedValue = personValue.map((item: any) => (
            item.name === name
                ? {...item, value: val.target.value}
                : item
        ));
        setPersonValue(changedValue)
    }

    return (
        <div className="table__container requisites__table">
            <TableHeader
                title={t('Individual')}/>
            <div className="table__body">
                <div className={classNames('add__block  add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            {person ? person.map((item: any) => (
                                    <div key={item.name} className={"form__input-item form__input-item-with-label"}>
                                        <div className="form__input-label">{item.placeholder}</div>
                                        <input
                                            {...register(`${item.name}`)}
                                            className="form__input"
                                            value={personValue && personValue.length > 0 ? personValue.find((el: any) => el.name === item.name).value : ''}
                                            onChange={(val: any) => onChange(val, item.name)}
                                            placeholder={item.placeholder}
                                            style={style(errors?.hasOwnProperty(item.name))}
                                            type="text"/>
                                    </div>
                                )
                            ) : ''}
                        </div>

                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default RequisitesIndividual;

