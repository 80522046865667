import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {verifyEmail} from "../../app/authSlice";

function EmailVerify() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(verifyEmail({token: searchParams.get("token")!}))
        navigate('login/sing-in');
    }, [searchParams]);
    return (<></>)
}

export default EmailVerify;

