import React, {useContext, useEffect} from 'react';
import "../../css/main.scss";
import person from "../../assets/icon/person.svg";
import contract from "../../assets/icon/contract.svg";
import deeds from "../../assets/icon/deeds.svg";
import clients from "../../assets/icon/clients.svg";
import requisites from "../../assets/icon/requisites.svg";
import support from "../../assets/icon/support.svg";
import off from "../../assets/icon/off.svg";
import computer from "../../assets/icon/computer.svg";
import payments from "../../assets/icon/payment.svg";
import monitoring from "../../assets/icon/monitoring.svg";
import balance from "../../assets/icon/balance.svg";
import unitLogo from "../../assets/img/unit-logo.png";
import close from "../../assets/icon/close.svg";
import car from "../../assets/icon/car.svg";
import franchisee from "../../assets/icon/franchisee.svg";
import tariff from "../../assets/icon/tariff.svg";
import acquirers from "../../assets/icon/ic_acquirers.svg";
import counterparties from "../../assets/icon/ic_counterparties.svg";
import {Navigate, NavLink, Route, Routes, useNavigate} from 'react-router-dom';
import classNames from "classnames";
import DashboardComponent from "../dashboard/DashboardComponent";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import store, {RootState} from "../../app/store";
import {setLanguage, setMenu} from "../../app/appSlice";
import {useTranslation} from "react-i18next";
import {languages, monitoringLink, setAccessToken, setUser} from "../../app/authSlice";
import Profile from "../profile/Profile";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import PricePlan from "../price-plan/PricePlan";
import PricePlanTable from "../price-plan/PricePlanTable";
import QrCode from "../generate-qr-code/QrCode";
import UnitsList from "../units/UnitsList";
import UnitCreate from "../units/UnitCreate";
import UnitScanQrCode from "../units/UnitScanQrCode";
import UnitsTable from "../units/UnitsTable";
import BalanceReplenish from "../balance-list/BalanceReplenish";
import BalanceReportTable from "../balance-list/BalanceReportTable";
import BalanceReportList from "../balance-list/BalanceReportList";
import Payments from "../invoices/Payments";
import Search from "../search/Search";
import AcquirersCreate from "../acquirers/AcquirersCreate";
import AcquirersTable from "../acquirers/AcquirersTable";
import AcquirersList from "../acquirers/AcquirersList";
import UsersList from "../users/UsersList";
import UsersTable from "../users/UsersTable";
import UsersAddBalance from "../users/UsersAddBalance";
import UsersEditRole from "../users/UsersEditRole";
import AcquirersEdit from "../acquirers/AcquirersEdit";
import PricePlanCreate from "../price-plan/PricePlanCreate";
import Deeds from "../documents/Deeds";
import Contract from "../documents/Contract";
import {AbilityContext} from '../../app/can';
import Requisites from "../requisites/Requisites";
import CounterpartiesList from "../counterparties/CounterpartiesList";
import CounterpartiesTable from "../counterparties/CounterpartiesTable";
import CounterpartiesCreate from "../counterparties/CounterpartiesCreate";
import CounterpartiesEdit from "../counterparties/CounterpartiesEdit";
import UsersAssignCounterparty from "../users/UsersAssignCounterparty";
import i18n from "i18next";
import {Can} from "@casl/react";
import FranchiseeList from "../franchisee/FranchiseeList";
import FranchiseeTable from "../franchisee/FranchiseeTable";

export const logOut = () => {
    localStorage.removeItem('refresh_token');
    store.dispatch(setAccessToken(''))
    store.dispatch(setUser(null))
}

function Layout() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const ability = useContext(AbilityContext);
    const userData = useAppSelector((state: RootState) => state.auth.userData);
    const menu = useAppSelector((state: RootState) => state.app.menuOpen);
    const searchVisible = useAppSelector((state: RootState) => state.search.searchVisible);
    const defaultLanguage = useAppSelector((state: RootState) => state.app.language);
    const unitsLoader = useAppSelector((state: RootState) => state.units.unitsLoader);
    const franchiseeLoader = useAppSelector((state: RootState) => state.franchisee.franchiseeLoader);
    const monitoringLinkValue = useAppSelector((state: RootState) => state.auth.monitoringLink);
    const usersLoader = useAppSelector((state: RootState) => state.users.usersLoader);
    const balanceReportLoader = useAppSelector((state: RootState) => state.balanceReport.balanceReportLoader);
    const invoicesLoader = useAppSelector((state: RootState) => state.invoices.invoicesLoader);
    const documentsLoader = useAppSelector((state: RootState) => state.documents.documentsLoader);
    const requisitesLoader = useAppSelector((state: RootState) => state.requisites.requisitesLoader);
    const counterpartiesLoader = useAppSelector((state: RootState) => state.counterparties.counterpartiesLoader);
    const unitImeiData = useAppSelector((state: RootState) => state.unitImei.unitImeiData);

    useEffect(() => {
        if (unitImeiData) {
            navigate('units-list/unit-create');
        }
    }, [unitImeiData]);

    const handleClick = () => {
        dispatch(setMenu(!menu));
    };

    useEffect(() => {
        if (ability.can('read', 'monitoring')) {
            dispatch(monitoringLink())
        }
    }, [ability]);


    return (
        <>
            {/*NAVIGATION  DESKTOP*/}
            <aside className={classNames('navigation navigation_desktop', menu ? ' navigation__hidden' : '')}>
                <div className="logo__container">
                    <div className="logo">
                    </div>
                </div>
                <ul className="navigation__menu">
                    <Can I='read' a='dashboard' ability={ability}>
                        <NavLink to="/dashboard" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={computer} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Dashboard')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='units' ability={ability}>
                        <NavLink to="/units-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={car} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Units')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='franchisee' ability={ability}>
                        <NavLink to="/franchisee-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={franchisee} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Franchisee')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='users' ability={ability}>
                        <NavLink to="/users-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={clients} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Clients')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='price-plan' ability={ability}>
                        <NavLink to="/price-plan" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={tariff} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Price plans')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='acquirers' ability={ability}>
                        <NavLink to="/acquirers-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={acquirers} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Acquirers')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='counterparties' ability={ability}>
                        <NavLink to="/counterparties-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={counterparties} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Counterparties')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='monitoring' ability={ability}>
                        <a href={monitoringLinkValue} target="_blank" rel="noreferrer"
                           className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={monitoring} alt="icon"/>
                            </div>
                            <p className="navigation__item-text">  {t('Monitoring')} </p>
                        </a>
                    </Can>
                    <Can I='read' a={'balance' || 'payments'} ability={ability}>
                        <div className="navigation__title">{t("Billing")}</div>
                    </Can>
                    <Can I='read' a='payments' ability={ability}>
                        <NavLink to="/payments" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={payments} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Payments')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a='balance' ability={ability}>
                        <NavLink to="/balance-list" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={balance} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('History of balance')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'contract' || 'deeds'} ability={ability}>
                        <div className="navigation__title">{t("Documents")}</div>
                    </Can>
                    <Can I='read' a={'contract'} ability={ability}>
                        <NavLink to="/contract" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={contract} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Contract')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'deeds'} ability={ability}>
                        <NavLink to="/deeds" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={deeds} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Deeds')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'profile'} ability={ability}>
                        <div className="navigation__title">{t('Account')}</div>
                        <NavLink to="/profile" className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={person} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Profile')} </p>
                        </NavLink>
                    </Can>
                </ul>
            </aside>

            {/*NAVIGATION  MOBILE*/}
            <aside className={classNames('navigation navigation_mobile', menu ? ' navigation__hidden' : '')}>
                <div className="close-menu" onClick={handleClick}>
                    <img className="icon_wh-16" src={close} alt="close"/>
                </div>
                <div className="logo__container">
                    <div className="logo">
                    </div>
                </div>
                <ul className="navigation__menu">
                    <Can I='read' a={'dashboard'} ability={ability}>
                        <NavLink to="/dashboard" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={computer} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Dashboard')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'units'} ability={ability}>
                        <NavLink to="/units-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={car} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Units')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'franchisee'} ability={ability}>
                        <NavLink to="/franchisee-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={franchisee} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Franchisee')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'users'} ability={ability}>
                        <NavLink to="/users-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={clients} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Clients')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'price-plan'} ability={ability}>
                        <NavLink to="/price-plan" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={tariff} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Price plans')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'acquirers'} ability={ability}>
                        <NavLink to="/acquirers-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={acquirers} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Acquirers')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'counterparties'} ability={ability}>
                        <NavLink to="/counterparties-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={counterparties} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Counterparties')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'monitoring'} ability={ability}>
                        <a href={monitoringLinkValue} target="_blank" rel="noreferrer"
                           className="navigation__item">
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={monitoring} alt="icon"/>
                            </div>
                            <p className="navigation__item-text">  {t('Monitoring')} </p>
                        </a>
                    </Can>
                    <Can I='read' a={'balance' || 'payments'} ability={ability}>
                        <div className="navigation__title">{t("Billing")}</div>
                    </Can>
                    <Can I='read' a={'payments'} ability={ability}>
                        <NavLink to="/payments" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={payments} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Payments')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'balance'} ability={ability}>
                        <NavLink to="/balance-list" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={balance} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('History of balance')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'contract' || 'deeds'} ability={ability}>
                        <div className="navigation__title">{t("Documents")}</div>
                    </Can>
                    <Can I='read' a={'contract'} ability={ability}>
                        <NavLink to="/contract" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={contract} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Contract')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'deeds'} ability={ability}>
                        <NavLink to="/deeds" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={deeds} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Deeds')} </p>
                        </NavLink>
                    </Can>
                    <Can I='read' a={'profile'} ability={ability}>
                        <div className="navigation__title">{t('Account')}</div>
                        <NavLink to="/profile" className="navigation__item" onClick={handleClick}>
                            <div className="icon icon_wh-32">
                                <img className="navigation__item-img icon_wh-14" src={person} alt="icon"/>
                            </div>
                            <p className="navigation__item-text"> {t('Profile')} </p>
                        </NavLink>
                    </Can>
                </ul>
            </aside>
            <div className={classNames('container', menu ? ' container__full' : '')}>
                <header className="header">
                    <div className="icon-menu icon-menu-desktop" onClick={handleClick}>
                        <div
                            className={classNames('menu wh-100', menu ? 'menu-hidden' : 'menu-open')}>
                        </div>
                    </div>
                    <div className="header__container">
                        {
                            searchVisible && <Search/>
                        }
                        <div className="language__container">
                            <div className={classNames('flag icon_wh-30', defaultLanguage)}>
                            </div>
                            <div className="dropdown__body">
                                {
                                    languages.map((language: any) => (
                                        <div key={language.value}
                                             className={classNames('flag icon_wh-30', language.value)}
                                             onClick={() => {
                                                 dispatch(setLanguage(language.value));
                                                 i18n.changeLanguage(language.value);
                                             }}
                                        >
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <div className="icon-menu icon-menu-mobile" onClick={handleClick}>
                            <div
                                className={classNames('menu wh-100', menu ? 'menu-hidden' : 'menu-open')}>
                            </div>
                        </div>
                        <div className="user__info">
                            <div className="user__img icon_wh-60">
                                <img className="wh-100" src={unitLogo} alt="user"/>
                            </div>
                            <div className="user__description">
                                <div className="user__name">{userData.name}</div>
                                <div className="user__balance">{userData.balance} {userData.currency}</div>
                            </div>
                            <div className="dropdown__body user__dropdown">
                                <Can I='read' a={'requisites'} ability={ability}>
                                    <NavLink className="user__dropdown-item" to="/requisites">
                                        <div className="icon icon_wh-20">
                                            <img className="user__dropdown-img" src={requisites} alt="icon"/>
                                        </div>
                                        <p className="navigation__item-text"> {t('Requisites')}</p>
                                    </NavLink>
                                </Can>
                                <a className="user__dropdown-item" href="https://t.me/GeliosSoft_bot">
                                    <div className="icon icon_wh-20">
                                        <img className="user__dropdown-img" src={support} alt="icon"/>
                                    </div>
                                    <p className="navigation__item-text">{t('Support')}</p>
                                </a>
                                <div className="user__dropdown-item log-out" onClick={logOut}>
                                    <div className="icon icon_wh-20">
                                        <img className="user__dropdown-img" src={off} alt="icon"/>
                                    </div>
                                    <p className="navigation__item-text alarm-text">{t('Log out')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <main className="main__container mt-1-5">
                    {(unitsLoader || usersLoader || balanceReportLoader || invoicesLoader || documentsLoader || requisitesLoader || counterpartiesLoader || franchiseeLoader) &&
                    <SecondaryLoader/>}
                    <Routes>
                        <Route path="dashboard" element={<DashboardComponent/>}/>
                        <Route path="units-list/*" element={<UnitsList/>}>
                            <Route path="units" element={<UnitsTable/>}/>
                            <Route path="unit-create" element={<UnitCreate/>}/>
                            <Route path="unit-scan-qr-code" element={<UnitScanQrCode/>}/>
                        </Route>
                        <Route path="units-list" element={<Navigate replace to="/units-list/units"/>}/>
                        <Route path="franchisee-list/*" element={<FranchiseeList/>}>
                            <Route path="franchisee" element={<FranchiseeTable/>}/>
                        </Route>
                        <Route path="franchisee-list" element={<Navigate replace to="/franchisee-list/franchisee"/>}/>
                        <Route path="users-list/*" element={<UsersList/>}>
                            <Route path="users" element={<UsersTable/>}/>
                            <Route path=":id/add-balance" element={<UsersAddBalance/>}/>
                            <Route path=":id/edit-role" element={<UsersEditRole/>}/>
                            <Route path=":id/assign-counterparty" element={<UsersAssignCounterparty/>}/>
                        </Route>
                        <Route path="users-list" element={<Navigate replace to="/users-list/users"/>}/>
                        <Route path="price-plan/*" element={<PricePlan/>}>
                            <Route path="list" element={<PricePlanTable/>}/>
                            <Route path="list/price-plan-create" element={<PricePlanCreate/>}/>
                            <Route path="qr-code" element={<QrCode/>}/>
                        </Route>
                        <Route path="price-plan" element={<Navigate replace to="/price-plan/list"/>}/>
                        <Route path="acquirers-list/*" element={<AcquirersList/>}>
                            <Route path="acquirers" element={<AcquirersTable/>}/>
                            <Route path="create" element={<AcquirersCreate/>}/>
                            <Route path=":id/edit" element={<AcquirersEdit/>}/>
                        </Route>
                        <Route path="acquirers-list" element={<Navigate replace to="/acquirers-list/acquirers"/>}/>
                        <Route path="counterparties-list/*" element={<CounterpartiesList/>}>
                            <Route path="counterparties" element={<CounterpartiesTable/>}/>
                            <Route path="create" element={<CounterpartiesCreate/>}/>
                            <Route path=":id/edit" element={<CounterpartiesEdit/>}/>
                        </Route>
                        <Route path="counterparties-list"
                               element={<Navigate replace to="/counterparties-list/counterparties"/>}/>
                        <Route path="payments" element={<Payments/>}/>
                        <Route path="balance-list/*" element={<BalanceReportList/>}>
                            <Route path="balance-report" element={<BalanceReportTable/>}/>
                            <Route path="balance-replenish" element={<BalanceReplenish/>}/>
                        </Route>
                        <Route path="balance-list"
                               element={<Navigate replace to="/balance-list/balance-report"/>}/>
                        <Route path="profile" element={<Profile/>}/>
                        <Route path="contract" element={<Contract/>}/>
                        <Route path="deeds" element={<Deeds/>}/>
                        <Route path="requisites" element={<Requisites/>}/>
                    </Routes>
                </main>
            </div>
        </>
    )
}

export default Layout;

