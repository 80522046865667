import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AppState {
    menuOpen: boolean;
    language: string | null;
    isLoader: boolean;
}

export interface TabsState {
    tabs: Array<{ tabName: string, route: string }>
}

const initialState: AppState = {
    menuOpen: false,
    language: null,
    isLoader: false,
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setMenu: (state: AppState, action: PayloadAction<boolean>) => {
            state.menuOpen = action.payload;
        },
        setLanguage: (state: AppState, action: PayloadAction<any>) => {
            state.language = action.payload;
            localStorage.setItem('language', action.payload);
        },
    },
});

export const {setMenu, setLanguage} = appSlice.actions;

export default appSlice.reducer;
