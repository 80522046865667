import React from 'react';
import Tabs from "../tabs/Tabs";
import {Route, Routes} from 'react-router-dom';
import QrCode from "../generate-qr-code/QrCode";
import PricePlanTable from "./PricePlanTable";
import PricePlanCreate from "./PricePlanCreate";

const PricePlan = () => {
    const tabs = [
        {
            tabName: 'Price plans',
            route: 'list'
        },
        {
            tabName: 'Generate QR CODE',
            route: 'qr-code'
        }];
    return (
        <div className="price-plan__component">
            <Tabs tabs={tabs}/>
            <Routes>
                <Route path="list" element={<PricePlanTable/>}/>
                <Route path="list/price-plan-create" element={<PricePlanCreate/>}/>
                <Route path="qr-code" element={<QrCode/>}/>
            </Routes>
        </div>
    )
};

export default PricePlan;

