import React from 'react';
import enter from "../../assets/icon/ic_enter.svg";

const EnterRenderComponent = (params: any) => {
    return (
        <a className="enter__container pointer" href={params.data.monitoring} target="_blank" rel="noreferrer">
            <img className="icon_wh-30" src={enter} alt="enter"/>
        </a>
    )
};

export default EnterRenderComponent;







