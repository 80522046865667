import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import SecondaryLoader from "../secondaryLoader/SecondaryLoader";
import DonutChart from "react-donut-chart";
import {dashboardColors} from "./DashboardComponent";
import {dashboardCustomers} from "./DashboardSlice";
import {NavLink} from "react-router-dom";

const DashboardCounts = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const dashboardData = useAppSelector<any>((state: RootState) => state.dashboard.dashboardData);
    const dashboardPreloader = useAppSelector((state: RootState) => state.dashboard.dashboardPreloader);

    useEffect(() => {
        dispatch(dashboardCustomers())
    }, []);

    return (
        <div className="dashboard__container w-100">
            <div className="dashboard__row">
                {dashboardPreloader &&
                <SecondaryLoader/>}
                <div className="dashboard__small-box_container">
                    <div className="dashboard__small-box_item">
                        <div className="dashboard__small-box_body">
                            <div className="dashboard__small-box-title">{t('Balance')}</div>
                            <div
                                className="dashboard__small-box-description">{dashboardData.user_balance} {dashboardData.currency_code}</div>
                        </div>
                        <NavLink
                            to="/balance-list/balance-replenish"
                            className="dashboard__small-box-icon dashboard__small-box-icon_add">
                        </NavLink>
                    </div>
                    <div className="dashboard__small-box_item">
                        <div className="dashboard__small-box_body">
                            <div className="dashboard__small-box-title">{t('Balance is calculated for')}</div>
                            <div className="dashboard__small-box-description">
                                {dashboardData.count_days === null ? '∞' : dashboardData.count_days} {t('d.')}
                            </div>
                        </div>
                        <div className="dashboard__small-box-icon">
                        </div>
                    </div>
                </div>
            </div>
            <div className="dashboard__row">
                {dashboardPreloader &&
                <SecondaryLoader/>}
                <div className="dashboard__item_donut-container">
                    <h2 className="title__secondary">{t('Units')}</h2>
                    <div className="dashboard__item_donut-body">
                        <div className="donut__item">
                            <DonutChart
                                legend={false}
                                height={200}
                                width={200}
                                innerRadius={0}
                                interactive={false}
                                strokeColor='transparent'
                                colors={dashboardColors}
                                data={[
                                    {
                                        label: '',
                                        value: dashboardData.count_all_units ? dashboardData.count_all_units : 0
                                    },
                                    {
                                        label: '',
                                        value: dashboardData.count_active_units ? dashboardData.count_active_units : 0
                                    },
                                ]}
                            />
                        </div>
                        <div className="dashboard__item_donut-legends dashboard__item_donut-legends_secondary">
                            <div className="legends__item legends__item_secondary">
                                <div className="legends__item-body">
                                    <div className="legends__square" style={{background: dashboardColors[0]}}>
                                    </div>
                                    <div className="legends__title">
                                        {t('Number of units')}
                                    </div>
                                </div>
                                <p className="legends__item-count">{dashboardData.count_all_units}</p>
                            </div>
                            <div className="legends__item legends__item_secondary">
                                <div className="legends__item-body">
                                    <div className="legends__square" style={{background: dashboardColors[1]}}>
                                    </div>
                                    <div className="legends__title">
                                        {t('Active units')}
                                    </div>
                                </div>
                                <p className="legends__item-count">{dashboardData.count_active_units}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default DashboardCounts;

