import React from 'react';
import {useTranslation} from "react-i18next";
import logo from "../../assets/icon/logo.svg";
import {useForm} from 'react-hook-form'
import {login, LoginState} from "../../app/authSlice";
import {useAppDispatch} from "../../app/hooks";
import {NavLink} from "react-router-dom";
import {style} from "../../assets/formErrorStyle";
import FormErrorField from "../form-error-field/FormErrorField";
import classNames from "classnames";

function SingIn() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [passwordType, setPasswordType] = React.useState(false);

    const passwordTypeClick = () => {
        setPasswordType(!passwordType)
    }
    const {
        register,
        handleSubmit,
        formState: {errors, isValid}
    } = useForm<LoginState>({mode: "all"})

    const onSubmit = handleSubmit((data) => {
        dispatch(login(data))
    })

    return (
        <div className="login__container">
            <div className="login__header">
                <img className="login__logo" src={logo} alt="solomon"/>
            </div>
            <div className="login__body">
                <form className="form" onSubmit={onSubmit}>
                    <div className="form__input-container">
                        <div className="form__input-item">
                            <FormErrorField error={errors.username}/>
                            <input
                                {...register('username', {
                                    required: t('Required field'),
                                })}
                                className="form__input"
                                style={style(errors?.username)}
                                placeholder={t('User')}
                                type="text"/>
                        </div>
                        <div className="form__input-item">
                            <FormErrorField error={errors.password}/>
                            <div className="input_eye">
                                <input
                                    {...register('password', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input w-100"
                                    autoComplete="on"
                                    style={style(errors?.password)}
                                    placeholder={t('Enter password')}
                                    type={passwordType ? 'text' : 'password'}/>
                                <div
                                    className={classNames('eye', passwordType ? 'eye_open' : 'eye_close')}
                                    onClick={passwordTypeClick}>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="switch__container">*/}
                    {/*    <label className="switch">*/}
                    {/*        <input*/}
                    {/*            type="checkbox"*/}
                    {/*            {...register('remember')}*/}
                    {/*        />*/}
                    {/*        <span className="slider"> </span>*/}
                    {/*    </label>*/}
                    {/*    <p className="switch__label">{t("Remember me")}</p>*/}
                    {/*</div>*/}
                    <div className="login__btn-container mt-1">
                        <NavLink className="small-text-right" to="/login/recovery">{t("Forgot password?")}</NavLink>
                        <button
                            type="submit"
                            className="button w-100"
                            disabled={!isValid}
                        >
                            {t('Sing in')}
                        </button>
                    </div>
                </form>

            </div>
            <div className="login__footer">
                <p className="login__footer-text"> {t("Don't have an account")}?</p>
                <NavLink className="login__footer-link pointer" to="/login/sing-up">{t("Sing up")}</NavLink>
            </div>
        </div>
    )
}

export default SingIn;

