import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {setSuccess, updateUser} from "../../app/authSlice";
import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {getUser} from "./usersSlice";
import classNames from "classnames";
import Select from "react-select";
import {selectStyles} from "../SelectStyles";
import FormErrorField from "../form-error-field/FormErrorField";
import {AbilityContext} from "../../app/can";
import {countryOptions} from "../utils/CountryOptions";
import {userRoleOptions} from "../utils/UserRoleOptions";
import TableHeader from "../table/TableHeader";

const UsersEditRole = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    let {id} = useParams();
    const navigate = useNavigate();
    const getUserData = useAppSelector<any>((state: RootState) => state.users.user);
    const [roleOptions, setRoleOptions] = React.useState<any>([]);
    const [role, setRole] = React.useState('');
    const [country, setCountry] = React.useState('');
    const successMessageFromServer = useAppSelector((state: RootState) => state.auth.successAuthMessage);
    const ability = useContext(AbilityContext);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors}
    } = useForm<{
        role: string,
        country_code?: string,
    }>({mode: 'all'})

    useEffect(() => {
        dispatch(getUser({id: Number(id)}))
    }, [])

    useEffect(() => {
        if (ability.can('read', 'users', 'distributor')) {
            setRoleOptions(userRoleOptions())
        } else {
            setRoleOptions(userRoleOptions().filter((e: any) => e.value !== 'distributor'))
        }
    }, [ability])

    useEffect(() => {
        if (getUserData) {
            setRole(getUserData.role)
            setValue('role', getUserData.role)
            setCountry(getUserData.country_code)
            setValue('country_code', getUserData.country_code)
        }
    }, [getUserData])

    useEffect(() => {
        if (successMessageFromServer) {
            navigate('/users-list/users')
            dispatch(setSuccess(false))
        }
    }, [successMessageFromServer])

    const onSubmit = handleSubmit((data: any) => {
        const result = {
            role: data.role,
            user_id: Number(id)
        }
        if (role === 'distributor') {
            dispatch(updateUser({...result, country_code: data.country_code}))
        } else {
            dispatch(updateUser(result))
        }
    })

    const changeRole = (val: any) => {
        setRole(val.value)
        setValue('role', val.value)
    }

    const changeCountry = (val: any) => {
        setValue('country_code', val.value)
        setCountry(val.value)
    }
    const closeUserRole = () => {
        navigate('/users-list/users')
    }

    return (
        <div className="table__container">
            <TableHeader
                id={id}
                title={t('Edit role')}
                close={closeUserRole}/>
            <div className="table__body">
                <div className={classNames('add__block add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.role}/>
                                <Select
                                    {...register('role', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={roleOptions}
                                    placeholder={t('Choose a role')}
                                    className={classNames('select', errors?.role ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={roleOptions.find((e: any) => role === e.value)}
                                    onChange={changeRole}
                                />
                            </div>
                            {
                                role === 'distributor'
                                &&
                                (
                                    <>
                                        <div className="form__input-item">
                                            <FormErrorField error={errors.country_code}/>
                                            <Select
                                                {...register('country_code', {
                                                    required: t('Required field'),
                                                })}
                                                isSearchable={false}
                                                options={countryOptions()}
                                                placeholder={t('Choose a country')}
                                                className={classNames('select', errors?.country_code ? ' select-invalid' : '')}
                                                classNamePrefix="select"
                                                styles={selectStyles}
                                                value={countryOptions().find((e: any) => country === e.value)}
                                                onChange={changeCountry}
                                            />
                                        </div>
                                    </>
                                )
                            }
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UsersEditRole;

