import React, {useEffect} from 'react';
import logo from "../../assets/icon/logo.svg";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    getCity,
    getCountries,
    location,
    registration,
    RegistrationState,
    setCities,
    setRegistrationStatus,
    verifyTaxNumber
} from "../../app/authSlice";
import {NavLink} from "react-router-dom";
import {RootState} from "../../app/store";
import {toast} from "react-toastify";
import mail from "../../assets/icon/mail.png";
import {style} from "../../assets/formErrorStyle";
import {selectStyles} from "../SelectStyles";
import Select from "react-select";
import classNames from "classnames";
import FormErrorField from "../form-error-field/FormErrorField";
import {countryOptions} from "../utils/CountryOptions";
import {entityOrIndividualOptions} from "../utils/EntityOrIndividualOptions";

function SingUp() {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const registrationStatus = useAppSelector((state: RootState) => state.auth.status);
    const countries = useAppSelector<Array<any>>((state: RootState) => state.auth.countries);
    const [state, setState] = React.useState({passwordsDoNotMatch: false, email: ''});
    const locationData = useAppSelector<any>((state: RootState) => state.auth.locationData);
    const getCityData = useAppSelector<any>((state: RootState) => state.auth.getCityData);
    const verifyTaxResult = useAppSelector<any>((state: RootState) => state.auth.verifyTaxResult);
    const [country, setCountry] = React.useState('');
    const [entityOrIndividual, setEntityOrIndividual] = React.useState('individual');
    const [city, setCity] = React.useState('');
    const [passwordType, setPasswordType] = React.useState(false);
    const [passwordConfirmationType, setPasswordConfirmationType] = React.useState(false);

    useEffect(() => {
        dispatch(location())
        dispatch(setRegistrationStatus(''))
        dispatch(getCountries())
    }, []);

    useEffect(() => {
        if (verifyTaxResult && verifyTaxResult.result === false) {
            toast.error('Enter the correct tax number')
        }
        if (verifyTaxResult && verifyTaxResult.result) {
            setValue('name_company', verifyTaxResult.name_company)
            setValue('address_company', verifyTaxResult.address_company)
        }
    }, [verifyTaxResult]);

    const onInputChangeCity = (val: string) => {
        if (val && val.length >= 3 && country) {
            dispatch(getCity({name: val, country_code: country}))
        }
    }

    const onChangeTaxNumber = (val: React.ChangeEvent<HTMLInputElement>) => {
        countries.find(el => el.code === country)?.length_tax_number?.forEach((num: number) => {
            if (num && val && val.target.value.length >= num) {
                dispatch(verifyTaxNumber({
                    country_code: country,
                    tax_number: val.target.value
                }))
            }
        })

    }

    const changeCity = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('city', val.value)
            setCity(val.value)
        } else {
            setCity('')
            setValue('city', '')
        }
    }

    const changeCountry = (val: { value: string, label: string } | null) => {
        if (val) {
            setValue('phone_country', val.value)
            setValue('city', '')
            setCity('')
            setCountry(val.value)
            dispatch(setCities([]))
        }
    }

    const changeEntityOrIndividual = (val: { value: string, label: string } | null) => {
        if (val) {
            setEntityOrIndividual(val.value)
        }
    }

    useEffect(() => {
        if (locationData.status === 'SUCCESS') {
            setValue('phone_country', locationData.location.country_code)
            setCountry(locationData.location.country_code)
            setCity(locationData.location.city)
            setValue('city', locationData.location.city)
        }
    }, [locationData]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid}
    } = useForm<RegistrationState>({
        mode: 'all',
    })
    const onSubmit = handleSubmit((data) => {
        if (data.password_confirmation !== data.password) {
            setState({...state, passwordsDoNotMatch: true})
        } else {
            setState({...state, passwordsDoNotMatch: false, email: data.email})
            dispatch(registration(data))
        }
    })

    useEffect(() => {
        if (registrationStatus === 'ERROR') {
            toast.error(t('Error! Check the entered data and try again.'));
        }
    }, [registrationStatus]);

    const passwordTypeClick = (e: string) => {
        if (e === 'password') {
            setPasswordType(!passwordType)
        }
        if (e === 'password_confirmation') {
            setPasswordConfirmationType(!passwordConfirmationType)
        }
    }
    return (
        <div className="login__container">
            <div className="login__header">
                <img className="login__logo" src={logo} alt="solomon"/>
            </div>
            <div className="login__body">
                {
                    registrationStatus === 'MUST_VERIFY_EMAIL'
                    &&
                    (
                        <div className="login__message">
                            <img className="login__message-img" src={mail} alt="email"/>
                            <p className="login__message-text mt-0-5"> {t('Email was sent to')}
                                <strong> {state.email}</strong>.
                            </p>

                            <p className="login__message-text mt-0-5"> {t('Follow the link in the email to verify your email address')}</p>
                            <div className="switch__notice mt-1-5 pointer">
                                <NavLink className="switch__notice-link" to="/login/sing-in">{t("Sing in")}</NavLink>
                            </div>
                        </div>
                    )
                }
                {
                    registrationStatus !== 'MUST_VERIFY_EMAIL'
                    &&
                    (
                        <form className="form" onSubmit={onSubmit}>
                            <div className="form__input-container">
                                <div className="form__input-item">
                                    <FormErrorField error={errors.name}/>
                                    <input
                                        {...register('name', {
                                            required: t('Required field'),
                                        })}
                                        className="form__input"
                                        style={style(errors?.name)}
                                        placeholder={t('User login in the system')}
                                        type="text"/>
                                </div>
                                <div className="form__input-item">
                                    <Select
                                        isSearchable={false}
                                        options={entityOrIndividualOptions()}
                                        className="select"
                                        classNamePrefix="select"
                                        styles={selectStyles}
                                        value={entityOrIndividualOptions().find((e: { value: string, label: string }) => entityOrIndividual === e.value)}
                                        onChange={changeEntityOrIndividual}
                                    />
                                </div>
                                <div className="form__input-item">
                                    <FormErrorField error={errors.phone_country}/>
                                    <Select
                                        {...register('phone_country', {
                                            required: t('Required field'),
                                        })}
                                        isSearchable={false}
                                        options={countryOptions()}
                                        placeholder={t('Choose a country')}
                                        className={classNames('select', errors?.phone_country ? ' select-invalid' : '')}
                                        classNamePrefix="select"
                                        styles={selectStyles}
                                        value={countryOptions().find((e: { value: string, label: string }) => country === e.value)}
                                        onChange={changeCountry}
                                    />
                                </div>
                                <div className="form__input-item">
                                    <FormErrorField error={errors.city}/>
                                    <Select
                                        {...register('city', {
                                            required: t('Required field'),
                                        })}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={getCityData}
                                        placeholder={t('Enter city')}
                                        className={classNames('select', errors?.city ? ' select-invalid' : '')}
                                        classNamePrefix="select"
                                        value={city ? {label: city, value: city} : null}
                                        styles={selectStyles}
                                        onChange={changeCity}
                                        onInputChange={onInputChangeCity}
                                    />
                                </div>
                                {
                                    entityOrIndividual === 'entity'
                                    && (
                                        <>
                                            <div className="form__input-item">
                                                <FormErrorField error={errors.tax_number}/>
                                                <input
                                                    {...register('tax_number', {
                                                        required: t('Required field'),
                                                    })}
                                                    className="form__input"
                                                    style={style(errors?.tax_number)}
                                                    placeholder={countries.find(el => el.code === country)?.tax_number}
                                                    onChange={onChangeTaxNumber}
                                                    type="number"
                                                />
                                            </div>
                                        </>
                                    )
                                }
                                {(verifyTaxResult && verifyTaxResult.result) && (
                                    <>
                                        <div className="form__input-item">
                                            <input
                                                {...register('name_company')}
                                                className="form__input"
                                                disabled={verifyTaxResult.name_company}
                                                title={verifyTaxResult.name_company}
                                                style={style(errors?.name_company)}
                                                type="text"
                                            />
                                        </div>
                                        <div className="form__input-item">
                                            <input
                                                {...register('address_company')}
                                                className="form__input"
                                                disabled={verifyTaxResult.address_company}
                                                title={verifyTaxResult.address_company}
                                                style={style(errors?.address_company)}
                                                type="text"
                                            />
                                        </div>
                                    </>

                                )}
                                <div className="form__input-item">
                                    <FormErrorField error={errors.email}/>
                                    <input
                                        {...register('email', {
                                            required: t('Required field'),
                                            pattern: {
                                                value: /\S+@\S+\.\S+/,
                                                message: t('Invalid email address')
                                            }
                                        })}
                                        className="form__input"
                                        style={style(errors?.email)}
                                        placeholder="E-mail"
                                        type="text"/>
                                </div>
                                <div className="form__input-item">
                                    <FormErrorField error={errors.phone}/>
                                    <input
                                        {...register('phone',
                                            {
                                                required: t('Required field'),
                                                pattern: {
                                                    value: /^[+{1,}0-9]+$/,
                                                    message: t('Invalid phone number')
                                                }

                                            })}
                                        className="form__input"
                                        style={style(errors?.phone)}
                                        placeholder={t('Phone')}
                                        type="tel"/>
                                </div>

                                <div className="form__input-item">
                                    <FormErrorField error={errors.password}/>
                                    <FormErrorField
                                        errorText={state.passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                                    <div className="input_eye">
                                        <input
                                            {...register('password', {
                                                required: t('Required field'),
                                                minLength: {
                                                    value: 6,
                                                    message: t('Password must be at least 6 characters')
                                                }
                                            })}
                                            className="form__input w-100"
                                            style={style(errors?.password || state.passwordsDoNotMatch)}
                                            placeholder={t('Enter password')}
                                            type={passwordType ? 'text' : 'password'}/>
                                        <div
                                            className={classNames('eye', passwordType ? 'eye_open' : 'eye_close')}
                                            onClick={() => passwordTypeClick('password')}>
                                        </div>
                                    </div>
                                </div>
                                <div className="form__input-item">
                                    <FormErrorField error={errors.password_confirmation}/>
                                    <FormErrorField
                                        errorText={state.passwordsDoNotMatch ? t('Passwords do not match') : null}/>
                                    <div className="input_eye">
                                        <input
                                            {...register('password_confirmation', {
                                                required: t('Required field'),
                                                minLength: {
                                                    value: 6,
                                                    message: t('Password must be at least 6 characters')
                                                },
                                            })}
                                            className="form__input w-100"
                                            style={style(errors?.password_confirmation || state.passwordsDoNotMatch)}
                                            placeholder={t('Repeat password')}
                                            type={passwordConfirmationType ? 'text' : 'password'}/>
                                        <div
                                            className={classNames('eye', passwordConfirmationType ? 'eye_open' : 'eye_close')}
                                            onClick={() => passwordTypeClick('password_confirmation')}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="login__btn-container mt-1">
                                <button
                                    type="submit"
                                    className="button w-100"
                                >
                                    {t('Sing up')}
                                </button>
                            </div>
                        </form>
                    )
                }
            </div>
            <div className="login__footer">
                <p className="login__footer-text">{t("Do you have an account")}?</p>
                <NavLink className="login__footer-link pointer" to="/login/sing-in">{t("Sing in")}
                </NavLink>
            </div>
        </div>
    )
}

export default SingUp;

