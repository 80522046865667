import React from 'react';
import {Route, Routes} from "react-router-dom";
import AcquirersCreate from "./AcquirersCreate";
import AcquirersTable from "./AcquirersTable";
import AcquirersEdit from "./AcquirersEdit";

const AcquirersList = () => {
    return (
        <Routes>
            <Route path="acquirers" element={<AcquirersTable/>}/>
            <Route path="create" element={<AcquirersCreate/>}/>
            <Route path=":id/edit" element={<AcquirersEdit/>}/>
        </Routes>
    )
};

export default AcquirersList;

