import React, {useEffect} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import {getUnitImei} from "./UnitsImeiSlice";

function UnitImei() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUnitImei(searchParams.get('imei')))
        navigate('login/sing-in');
    }, [searchParams]);

    return (<></>)
}

export default UnitImei;

