import React from 'react';
import SingIn from "./Sing-in";
import "../../css/main.scss";
import {Route, Routes} from "react-router-dom";
import SingUp from "./Sign-up";
import Recovery from "./Recovery";
import RestorePassword from "./RestorePassword";

function Authentication() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="login wh-100">
                <span>
                </span>
            <Routes>
                <Route path="sing-in" element={<SingIn/>}/>
                <Route path="sing-up" element={<SingUp/>}/>
                <Route path="recovery" element={<Recovery/>}/>
                <Route path="restore-password" element={<RestorePassword/>}/>
            </Routes>
            <div className="copyright mt-1">{currentYear} &#169; Solomon</div>
        </div>
    )
}

export default Authentication;

