import {configureStore} from '@reduxjs/toolkit';
import app from '../app/appSlice';
import auth from "./authSlice";
import unitImei from "../components/unit-imei/UnitsImeiSlice";
import units from "../components/units/UnitsSlice";
import franchisee from "../components/franchisee/FranchiseeSlice";
import users from "../components/users/usersSlice";
import pricePlans from "../components/price-plan/pricePlanSlice";
import invoices from "../components/invoices/InvoicesSlice";
import balanceReport from "../components/balance-list/BalanceSlice";
import qrCode from "../components/generate-qr-code/QrCodeSlice";
import dashboard from "../components/dashboard/DashboardSlice";
import acquirer from "../components/acquirers/AcquirersSlice";
import counterparties from "../components/counterparties/CounterpartiesSlice";
import search from "../components/search/SearchSlice";
import documents from "../components/documents/DocumentsSlice";
import requisites from "../components/requisites/RequisitesSlice";

const store = configureStore({
    reducer: {
        app,
        dashboard,
        auth,
        unitImei,
        search,
        units,
        franchisee,
        users,
        pricePlans,
        acquirer,
        counterparties,
        qrCode,
        balanceReport,
        invoices,
        documents,
        requisites
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
