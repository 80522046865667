import React from 'react';
import {countryOptions} from "./utils/CountryOptions";

const CountryRenderComponent = (params: any) => {

    return (
        <div className="country__container">
            <div className={'icon-country icon_wh-1-25 icon-country-' + params.data.country_code}>
            </div>
            <div>{countryOptions().find((e: any) => params.data.country_code === e.value)?.label}</div>
        </div>
    )
};

export default CountryRenderComponent;







