import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import translationEN from "../assets/locales/en/translation.json";
import translationRU from "../assets/locales/ru/translation.json";

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            EN: {
                translation: translationEN
            },
            RU: {
                translation: translationRU
            }
        },
        fallbackLng: 'RU',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false
        }
    });


export default i18n;
