import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

export interface UpdatePricePlanState {
    rate_id: number
    price: number
    country_code: string
}

export interface FormPricePlanState {
    price: number
}

export interface FormAddNewPricePlanState {
    price: number
    name: string
    add_to_balance: number,
    country_code?: string
}

export interface SuccessUpdatePricePlan {
    successUpdatePricePlan: boolean
}

const PRICE_PLANS = gql`
mutation {
rates {
  rate_id
  country
  price
  add_to_balance
  name
  }
}
`;

const UPDATE_PRICE_PLANS = gql`
mutation updateRate($input: UpdateRate!) {
updateRate(input: $input) {
    result
  }
}
`;

const CREATE_PRICE_PLANS = gql`
mutation addRate($input: AddRate!) {
  addRate(input: $input) {
    result
  }
}
`;

export const pricePlans = createAsyncThunk(
    'pricePlan',
    async () => {
        const response = await client.mutate({mutation: PRICE_PLANS})
        return response.data
    }
)

export const updatePricePlans = createAsyncThunk(
    'updatePricePlan',
    async (form: UpdatePricePlanState) => {
        const response = await client.mutate({mutation: UPDATE_PRICE_PLANS, variables: {input: form}})
        return response.data
    }
)
export const createPricePlans = createAsyncThunk(
    'createPricePlans',
    async (form: any) => {
        const response = await client.mutate({mutation: CREATE_PRICE_PLANS, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    pricePlans: [],
    pricePlansSelection: [],
    pricePlanLoader: false,
    selectedPricePlan: {
        rate_id: null,
        price: null
    },
    successUpdatePricePlan: false,
}

const pricePlanSlice = createSlice({
    name: 'pricePlans',
    initialState,
    reducers: {
        setSelectedPricePlan: (state: any, action: any) => {
            state.selectedPricePlan = action.payload;
        },
        setSuccessUpdatePricePlan: (state: SuccessUpdatePricePlan, action: PayloadAction<boolean>) => {
            state.successUpdatePricePlan = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(pricePlans.pending, (state: any) => {
                    state.pricePlanLoader = true;
                }
            )
            .addCase(pricePlans.fulfilled, (state: any, action) => {
                    state.pricePlans = action.payload.rates
                    state.pricePlansSelection = action.payload.rates.map((item: any) => ({
                        value: item.rate_id,
                        label: item.rate_id + ' - ' + item.name
                    }))
                    state.pricePlanLoader = false;
                }
            )
            .addCase(pricePlans.rejected, (state: any) => {
                    state.pricePlanLoader = false;
                }
            )
            .addCase(updatePricePlans.pending, (state: any) => {
                    state.pricePlanLoader = true;
                }
            )
            .addCase(updatePricePlans.fulfilled, (state: any, action) => {
                    state.pricePlanLoader = false;
                    state.successUpdatePricePlan = action.payload.updateRate.result;
                }
            )
            .addCase(updatePricePlans.rejected, (state: any) => {
                    state.pricePlanLoader = false;
                }
            )
            .addCase(createPricePlans.pending, (state: any) => {
                    state.pricePlanLoader = true;
                }
            )
            .addCase(createPricePlans.fulfilled, (state: any, action) => {
                    state.pricePlanLoader = false;
                    state.successUpdatePricePlan = action.payload.addRate.result;
                }
            )
            .addCase(createPricePlans.rejected, (state: any) => {
                    state.pricePlanLoader = false;
                }
            )
    }
})
export const {setSelectedPricePlan, setSuccessUpdatePricePlan} = pricePlanSlice.actions;
export default pricePlanSlice.reducer;


