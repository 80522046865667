import React from 'react';
import {useAppDispatch} from "../../app/hooks";
import trash from "../../assets/icon/trash.svg";
import {useTranslation} from "react-i18next";
import {updateUnitStatus} from "./UnitsSlice";

const RemoveRenderComponent = (params: any) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const handleChange = (e: any) => {
        const confirmMessage = t('Do you really want to delete the unit?');
        if (window.confirm(confirmMessage)) {
            dispatch(updateUnitStatus({
                id_unit: params.data.id,
                removed: true
            }))
        }

    }

    return (
        <div className="clear__container pointer" onClick={handleChange}>
            <img className="icon_wh-1-25" src={trash} alt="clear"/>
        </div>
    )
};

export default RemoveRenderComponent;







