import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

export interface AddUnitState {
    name: string,
    imei: string,
}

export interface UnitStatusChangeState {
    id_unit: number
    removed: boolean
}

export interface SuccessAddedUnitState {
    successAddedUnit: boolean
}

export interface SuccessUpdateUnitStatusState {
    successUpdateUnitStatus: boolean
}

const UnitsSlice = gql`
mutation units($input: UnitsPaginate!) {
units(input: $input) {
      units {
        id
        hw_id
        last_msg
        hw_type
        imei
        removed
        removed
        creator
        name
        phone
        status
        rate
      }
      paginate {
        total
        last_page
        per_page
        current_page
      }
    }   
}
`;

const ADD_UNIT = gql`
mutation addUnit($input: CreateUnit!) {
addUnit(input: $input) {
  unit_id
  }
}
`;

const UNIT_STATUS_CHANGE = gql`
mutation updateUnit($input: UpdateUnit!) {
updateUnit(input: $input) {
    result
  }
}
`;

export const units = createAsyncThunk(
    'units',
    async (form: any) => {
        const response = await client.mutate({mutation: UnitsSlice, variables: {input: form}})
        return response.data
    }
)

export const addUnit = createAsyncThunk(
    'addUnit',
    async (form: AddUnitState) => {
        const response = await client.mutate({mutation: ADD_UNIT, variables: {input: form}})
        return response.data
    }
)

export const updateUnitStatus = createAsyncThunk(
    'updateUnit',
    async (form: any) => {
        const response = await client.mutate({mutation: UNIT_STATUS_CHANGE, variables: {input: form}})
        return response.data
    }
)

export interface UnitState {
    unitsData: [],
    paginate: any,
    unitsLoader: boolean,
    successAddedUnit: boolean,
    unsuccessfulAddedUnit: boolean,
    successUpdateUnitStatus: boolean,
}

const initialState: UnitState = {
    unitsData: [],
    paginate: null,
    unitsLoader: false,
    successAddedUnit: false,
    unsuccessfulAddedUnit: false,
    successUpdateUnitStatus: false,
}

const unitSlice = createSlice({
    name: 'units',
    initialState,
    reducers: {
        setSuccessAddedUnit: (state: SuccessAddedUnitState, action: PayloadAction<boolean>) => {
            state.successAddedUnit = action.payload;
        },
        setUnsuccessfulAddedUnit: (state: any, action: PayloadAction<boolean>) => {
            state.unsuccessfulAddedUnit = action.payload;
        },
        setSuccessUpdateUnitStatus: (state: SuccessUpdateUnitStatusState, action: PayloadAction<boolean>) => {
            state.successUpdateUnitStatus = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(units.pending, (state: any) => {
                    state.unitsLoader = true;
                }
            )
            .addCase(units.fulfilled, (state: any, action) => {
                    state.unitsData = action.payload.units.units
                    state.paginate = action.payload.units.paginate
                    state.unitsLoader = false;
                }
            )
            .addCase(units.rejected, (state: any) => {
                    state.unitsLoader = false;
                }
            )
            .addCase(addUnit.pending, (state: any) => {
                    state.unitsLoader = true;
                }
            )
            .addCase(addUnit.fulfilled, (state: any, action) => {
                    state.unitsLoader = false;
                    if (action.payload && action.payload.addUnit && action.payload.addUnit.unit_id) {
                        state.successAddedUnit = true;
                    } else {
                        state.unsuccessfulAddedUnit = true;
                    }
                }
            )
            .addCase(addUnit.rejected, (state: any) => {
                    state.unitsLoader = false;
                }
            )
            .addCase(updateUnitStatus.pending, (state: any) => {
                    state.unitsLoader = true;
                }
            )
            .addCase(updateUnitStatus.fulfilled, (state: any, action) => {
                    state.successUpdateUnitStatus = action.payload.updateUnit.result;
                    state.unitsLoader = false;
                }
            )
            .addCase(updateUnitStatus.rejected, (state: any) => {
                    state.unitsLoader = false;
                }
            )
    }
})

export const {setSuccessAddedUnit, setUnsuccessfulAddedUnit, setSuccessUpdateUnitStatus} = unitSlice.actions;

export default unitSlice.reducer;


