import React from 'react';
import {Route, Routes} from "react-router-dom";
import UnitCreate from "./UnitCreate";
import UnitScanQrCode from "./UnitScanQrCode";
import UnitsTable from "./UnitsTable";

const UnitsList = () => {
    return (
        <Routes>
            <Route path="units" element={<UnitsTable/>}/>
            <Route path="unit-create" element={<UnitCreate/>}/>
            <Route path="unit-scan-qr-code" element={<UnitScanQrCode/>}/>
        </Routes>
    )
};

export default UnitsList;

