import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

export interface BalancePaginationState {
    page: number
    per_page: number
}

export interface TopUpBalanceState {
    amount: number,
    cardId?: number | string,
}

const BALANCE_REPORT = gql`
mutation balanceHistory($input: UnitsPaginate!) {
    balanceHistory(input: $input) {
        balance{
           id
           amount
           created_at
        }
           paginate {
           total
           last_page
           per_page
           current_page
        }
    }
}
`;
const TOP_UP_BALANCE = gql`
mutation formPayment($input: PaymentData!) {
    formPayment(input: $input) {
        redirect_url
        status
    }
}
`;

const CREDIT_CARD = gql`
mutation {
 creditCard {
  id
  first_six_digits
  last_four_digits
  type
  expiration_month
  expiration_year
  is_main
 }
}
`
;
const CARD_PAYMENT = gql`
mutation cardPayment($input: PaymentCard!) {
    cardPayment(input: $input) {
        status
    }
}
`;

export const balanceReport = createAsyncThunk(
    'balanceReport',
    async (form: BalancePaginationState) => {
        const response = await client.mutate({mutation: BALANCE_REPORT, variables: {input: form}})
        return response.data
    }
)
export const topUpBalance = createAsyncThunk(
    'topUpBalance',
    async (form: TopUpBalanceState) => {
        const response = await client.mutate({mutation: TOP_UP_BALANCE, variables: {input: form}})
        return response.data
    }
)

export const creditCard = createAsyncThunk(
    'creditCard',
    async () => {
        const response = await client.mutate({mutation: CREDIT_CARD})
        return response.data
    }
)
export const cardPayment = createAsyncThunk(
    'cardPayment',
    async (form: any) => {
        const response = await client.mutate({mutation: CARD_PAYMENT, variables: {input: form}})
        return response.data
    }
)


export interface BalanceReportState {
    balanceReport: [],
    creditCards: Array<any>,
    cardPaymentStatus: string,
    paginate: any,
    balanceReportLoader: boolean,
    redirectUrl: string,
}

const initialState: BalanceReportState = {
    balanceReport: [],
    creditCards: [],
    cardPaymentStatus: '',
    paginate: null,
    balanceReportLoader: false,
    redirectUrl: '',
}

const balanceSlice = createSlice({
    name: 'balanceReport',
    initialState,
    reducers: {
        setRedirectUrl: (state: any, action: PayloadAction<string>) => {
            state.redirectUrl = action.payload;
        },
        setCardPaymentStatus: (state: any, action: PayloadAction<string>) => {
            state.cardPaymentStatus = action.payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(balanceReport.pending, (state: any) => {
                    state.balanceReportLoader = true
                }
            )
            .addCase(balanceReport.fulfilled, (state: any, action: any) => {
                    state.balanceReport = action.payload.balanceHistory.balance;
                    state.paginate = action.payload.balanceHistory.paginate
                    state.balanceReportLoader = false
                }
            )
            .addCase(balanceReport.rejected, (state: any) => {
                    state.balanceReportLoader = false
                }
            )
            .addCase(topUpBalance.pending, (state: any) => {
                    state.balanceReportLoader = true
                }
            )
            .addCase(topUpBalance.fulfilled, (state: any, action: any) => {
                    console.log(action.payload.formPayment.redirect_url)
                    state.balanceReportLoader = false
                    state.redirectUrl = action.payload.formPayment.redirect_url
                }
            )
            .addCase(topUpBalance.rejected, (state: any) => {
                    state.balanceReportLoader = false
                }
            )
            .addCase(creditCard.fulfilled, (state: any, action: any) => {
                    state.creditCards = action.payload.creditCard
                }
            )
            .addCase(cardPayment.pending, (state: any) => {
                    state.balanceReportLoader = true
                }
            )
            .addCase(cardPayment.fulfilled, (state: any, action: any) => {
                    state.balanceReportLoader = false
                    state.cardPaymentStatus = action.payload.cardPayment.status
                }
            )
            .addCase(cardPayment.rejected, (state: any) => {
                    state.balanceReportLoader = false
                }
            )

    }
})

export const {
    setRedirectUrl,
    setCardPaymentStatus
} = balanceSlice.actions;
export default balanceSlice.reducer;


