import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import gql from "graphql-tag";
import {client} from "../../app/apolloClient";

const CONTRACT = gql`
query {
contract {
     base64
  } 
}
`;

const REPORT = gql`
mutation report($input: ReportDate!) {
report(input: $input) {
    base64
  }
}
`;

export const contract = createAsyncThunk(
    'contract',
    async () => {
        const response = await client.mutate({mutation: CONTRACT})
        return response.data
    }
)

export const report = createAsyncThunk(
    'report',
    async (form: any) => {
        const response = await client.mutate({mutation: REPORT, variables: {input: form}})
        return response.data
    }
)

const initialState = {
    documentsLoader: false,
    contractData: null,
    reportData: null,
}

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(contract.pending, (state: any) => {
                    state.documentsLoader = true
                }
            )
            .addCase(contract.fulfilled, (state: any, action) => {
                    state.documentsLoader = false
                    state.contractData = 'data:application/pdf;base64,' + action.payload.contract.base64
                }
            )
            .addCase(contract.rejected, (state: any) => {
                    state.documentsLoader = false
                }
            )
            .addCase(report.pending, (state: any) => {
                    state.documentsLoader = true
                }
            )
            .addCase(report.fulfilled, (state: any, action) => {
                    state.documentsLoader = false
                    state.reportData = 'data:application/pdf;base64,' + action.payload.report.base64
                }
            )
            .addCase(report.rejected, (state: any) => {
                    state.documentsLoader = false
                }
            )
    }
})

export default documentsSlice.reducer;


