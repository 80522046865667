import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {createAccountDetails, updateUserVATStatus} from "./RequisitesSlice";
import classNames from "classnames";
import {style} from "../../assets/formErrorStyle";
import {selectStyles} from "../SelectStyles";
import Select from "react-select";
import FormErrorField from "../form-error-field/FormErrorField";
import {vatStatusOptions} from "../utils/VatStatusOptions";
import TableHeader from "../table/TableHeader";

const RequisitesLegalEntity = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const userData = useAppSelector<any>((state: RootState) => state.auth.userData);
    const requisitesData = useAppSelector<any>((state: RootState) => state.requisites.requisitesData);
    const [entity, setEntity] = React.useState<any>(null);
    const [entityValue, setEntityValue] = React.useState<any>(null);
    const [chosenVatStatus, setChosenVatStatus] = React.useState<any>(null);

    const {
        register,
        handleSubmit,
        formState: {errors}
    } = useForm<any>({mode: "all"})

    const {
        register: registerVatStatus,
        handleSubmit: handleSubmitVatStatus,
        setValue: setValueVatStatus,
        formState: {errors: errorsVatStatus}
    } = useForm<{
        vat_status: string

    }>({mode: "all"})

    useEffect(() => {
        if (userData && userData.vat_status) {
            setChosenVatStatus(vatStatusOptions()[0].value)
            setValueVatStatus('vat_status', vatStatusOptions()[0].value)
        } else {
            setChosenVatStatus(vatStatusOptions()[1].value)
            setValueVatStatus('vat_status', vatStatusOptions()[1].value)
        }
    }, [userData]);

    useEffect(() => {
        if (requisitesData) {
            requisitesData.forEach((item: any) => {
                if (item.type === 'entity') {
                    const arr: any = []
                    const requisitesFields = JSON.parse(item.fields);
                    Object.keys(requisitesFields).forEach(
                        result =>
                            arr.push(
                                {
                                    name: result,
                                    value: '',
                                    placeholder: requisitesFields[result],
                                }
                            )
                    );
                    setEntity(arr)
                    setEntityValue(arr)
                }
            })
        }
    }, [requisitesData]);

    useEffect(() => {
        if (entity && entity.length > 0) {
            if (userData && userData.account_details) {
                const accountDetails = JSON.parse(userData.account_details);
                if (accountDetails.type === 'entity') {
                    const arr: any = []
                    Object.keys(accountDetails).forEach(
                        result => {
                            if (result !== 'type') {
                                entity.forEach((item: any) => {
                                    if (item.name === result) {
                                        arr.push({...item, value: accountDetails[result]})
                                    }
                                })
                            }
                        }
                    );
                    setEntityValue(arr)
                }
            }
        }
    }, [entity]);

    const onSubmit = handleSubmit((data: any) => {
        dispatch(createAccountDetails(Object.assign({}, ...entityValue.map((n: any) => ({[n.name]: n.value})), {type: 'entity'})))
    })


    const onChange = (val: any, name: any) => {
        const changedValue = entityValue.map((item: any) => (
            item.name === name
                ? {...item, value: val.target.value}
                : item
        ));
        setEntityValue(changedValue)
    }

    const onChangeVatStatus = (val: any) => {
        setValueVatStatus('vat_status', val.value)
        setChosenVatStatus(val.value)
    }

    const onSubmitVatStatus = handleSubmitVatStatus((data: any) => {
        if (data.vat_status === vatStatusOptions()[0].value) {
            dispatch(updateUserVATStatus({vat_status: true}))
        } else {
            dispatch(updateUserVATStatus({vat_status: false}))
        }
    })

    return (
        <div className="table__container requisites__table">
            <TableHeader
                title={t('Legal entity')}/>
            <div className="table__body">
                <div className={classNames('add__block  add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            {entity ? entity.map((item: any) => (
                                    <div key={item.name} className={"form__input-item form__input-item-with-label"}>
                                        <div className="form__input-label">{item.placeholder}</div>
                                        <input
                                            key={item.name}
                                            {...register(`${item.name}`)}
                                            className="form__input"
                                            value={entityValue && entityValue.length > 0 ? entityValue.find((el: any) => el.name === item.name).value : ''}
                                            onChange={(val: any) => onChange(val, item.name)}
                                            placeholder={item.placeholder}
                                            style={style(errors?.hasOwnProperty(item.name))}
                                            type="text"/>
                                    </div>
                                )
                            ) : ''}
                        </div>

                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                    <form className="form mt-2" onSubmit={onSubmitVatStatus}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errorsVatStatus.vat_status}/>
                                <Select
                                    {...registerVatStatus('vat_status', {
                                        required: t('Required field'),
                                    })}
                                    isSearchable={false}
                                    options={vatStatusOptions()}
                                    placeholder={t('Type of taxation')}
                                    className={classNames('select', errorsVatStatus?.vat_status ? ' select-invalid' : '')}
                                    classNamePrefix="select"
                                    styles={selectStyles}
                                    value={vatStatusOptions().find((e: any) => chosenVatStatus === e.value)}
                                    onChange={onChangeVatStatus}
                                />
                            </div>
                        </div>

                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default RequisitesLegalEntity;

