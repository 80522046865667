import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import moment from 'moment';
import {report} from "./DocumentsSlice";
import {RootState} from "../../app/store";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";

const Deeds = () => {
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [date, setDate] = React.useState(moment(new Date()).format('YYYY-MM-DD'));
    const reportData = useAppSelector<any>((state: RootState) => state.documents.reportData);

    useEffect(() => {
        dispatch(report({date: date}))
    }, []);

    const {
        register,
        handleSubmit,
        formState: {isValid}
    } = useForm({
        mode: 'all',
    })
    const onDateChange = (val: React.ChangeEvent<HTMLInputElement>) => {
        setDate(val.target.value)
    }

    const onSubmit = handleSubmit((data) => {
        dispatch(report(data))
    })
    return (
        <div className="deeds__container wh-100">
            <form className="form-line" onSubmit={onSubmit}>
                <input
                    {...register('date')}
                    type="date"
                    value={date}
                    onChange={onDateChange}
                />
                <div className="button__container">
                    <button
                        type="submit"
                        className="button"
                        disabled={!isValid}
                    >
                        {t('Apply')}
                    </button>
                </div>
            </form>
            <div className="deeds__body wh-100">
                <iframe className="wh-100" src={reportData}/>
            </div>
        </div>
    )
};

export default Deeds;

