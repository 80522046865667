import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {addUnit, AddUnitState, setSuccessAddedUnit, setUnsuccessfulAddedUnit} from "./UnitsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import classNames from "classnames";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {style} from "../../assets/formErrorStyle";
import {useNavigate} from "react-router-dom";
import {user} from "../../app/authSlice";
import FormErrorField from "../form-error-field/FormErrorField";
import TableHeader from "../table/TableHeader";
import {getUnitImei} from "../unit-imei/UnitsImeiSlice";

const UnitCreate = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const successUnitAddedFromServer = useAppSelector((state: RootState) => state.units.successAddedUnit);
    const unsuccessfulUnitAddedFromServer = useAppSelector((state: RootState) => state.units.unsuccessfulAddedUnit);
    const unitImeiData = useAppSelector((state: RootState) => state.unitImei.unitImeiData);

    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors, isValid}
    } = useForm<AddUnitState>({mode: 'all'})

    const onSubmit = handleSubmit((data: AddUnitState) => {
        dispatch(addUnit({
            name: data.name,
            imei: data.imei,
        }))
        dispatch(getUnitImei(null))
    })

    useEffect(() => {
        if (unitImeiData) {
            setValue('imei', unitImeiData)
        }
    }, [unitImeiData]);

    useEffect(() => {
        if (successUnitAddedFromServer) {
            dispatch(user())
            toast.success(t('Saved'));
            navigate('/units-list/units')
        }
        return () => {
            dispatch(setSuccessAddedUnit(false))
        }
    }, [successUnitAddedFromServer]);

    useEffect(() => {
        if (unsuccessfulUnitAddedFromServer) {
            toast.error(t('An error has occurred. Check imei and try again'));
        }
        return () => {
            dispatch(setUnsuccessfulAddedUnit(false))
        }
    }, [unsuccessfulUnitAddedFromServer]);

    const closeUnitCreate = () => {
        navigate('/units-list/units')
    }
    const scanQrCodeClick = () => {
        navigate('/units-list/unit-scan-qr-code')
    }

    return (
        <div className="table__container">
            <TableHeader title={t('Creating a object')}
                         close={closeUnitCreate}
                         scanQrCode={scanQrCodeClick}/>
            <div className="table__body">

                <div
                    className={classNames('add__block  add__block_visible')}>
                    <form className="form" onSubmit={onSubmit}>
                        <div className="form__input-container">
                            <div className="form__input-item">
                                <FormErrorField error={errors.name}/>
                                <input
                                    {...register('name', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input"
                                    style={style(errors?.name)}
                                    placeholder={t('Enter the name of the object')}
                                    type="text"/>
                            </div>
                            <div className="form__input-item">
                                <FormErrorField error={errors.imei}/>
                                <input
                                    {...register('imei', {
                                        required: t('Required field'),
                                    })}
                                    className="form__input"
                                    style={style(errors?.imei)}
                                    placeholder={t('Enter imei')}
                                    type="text"/>
                            </div>
                        </div>
                        <div className="button__container mt-2">
                            <button
                                type="submit"
                                className="button"
                                disabled={!isValid}
                            >
                                {t('Save')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default UnitCreate;

